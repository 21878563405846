import React from 'react';
import { bool, func, number } from 'prop-types';
import cx from 'classnames';
import { scrollToElement } from '../../../utils/helpers';

import styles from './MiniHeader.module.scss';
import LogoSmall from './logo-small.svg';

const DEFAULT_STYLES = {
  visibility: 'hidden',
};

const DAYS = ['sun', 'mon', 'tues', 'wed', 'thurs', 'fri', 'sat'];

/**
 * Calculates next title id to scroll to.
 * @param {string} captionId - id of the passed month
 * @param {string} type - type of the action to calculate next month (add/subtract)
 */
const calculateMonthId = function (captionId, type) {
  const splittedId = captionId.split('_');
  let month = +splittedId[0];
  let year = +splittedId[1];

  if (month === 11 && type === 'next') {
    month = 0;
    year += 1;
  } else if (month === 0 && type === 'prev') {
    month = 11;
    year -= 1;
  } else {
    month = type === 'next' ? month + 1 : month - 1;
  }

  return `${month}_${year}`;
};

/**
 * Gets invoked when mini header navigation buttons are clicked.
 * @param {string} type - type of the clicked button (next/prev/today)
 */
const handleCalendarHeaderClick = (type) => () => {
  if (type !== 'today') {
    const { _visibleMonths } = window;
    // sort visible monts by date
    const sortedVisibleMonths = [..._visibleMonths].sort((a, b) => a.ts - b.ts);
    const [firstVisibleMonth] = sortedVisibleMonths;
    if (!firstVisibleMonth) return;

    const nextMonthId = calculateMonthId(firstVisibleMonth.id, type);
    const getCaptionEl = () => document.querySelector(`[data-caption-id="${nextMonthId}"]`);
    let captionEl = getCaptionEl();

    // if there's no captionEl it means that next monts aren't rendered
    // so render them first end than find captionEl again
    if (!captionEl) {
      const expandMonthsBtn = document.querySelector('.range-controller__next');

      expandMonthsBtn && expandMonthsBtn.click(); // eslint-disable-line
      setTimeout(() => {
        captionEl = getCaptionEl();
      }, 4);
    }

    setTimeout(() => {
      scrollToElement(captionEl);
    }, 4);
  } else {
    const d = new Date();
    const month = d.getMonth();
    const year = d.getFullYear();
    const todayCaptionEl = document.querySelector(`[data-caption-id="${month}_${year}"]`);

    scrollToElement(todayCaptionEl);
  }
};

function renderCalendarHeader() {
  const d = new Date();
  const date = d.getDate();

  return (
    <div className={styles['mini-header__calendar']}>
      <div className={styles['mini-header__calendar-container']}>
        <div className={styles['mini-header__calendar-box']}>
          <div className={styles['mini-header__calendar-days']}>
            <div className={styles['mini-header__calendar-row']}>
              {DAYS.map((c, i) => (
                <div key={i} className={styles['mini-header__calendar-day']} // eslint-disable-line
                >
                  {c}
                </div>
              ))}
            </div>
          </div>
          <div className={styles['mini-header__calendar-btns']}>
            <button
              className={styles['mini-header__btn-next']}
              type="button"
              onClick={handleCalendarHeaderClick('next')}
            >
              NEXT
            </button>
            <div className={styles['mini-header__calendar-separator']} />
            <button
              className={styles['mini-header__btn-prev']}
              type="button"
              onClick={handleCalendarHeaderClick('prev')}
            >
              PREV
            </button>
            <button
              className={styles['mini-header__btn-today']}
              type="button"
              onClick={handleCalendarHeaderClick('today')}
            >
              {date}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function MiniHeader({ isActive, renderContent, activeStep }) {
  return (
    <div
      id="js-mini-header"
      className={cx(styles['mini-header'], 'headers-wrapper__mini-header', {
        [styles['mini-header--is-active']]: isActive,
      })}
      style={DEFAULT_STYLES}
    >
      <div className={styles['mini-header__container']}>
        <div className={styles['mini-header__logo-wrap']}>
          <a className={styles['mini-header__logo']} href="https://calamigosguestranch.com">
            <LogoSmall className={styles['mini-header__logo-img']} alt="calamigos guest ranch and beach club" />
          </a>
        </div>
        {renderContent()}
      </div>
      {activeStep === 1 && renderCalendarHeader()}
    </div>
  );
}

MiniHeader.propTypes = {
  isActive: bool,
  renderContent: func,
  activeStep: number,
};

export default MiniHeader;
