/*
  Loader for Reservations step.
  Shows when reservation items are loading.
*/
import React from 'react';
import { number, string } from 'prop-types';
import ContentLoader from 'react-content-loader';

function ReservationsLoader({ width, height, title }) {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      title={title}
      backgroundColor="rgb(0,0,0)"
      foregroundColor="rgb(0,0,0)"
      backgroundOpacity={0.06}
      foregroundOpacity={0.12}
    >
      <rect width="535.5" height="550" />
      <rect x="688.6" y="132" width="557.1" height="22.7" />
      <rect x="688" y="198.7" width="351.7" height="17.3" />
      <rect x="688.3" y="246" width="354.1" height="17.3" />
      <rect x="687.7" y="317" width="194" height="17.3" />
      <rect x="687.7" y="357" width="194.7" height="38.6" />
      <rect x="688.3" y="434.8" width="111.5" height="19.5" />
    </ContentLoader>
  );
}

ReservationsLoader.defaultProps = {
  width: 1365,
  height: 550,
  title: 'Loading reservations...',
};

ReservationsLoader.propTypes = {
  width: number,
  height: number,
  title: string,
};

export default ReservationsLoader;
