import React from 'react';
import { oneOfType, string, node } from 'prop-types';

import './FormFieldLabel.module.scss';

const FormFieldLabel = ({ name, label }) => (
  <label className="form-field__label" htmlFor={name}>
    {label}
  </label>
);

FormFieldLabel.propTypes = {
  label: oneOfType([string, node]).isRequired,
  name: string.isRequired,
};

export default FormFieldLabel;
