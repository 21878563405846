import React, { Component } from 'react';
import { oneOfType, node, number, string, bool, func } from 'prop-types';
import cx from 'classnames';
import Truncate from 'react-truncate';
import styles from './Truncate.module.scss';

class TruncateToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTruncated: false,
      isExpanded: false,
    };

    this._isControlled = props.isExpanded !== undefined;
  }

  /*
    triggers when the toggle (expand/collapse) button is clicked
  */
  handleToggleClick = () => {
    if (this._isControlled) {
      const { onToggleClick } = this.props;

      onToggleClick();
      return;
    }

    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  };

  /*
    Gets invoked on each render.
    Gets called with true when text got truncated and ellipsis was injected, and with false otherwise.
    https://github.com/One-com/react-truncate

    @callback Truncate.onTruncate
  */
  handleTruncate = (nextIsTruncated) => {
    const { isTruncated } = this.state;

    if (isTruncated !== nextIsTruncated) {
      this.setState({ isTruncated: nextIsTruncated });
    }
  };

  render() {
    const { isTruncated } = this.state;
    const { className, children, lines, ellipsisContent, moreContent, lessContent } = this.props;
    const isExpanded = this.props.isExpanded || this.state.isExpanded;

    return (
      <div
        className={cx('react-truncate', styles.truncate, {
          [className]: !!className,
          [styles['truncate--is-expanded']]: isExpanded,
        })}
      >
        <Truncate
          lines={!isExpanded && lines}
          ellipsis={<span className={styles.truncate__ellipsis}>{ellipsisContent}</span>}
          onTruncate={this.handleTruncate}
        >
          {children}
        </Truncate>
        {(isTruncated || isExpanded) && (
          <div className={styles.truncate__toggle}>
            <button className={styles['truncate__btn-toggle']} type="button" onClick={this.handleToggleClick}>
              {!isExpanded ? <span>{moreContent}</span> : <span>{lessContent}</span>}
            </button>
          </div>
        )}
      </div>
    );
  }
}

TruncateToggle.defaultProps = {
  lines: 3,
  ellipsisContent: '...',
  moreContent: 'read more',
  lessContent: 'show less',
  onToggleClick: () => {},
};

TruncateToggle.propTypes = {
  className: string,
  children: node.isRequired,
  isExpanded: bool,
  ellipsisContent: oneOfType([node, string]),
  moreContent: oneOfType([node, string]),
  lessContent: oneOfType([node, string]),
  lines: number,
  onToggleClick: func,
};

export default TruncateToggle;
