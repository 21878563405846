function getNum(value = '') {
  return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const _value = getNum(value);
  const nextValue = `${_value.slice(0, 4)} ${_value.slice(4, 8)} ${_value.slice(8, 12)} ${_value.slice(12, 19)}`;

  return nextValue.trim();
}

export function formatCreditCardName(value) {
  return value && value.toUpperCase();
}

export function formatExpirationDate(value = '') {
  const nextValue = value.replace(/[^\d|/]/g, '');

  return nextValue.slice(0, 7);
}

export function formatCVC(value) {
  const _value = getNum(value);
  const maxLength = 4;

  return _value.slice(0, maxLength);
}

export function formatNum(value) {
  return getNum(value);
}
