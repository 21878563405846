/*
  render different alert
  according to passed content
*/
import React, { Fragment } from 'react';
import { oneOfType, string, node, func } from 'prop-types';
import { Confirm } from '../../../elements';

function Alert({ title, content, closeModal }) {
  return (
    <div className="modal__note">
      <Confirm title={title} description={content} onConfirm={closeModal} isAlert />
    </div>
  );
}

Alert.defaultProps = {
  title: (
    <>
      <span>Hotel Alert</span>
      <span className="modal__icon-info" />
    </>
  ),
};

Alert.propTypes = {
  title: oneOfType([string, node]),
  content: oneOfType([string, node]),
  closeModal: func,
};

export default Alert;
