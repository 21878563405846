const site = process.env.REACT_APP_SITE || '';
const gtmId = process.env.REACT_APP_GTM_ID || '';
const gtmAuth = process.env.REACT_APP_GTM_AUTH || '';
const gtmPreview = process.env.REACT_APP_GTM_PREVIEW || '';
const hotjarId = process.env.REACT_APP_HOTJAR_ID || '';
const heapId = process.env.REACT_APP_HEAP_ID || '';
// used for loading rooms and to book a room
// can be specified in the env to make test bookings
const testMode = process.env.REACT_APP_TEST_MODE || '';

const operaHotelCode = process.env.REACT_APP_OPERA_HOTEL_CODE || '';
const operaChainCode = process.env.REACT_APP_OPERA_CHAIN_CODE || '';

// Sentry react
const sentryReactDsn = process.env.REACT_APP_SENTRY_DSN || '';

module.exports = {
  site,
  gtmId,
  gtmAuth,
  gtmPreview,
  hotjarId,
  heapId,
  testMode,
  operaHotelCode,
  operaChainCode,
  sentryReactDsn,
};
