/**
 * Renders Reservations step.
 * NOTE: name in steps is Summary.
 */
import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { oneOfType, object, func, array, bool, string, number } from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import to from 'await-to-js';
import PriceItems from '../../../Headers/PriceItems';
import { setStep } from '../../../../redux/modules/bookingForm';
import { addNotificationQueue } from '../../../../redux/modules/notifications';
import { setModalParams } from '../../../../redux/modules/modals';
import { getBookings } from '../../../../redux/modules/bookings';
import {
  updateSession,
  getUserSessionAddonsWithDetails,
  getUserSessionAddons,
  updateUserSessionLoading,
} from '../../../../redux/modules/session';
import { getStartDate, getEndDate } from '../../../../redux/modules/rangeController';
import { getAddonsPrices } from '../../../../redux/modules/bookingFormData';
import { getResultError, checkSameAsRestoredStep, scrollToElement } from '../../../../utils/helpers';
import { DEFAULT_ERR_MSG } from '../../../../utils/notificationsFormat';
import { getFormattedNumber } from '../../../../utils/priceBreakdown';

import styles from './Reservations.module.scss';
import ReservationRoom from './ReservationRoom';
import ReservationAddon from './ReservationAddon';
import ReservationsLoader from './ReservationsLoader';

const CANCELLATION_POLICY_DAYS = 14;

const mapStateToProps = (state) => ({
  addons: getUserSessionAddons(state),
  addonsWithDetails: getUserSessionAddonsWithDetails(state),
  bookings: getBookings(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  updateSessionLoading: updateUserSessionLoading(state),
  addonsPrices: getAddonsPrices(state),
});

const mapDispatchToProps = {
  setStep,
  addNotificationQueue,
  setModalParams,
  updateSession,
};

class Reservations extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // checks if current step are the same as restored step
      // to prevent the data to be loaded twice
      isReservationsLoaded: checkSameAsRestoredStep('Summary', 1),
    };

    this._reqSource = null;
  }

  componentDidMount() {
    const { isReservationsLoaded } = this.state;

    !isReservationsLoaded && this.loadReservations(); // eslint-disable-line
    scrollToElement('#js-steps-wrapper', 60);
  }

  componentWillUnmount() {
    this._reqSource && this._reqSource.cancel(); // eslint-disable-line
  }

  /**
   * Gets invoked when it's time to change step
   * and sets data according to passed params.
   * @param {number} [nextStep] - next step
   * @param {number} [nextSubstep] - next sub step
   * @param {number} [finishedStep] - finished step
   */
  handleSetStep = (nextStep, nextSubstep, finishedStep) => () => {
    const { setStep } = this.props; // eslint-disable-line no-shadow

    setStep(nextStep, nextSubstep, finishedStep);
  };

  /**
   * Removes clicked add-on.
   * @param {object} addon - data with clicked add-on
   */
  handleRemoveAddon = (addon) => async () => {
    const { updateSession, addons: prevAddons, addNotificationQueue } = this.props; // eslint-disable-line
    let nextAddons = [...prevAddons];
    const isExists = prevAddons.includes(addon.id);
    if (isExists) {
      nextAddons = nextAddons.filter((c) => c !== addon.id);
    } else {
      nextAddons = [...nextAddons, addon.id];
    }
    const [err, res = {}] = await to(updateSession({ addons: nextAddons }));
    const _err = err || getResultError(res.data);

    if (_err) {
      addNotificationQueue(DEFAULT_ERR_MSG, { type: 'warning', autoclose: false });
    }
  };

  /**
   * Triggers when the "want to start over" button is clicked.
   */
  handleReset = () => {
    const { setModalParams } = this.props; // eslint-disable-line no-shadow

    setModalParams({
      bookingFormModalData: { type: 'new search' },
    });
  };

  /**
   * Loads reservation items.
   */
  loadReservations() {
    this.setState({ isReservationsLoaded: true });
  }

  /**
   * RENDER methods BELOW
   */
  renderRoom() {
    const {
      bookings: { room },
      startDate,
      endDate,
    } = this.props;

    if (!room) return null;
    const startDateFormatted = startDate && moment(startDate).format('MMMM DD, YYYY');
    const endDateFormatted = endDate && moment(endDate).format('MMMM DD, YYYY');

    return (
      <ReservationRoom
        title={room.title}
        photo={room.photo}
        startDate={startDateFormatted}
        endDate={endDateFormatted}
        price={getFormattedNumber(room.priceSubtotal)}
        handleEditRoom={this.handleSetStep(2, 1)}
      />
    );
  }

  renderAddons() {
    const { addonsWithDetails, updateSessionLoading } = this.props;

    return addonsWithDetails.map((c) => (
      <ReservationAddon
        {...c}
        key={c.id}
        price={getFormattedNumber(c.price)}
        removeDisabled={updateSessionLoading}
        handleRemoveAddon={this.handleRemoveAddon(c)}
      />
    ));
  }

  renderFooter() {
    const { bookings, addonsPrices } = this.props;

    return (
      <div className={styles.reservations__footer}>
        <div className={styles['reservations__footer-section']}>
          <div className={cx(styles.reservations__item, styles['reservations__item--prices'])}>
            <div className={styles.reservations__box}>
              <div className={styles['reservations__item-name']}>Price Breakdown</div>
              {bookings.room && <PriceItems room={bookings.room} addons={addonsPrices} />}
            </div>
          </div>
          <div className={cx(styles.reservations__item, styles['reservations__item--total'])}>
            <div className={styles.reservations__box}>
              <div className={styles['reservations__item-name']}>
                grand total <span>(USD)</span>
              </div>
              {bookings.room && bookings.room.priceTotal && (
                <div className={styles['reservations__item-price']}>
                  ${getFormattedNumber(bookings.room.priceTotal + addonsPrices)}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles['reservations__footer-section']}>
          <div className={styles.reservations__cancellation}>
            <div className={styles['reservations__item-name']}>Cancellation Policy</div>
            <div className={styles.reservations__descr}>
              Reservations must be cancelled at least {CANCELLATION_POLICY_DAYS} days before arrival date.
            </div>
          </div>
        </div>
        <div className={styles['reservations__footer-section']}>
          <div className={styles.reservations__book}>
            <button className={styles['reservations__btn-book']} type="button" onClick={this.handleSetStep(4, 1, 3)}>
              Check out now
            </button>
            <button className={styles['reservations__btn-reset']} type="button" onClick={this.handleReset}>
              Want to start over?
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderContent() {
    const { isReservationsLoaded } = this.state;

    if (!isReservationsLoaded) {
      return (
        <div className={styles.reservations__items}>
          <ReservationsLoader />
        </div>
      );
    }

    return (
      <>
        <div className={styles.reservations__items}>
          {this.renderRoom()}
          {this.renderAddons()}
        </div>
        <div className={styles.reservations__add}>
          <button className={styles['reservations__btn-add']} type="button" onClick={this.handleSetStep(2, 2)}>
            <span>add more items</span>
          </button>
        </div>
        {this.renderFooter()}
      </>
    );
  }

  render() {
    return (
      <div className={styles.reservations}>
        <div className={styles.reservations__wrap}>
          <h2 className="booking-form__ttl">Reservation Summary</h2>
          <div className="booking-form__sub-ttl">
            Please take a moment to review the contents of your booking carefully.
          </div>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

Reservations.propTypes = {
  addNotificationQueue: func,
  addonsPrices: oneOfType([string, number]),
  addonsWithDetails: array.isRequired,
  bookings: object,
  endDate: object,
  sessionData: object,
  setModalParams: func,
  setStep: func,
  startDate: object,
  updateSession: func.isRequired,
  updateSessionLoading: bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reservations);
