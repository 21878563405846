import React from 'react';
import { oneOfType, node, object, string } from 'prop-types';
import cx from 'classnames';
import { FormFieldLabel, FormFieldError } from '..';

import './FormField.module.scss';

function FormField({ children, label, meta, name, className }) {
  const { touched, error } = meta;
  const hasError = !!(touched && error);

  return (
    <div
      className={cx('form-field', {
        [className]: !!className,
        'form-field--error': hasError,
      })}
    >
      {label && <FormFieldLabel name={name} label={label} />}
      <div className="form-field__children">{children}</div>
      {hasError && <FormFieldError text={error} />}
    </div>
  );
}

FormField.propTypes = {
  name: string.isRequired,
  meta: object.isRequired,
  children: node.isRequired,
  className: string,
  label: oneOfType([string, node]),
};

export default FormField;
