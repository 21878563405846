import React, { Component, Fragment } from 'react';
import { func } from 'prop-types';
import { Confirm } from '../../../elements';

class SessionExpired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  /**
   * Resets all the data
   */
  handleSessionExpired = async () => {
    const { resetAllData } = this.props;

    this.setState({ isLoading: true });
    resetAllData();
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div className="modal__note">
        <Confirm
          title={
            <>
              <span>important note</span>
              <span className="modal__icon-info" />
            </>
          }
          description="Your session has timed out."
          confirmText="Start Over"
          onConfirm={this.handleSessionExpired}
          disabled={isLoading}
          isAlert
        />
      </div>
    );
  }
}

SessionExpired.propTypes = {
  resetAllData: func,
};

export default SessionExpired;
