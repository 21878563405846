import React, { Component } from 'react';
import { oneOfType, string, node, bool, func } from 'prop-types';
import cx from 'classnames';

import styles from './Toggler.module.scss';

export default class Toggler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
    };

    const { isOpened, trigger } = props;

    /*
      if isOpened prop is passed means that component is controlled
      and whether it opened or not is defined with prop
    */
    this._isControlled = isOpened !== undefined;
    this._trigger = typeof trigger === 'function' ? (nextIssOpened) => trigger(nextIssOpened) : () => props.trigger;
  }

  /*
    gets invoked when the trigger button is clicked
  */
  handleTriggerClick = () => {
    if (this._isControlled) {
      const { onTriggerClick } = this.props;

      onTriggerClick();
      return;
    }

    this.setState((prevState) => ({
      isOpened: !prevState.isOpened,
    }));
  };

  render() {
    const { children, className, classNameBody, isDisabled } = this.props;
    const isOpened = this.props.isOpened || this.state.isOpened;

    return (
      <div
        className={cx(styles.toggler, {
          [className]: !!className,
          [styles['toggler--is-opened']]: isOpened,
          [styles['toggler--is-disabled']]: isDisabled,
        })}
      >
        <div className={styles.toggler__trigger} onClick={!isDisabled ? this.handleTriggerClick : undefined}>
          {this._trigger(isOpened)}
        </div>
        {isOpened && (
          <div
            className={cx(styles.toggler__body, {
              [classNameBody]: !!classNameBody,
            })}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}

Toggler.defaultProps = {
  onTriggerClick: () => {},
};

Toggler.propTypes = {
  className: string,
  classNameBody: string,
  trigger: oneOfType([string, node, func]).isRequired,
  children: node.isRequired,
  isOpened: bool,
  isDisabled: bool,
  onTriggerClick: func,
};
