import { parsePhoneNumberFromString } from 'libphonenumber-js';
import cardValidator from 'card-validator';

const isEmpty = (value) => value === undefined || value === null || value === '' || !value; // eslint-disable-line
const join = (rules) => (value, data) => rules.map((rule) => rule(value, data)).filter((error) => !!error)[0]; // eslint-disable-line

export function email(value) {
  // Let's not start a debate on email regex. This is just for an example app!
  if (!isEmpty(value) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address';
  }
}

export function required(value) {
  const _value = value && value.trim();

  if (isEmpty(_value)) {
    return 'Required';
  }
}

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
  };
}

export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
  };
}

export function integer(value) {
  if (!Number.isInteger(Number(value))) {
    return 'Must be an integer';
  }
}

export function phoneNumber(error = 'Must be a valid phone number', defaultCountry = 'US') {
  return (value) => {
    // https://stackoverflow.com/a/29767609/5464360
    const regex = /^[0-9+-.]+$/; // eslint-disable-line
    const isValid = regex.test(value);
    const _phoneNumber = value && parsePhoneNumberFromString(value, defaultCountry);

    if (!isValid || !(_phoneNumber && _phoneNumber.isValid())) {
      return error;
    }
  };
}

export function oneOf(enumeration) {
  return (value) => {
    if (!~enumeration.indexOf(value)) { // eslint-disable-line
      return `Must be one of: ${enumeration.join(', ')}`;
    }
  };
}

export function match(field) {
  return (value, data) => {
    if (data) {
      if (value !== data[field]) {
        return 'Do not match';
      }
    }
  };
}

export function phone(value) {
  if (!/^(\d|\+)[0-9+() -]+$/.test(value)) {
    return 'Invalid phone format';
  }
}

export function cardNumber(value) {
  const validation = cardValidator.number(value);

  if (!validation.isValid) {
    return 'Please enter a valid credit card number';
  }
}

export function expirationDate(value) {
  const validation = cardValidator.expirationDate(value);

  if (!validation.isValid) {
    return 'Please enter a valid expiration date';
  }
}

export function cvv(value) {
  if (value && value.length < 3) {
    return 'Please enter a valid card verification code';
  }
}

export function createValidator(rules) {
  return (data = {}) => {
    const errors = {};

    Object.keys(rules).forEach((key) => {
      const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
      const error = rule(data[key], data);

      if (error) {
        errors[key] = error;
      }
    });

    return errors;
  };
}
