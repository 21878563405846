import { addNotificationQueue } from '../redux/modules/notifications';
import { store } from '../redux/store';
import { DEFAULT_ERR_MSG } from './notificationsFormat';

export function showErrorNotif(message, params = {}) {
  const _message = message || DEFAULT_ERR_MSG;

  store.dispatch(
    addNotificationQueue(_message, {
      toastId: '__error',
      type: 'warning',
      autoClose: false,
      ...params,
    })
  );
}
