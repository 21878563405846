/*
  renders agreement content of the BookingModal
*/
import React, { Fragment } from 'react';
import { func } from 'prop-types';
import { Confirm } from '../../../elements';

import agreementData from '../../AddonsList/agreement.yaml';

function handleAccept({ setStep, closeModal }) {
  // activeStep, activeSubStep, finishedStep
  setStep(3, null, 2);
  closeModal();
}

function AddonsAgreement(props) {
  const { closeModal } = props;

  return (
    <div className="modal__note">
      <Confirm
        title={
          <>
            <span>important note</span>
            <span className="modal__icon-info" />
          </>
        }
        htmlDescription={agreementData.agreement}
        cancelText="cancel"
        confirmText="accept"
        onConfirm={() => handleAccept(props)}
        onCancel={closeModal}
      />
    </div>
  );
}

AddonsAgreement.propTypes = {
  closeModal: func,
};

export default AddonsAgreement;
