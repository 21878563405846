import React, { Component } from 'react';
import { number, object } from 'prop-types';
import { connect } from 'react-redux';
import { MyInfo, PaymentInfo, Confirmations } from '../../..';
import { getActiveStep, getSteps } from '../../../../redux/modules/bookingForm';

import BookingFormTransitions from '../../BookingFormTransitions';
import styles from './Checkout.module.scss';

const mapStateToProps = (state) => ({
  activeStep: getActiveStep(state),
  steps: getSteps(state),
});

const mapDispatchToProps = {};

class Checkout extends Component {
  shouldComponentUpdate(nextProps) {
    // prevents possible redundant children mounts
    // do not proceed if not equal actual step (activeStep)
    if (nextProps.activeStep !== 4) return false;

    return true;
  }

  /*
    renders substep data according to the active step and substep
  */
  renderSubSteps() {
    const { activeStep, steps } = this.props;
    const activeSubStep = steps[activeStep].subSteps.active;

    switch (activeSubStep) {
      case 1:
        return <MyInfo />;
      case 2:
        return <PaymentInfo />;
      case 3:
        return <Confirmations />;
      default:
        return null;
    }
  }

  render() {
    const { activeStep, steps } = this.props;
    const activeSubStep = steps[activeStep].subSteps.active;

    return (
      <div className={styles.checkout}>
        <BookingFormTransitions activeStep={activeSubStep}>{this.renderSubSteps(activeSubStep)}</BookingFormTransitions>
      </div>
    );
  }
}

Checkout.propTypes = {
  activeStep: number,
  steps: object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
