import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.footer__container}>
      <div className={styles.footer__copyright}>
        © 2019 Calamigos Guest Ranch and Beach Club | Est. 1937 in Malibu, CA
      </div>
      <ul className={styles.footer__list}>
        <li className={styles.footer__item}>
          <span>Need assistance? Call </span>
          <a href="tel:818-575-4400">818-575-4400</a>
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
