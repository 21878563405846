import React, { Component } from 'react';
import { string, number, func } from 'prop-types';
import anime from 'animejs';
import { getSvgPath } from '../../../../utils/helpers';

// define some contants with colors
const EMPTY_COLOR = '#e5e5e5';
const FILLED_COLOR = '#a58962';
const ACTIVE_COLOR = '#fff';

// animation duration of the step
const STEP_DURATION = 300;

// initial styles for base svg
const INITIAL_STYLES = `
  .el-empty {
    fill: none;
    stroke: ${EMPTY_COLOR};
  }
`;

// initial styles for controlled fill animation
const INITIAL_FILLED_STYLES = `
  .el-filled {
    fill: ${EMPTY_COLOR};
    stroke: ${FILLED_COLOR};
  }
  .el-filled-nums {
    fill: none;
    opacity: 0
  }
`;

/**
 * The component is based on two identical animations that lay one above the other.
 * The first one is animation with non-changeable styles used for basic svg drawing.
 * The second one is animation with some controlled styles used for fill-in steps.
 */
class StepsAnimation extends Component {
  constructor() {
    super();

    // define class variables that will be used later
    this._filledSvg = null;
    this._prevStep = null;
  }

  componentDidMount() {
    this.initAnimation();
  }

  componentDidUpdate(prevProps) {
    const { activeStep: prevActiveStep, finishedStep: prevFinishedStep } = prevProps;
    const { activeStep, finishedStep } = this.props;

    // update animation progress if active step has changed
    if (prevActiveStep !== activeStep) {
      const isIncreasingWithCircles = finishedStep > prevFinishedStep;

      this.setProgress(activeStep, isIncreasingWithCircles);
    }
  }

  /**
   * method that returns array of elements according to params and some conditions
   * the goal was to pass startStep && endStep and get elements between these steps
   * then animate them one by one
   * @param {number} startStep - step to start from
   * @param {number} endStep - step to end
   * @param {boolean} [includeStart] - should the elements contain a startElement
   * @param {array} [elements] - elements to get other elements from. default is elements from filled animation
   * @returns {array}
   */
  getIntermediateElements(startStep, endStep, includeStart, elements) {
    const _elements = elements || this._filledSvg.querySelectorAll('.el-filled');
    const isReversed = startStep > endStep;
    const isEqual = startStep === endStep;
    // steps are 1-based but indexes in the array are 0-based
    // so if startStep/endStep is passed calc zero-based index
    const startIndex = (startStep && startStep - 1) || 0;
    const endIndex = (endStep && endStep - 1) || 0;
    let start;
    let end;

    /**
     * In this concere animation there are lines between step circles
     * so the basic formula could be n * 2 - 1 but slice method doesn't include end.
     *
     * For initial fill-in animation we need to animate
     * start step circle, end step circle and all elements between them
     * for future animations we already have animated start circle so we need to animate
     * elements like in the initial animation BUT actual startStep.
     */
    if (isEqual) {
      start = startIndex * 2;
      end = startIndex * 2 + 1;
    } else if (!isReversed) {
      start = startIndex * 2 + (includeStart ? 0 : 1);
      end = endIndex * 2 + 1;
    } else {
      start = endIndex * 2;
      end = startIndex * 2 + (includeStart ? 1 : 0);
    }
    const sliced = [].slice.call(_elements, start, end);

    return !isReversed ? sliced : sliced.reverse();
  }

  /**
   * Sets initial progress when the animation initializes
   */
  async setInitialProgress() {
    const { activeStep, finishedStep, setBookingFormParams } = this.props;
    const maxAvailableStep = finishedStep + 1;
    // calc element index according to activeStep
    // (circles PLUS lines between them)
    const indexFromStep = activeStep * 2 - 1;

    // get all elements
    const elements = this.getIntermediateElements(0, maxAvailableStep, true);
    // filter elements to get circles and do not add circle with active step
    const circles = elements.filter((c, i) => {
      const itemIndex = i + 1;
      const tagName = c.tagName.toLowerCase();

      return tagName === 'circle' && itemIndex !== indexFromStep;
    });
    const timeline = anime.timeline({
      autoplay: false,
      duration: STEP_DURATION,
    });

    /**
     * When the session is restored there could be available/finished steps AFTER active step.
     * @example
     * finishedStep = 3 (so maxAvailableStep = 4), activeStep = 2
     * it means that 4 circles should be available and 3 (except active)
     * should be filled BUT filled LINES are lines BEFORE activeStep.
     */
    elements.forEach((c, i) => {
      const itemIndex = i + 1;
      const tagName = c.tagName.toLowerCase();
      // add to timeline all elements BUT lines that after maxAvailableStep
      if (tagName === 'line' && itemIndex > indexFromStep) return;

      timeline.add({
        targets: c,
        strokeDashoffset: { value: 0, easing: 'easeInOutQuad' },
        fill: { value: ACTIVE_COLOR }, // fills body of the circle (not stroke)
      });
    });
    timeline.play();

    await timeline.finished;

    this.fillFinished(null, circles);
    this.setInitialNumbers(activeStep);

    setBookingFormParams({ isAnimatingProgress: false });
    this._prevStep = activeStep;
  }

  /**
   * method to start animation progress for fill animation
   * @param {number} step - new active step
   */
  async setProgress(step, isIncreasingWithCircles) {
    const { setBookingFormParams } = this.props;

    setBookingFormParams({ isAnimatingProgress: true });

    // if finished step has changed
    if (isIncreasingWithCircles) {
      await this.handleIncreaseWithCircles(step);
    } else {
      this.handleChangeWithoutCircles(step);
    }

    this.setNumbers(step);
    setBookingFormParams({ isAnimatingProgress: false });
    this._prevStep = step; // this._prevStep is IMPORTANT for the whole animation
  }

  /**
   * set initial colors for initial fill animation
   * different colors for different cases (finished or disabled element color)
   * @param {number} activeStep - new active step
   */
  setInitialNumbers(activeStep) {
    const numbers = this._filledSvg.querySelectorAll('.el-filled-nums-item');

    numbers.forEach((c, i) => {
      const itemIndex = i + 1;
      const isActive = itemIndex === activeStep;
      const color = isActive ? FILLED_COLOR : ACTIVE_COLOR;

      c.setAttribute('fill', color);
    });

    return anime({
      targets: '.el-filled-nums', // set target as all numbers
      opacity: { value: 1 },
      duration: STEP_DURATION * 2,
    }).finished;
  }

  /**
   * animates next active and previous numbers inside circles for future animations
   * @param {number} step - new step number
   */
  setNumbers(step) {
    const _prevStep = this._prevStep; // eslint-disable-line prefer-destructuring
    const numbers = this._filledSvg.querySelectorAll('.el-filled-nums-item');
    const stepIndex = step - 1;
    const prevStepIndex = _prevStep - 1;
    const stepNum = numbers[stepIndex];
    const prevStepNum = numbers[prevStepIndex];

    // animate active number
    anime({
      targets: stepNum,
      // active step number fills with filled_color
      fill: FILLED_COLOR,
      duration: STEP_DURATION * 2,
    });
    // animate previous number
    anime({
      targets: prevStepNum,
      // finished step number fills with active_color
      fill: ACTIVE_COLOR,
      duration: STEP_DURATION * 2,
    });
  }

  /**
   * fills finished step
   * @param {number} step - step number to fill
   * @param {array} [elements] - elements to animate
   */
  fillFinished = (step, elements) => {
    const { finishedStep } = this.props;
    let _elements = elements;

    if (!_elements) {
      const _step = step || finishedStep;
      // array with single element will return
      _elements = this.getIntermediateElements(_step, _step);
    }

    return anime({
      targets: _elements,
      duration: STEP_DURATION * 2,
      fill: FILLED_COLOR,
    }).finished;
  };

  /**
   * fills active step
   * @param {number} step - step number to fill
   */
  fillActive = (step) => {
    const { activeStep } = this.props;
    const _step = step || activeStep;
    const circle = this.getIntermediateElements(_step, _step);

    return anime({
      targets: circle,
      duration: STEP_DURATION * 2,
      fill: { value: ACTIVE_COLOR },
    }).finished;
  };

  /**
   * animates lines, new circles and fills new finished step
   * when finishedStep increases
   * @param {number} step - new active step
   */
  handleIncreaseWithCircles = async (step) => {
    const elements = this.getIntermediateElements(this._prevStep, step);

    const timeline = anime.timeline({
      autoplay: false,
      duration: STEP_DURATION,
    });
    elements.forEach((c) => {
      timeline.add({
        targets: c,
        strokeDashoffset: { value: 0, easing: 'easeInOutQuad' },
        fill: ACTIVE_COLOR,
      });
    });
    timeline.play();

    await timeline.finished;
    this.fillFinished();

    return Promise.resolve();
  };

  /**
   * animates lines, prev circles, fills previous step and new active step
   * when finishedStep stays the same but activeStep changes
   * @param {number} step - new active step
   */
  handleChangeWithoutCircles = (step) => {
    const isReversed = this._prevStep > step;
    const deltaStep = Math.abs(this._prevStep - step);
    const elements = this.getIntermediateElements(this._prevStep, step);
    const timeline = anime.timeline({
      autoplay: false,
      duration: STEP_DURATION / deltaStep,
    });

    elements.forEach((c) => {
      if (c.tagName.toLowerCase() !== 'line') return;
      const strokeDashoffsetValue = c.getAttribute('stroke-dashoffset');

      timeline.add({
        targets: c,
        strokeDashoffset: {
          value: isReversed ? strokeDashoffsetValue : 0,
          easing: 'easeInOutQuad',
        },
      });
    });
    timeline.play();

    const _step = step;
    const _prevStep = this._prevStep; // eslint-disable-line prefer-destructuring

    this.fillActive(_step);
    this.fillFinished(_prevStep);

    return Promise.resolve();
  };

  /*
  method for starting the animations
  */
  initAnimation() {
    const { setBookingFormParams } = this.props;

    this._filledSvg = document.querySelector('.steps-animation__filled');
    setBookingFormParams({ isAnimatingProgress: true });
    this.hideAllFilledStrokes();

    anime({
      targets: '.el-empty',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutQuad',
      duration: 500,
      direction: 'alternate',
      loop: false,
      fill: [{ value: ACTIVE_COLOR }, { value: EMPTY_COLOR }],
      complete: () => {
        // on complete show block with filled svg and start fill-in animation
        this._filledSvg.style.opacity = 1;
        this.setInitialProgress();
      },
    });
  }

  /*
    method that hides all the strokes for further animation (fill animation)
  */
  hideAllFilledStrokes() {
    const elements = this._filledSvg.querySelectorAll('.el-filled');

    elements.forEach((c) => {
      const elType = c.tagName.toLowerCase();
      // some browsers doesn't support getTotalLength or can return 0
      const totalLength = c.getTotalLength && c.getTotalLength();
      const elementLength = totalLength || getSvgPath[elType](c);

      c.setAttribute('stroke-dasharray', elementLength);
      c.setAttribute('stroke-dashoffset', elementLength);
    });
  }

  /*
    renders svg numbers
  */
  renderNums = () => (
    <g className="el-filled-nums">
      <polygon
        className="el-filled-nums-item"
        transform="translate(-4 -10)"
        points="36.203,33.5 45.797,33.5 45.797,58.5 41.588,58.5 41.588,37.28 36.203,37.28"
      />
      <path
        className="el-filled-nums-item"
        transform="translate(-2 -10)"
        d="M302.637,45.646c1.877-1.877,3.152-3.86,3.152-5.276c0-2.018-1.629-3.187-4.355-3.187
        c-2.231,0-4.851,1.169-6.976,3.081l-1.7-3.222c2.762-2.195,5.984-3.541,9.242-3.541c4.851,0,8.144,2.443,8.144,6.268
        c0,2.479-1.948,5.276-4.674,8.109l-6.799,6.87h12.677V58.5h-18.166v-3.081L302.637,45.646z"
      />
      <path
        className="el-filled-nums-item"
        d="M564.613,34.026c4.645,0.39,7.766,2.766,7.766,6.702c0,4.645-3.759,7.872-9.752,7.872
        c-3.298,0-6.738-1.064-9.007-2.695l1.738-3.404c1.95,1.667,4.468,2.589,6.986,2.589c3.759,0,5.957-1.702,5.957-4.291
        c0-2.695-2.234-4.078-6.028-4.078h-3.617v-2.411l7.27-6.986h-11.064l-0.035-3.723h16.312v2.624l-7.979,7.695L564.613,34.026z"
      />
      <polygon
        className="el-filled-nums-item"
        points="824.841,23.7 828.907,23.7 820.633,38.786 828.3,38.786 828.265,32.901
        832.188,32.901 832.223,38.786 835.897,38.786 835.897,42.566 832.188,42.566 832.188,48.7 828.265,48.7 828.265,42.566
        816.103,42.566 816.103,39.428"
      />
    </g>
  );

  /**
   * renders svg elements
   * @param {string} className - class name of the svg element
   * @param {string} elClassName - class name of the inner svg elements (lines || circles)
   * @param {string} [svgStyles] - inner styles for svg element
   * @param {object} [cssStyles] - outer styles for svg element
   */
  renderSvg(className, elClassName, svgStyles = '', cssStyles = {}) {
    return (
      <svg
        className={className}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 863 74"
        xmlSpace="preserve"
        style={cssStyles}
      >
        <style dangerouslySetInnerHTML={{ __html: svgStyles }} // eslint-disable-line
        />
        <circle
          className={elClassName}
          transform="rotate(180 37 37)"
          strokeWidth="3"
          strokeMiterlimit="10"
          cx="37"
          cy="37"
          r="35.5"
        />
        <line className={elClassName} strokeWidth="4" strokeMiterlimit="10" x1="72" y1="36.5" x2="265" y2="36.5" />
        <circle
          className={elClassName}
          transform="rotate(180 300 37)"
          strokeWidth="3"
          strokeMiterlimit="10"
          cx="300"
          cy="37"
          r="35.5"
        />
        <line className={elClassName} strokeWidth="4" strokeMiterlimit="10" x1="335" y1="36.5" x2="528" y2="36.5" />
        <circle
          className={elClassName}
          transform="rotate(180 563 37)"
          strokeWidth="3"
          strokeMiterlimit="10"
          cx="563"
          cy="37"
          r="35.5"
        />
        <line className={elClassName} strokeWidth="4" strokeMiterlimit="10" x1="598" y1="36.5" x2="791" y2="36.5" />
        <circle
          className={elClassName}
          transform="rotate(180 826 37)"
          strokeWidth="3"
          strokeMiterlimit="10"
          cx="826"
          cy="37"
          r="35.5"
        />
        {className === 'steps-animation__filled' && this.renderNums()}
      </svg>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className || ''}>
        <div className="steps-animation__holder" style={{ position: 'relative' }}>
          {this.renderSvg('steps-animation__initial', 'el-empty', INITIAL_STYLES, {
            display: 'block',
          })}
          {this.renderSvg('steps-animation__filled', 'el-filled', INITIAL_FILLED_STYLES, {
            opacity: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          })}
        </div>
        {/*  */}
      </div>
    );
  }
}

StepsAnimation.propTypes = {
  className: string,
  activeStep: number,
  finishedStep: number,
  setBookingFormParams: func,
};

export default StepsAnimation;
