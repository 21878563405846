/*
  renders list of the added add-ons
*/
import React from 'react';
import { array, func } from 'prop-types';
import { capitalizeEachWord } from '../../utils/helpers';

export default function HeadersAddons({ list, setStep }) {
  return list.map((c) => {
    const { title } = c;
    const capitalizedTitle = capitalizeEachWord(title);

    return (
      <div
        key={c.id}
        className="header__stay-item header__stay-item--clickable"
        title={capitalizedTitle}
        onClick={() => setStep(2, 2)}
      >
        <span>{capitalizedTitle}</span>
      </div>
    );
  });
}

HeadersAddons.propTypes = {
  list: array,
  setStep: func,
};
