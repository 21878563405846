import React from 'react';
import { func } from 'prop-types';

import styles from './SubHeader.module.scss';

function SubHeader({ renderContent }) {
  return (
    <div className={styles.sub_header}>
      <div className={styles.sub_header__container}>
        <div id="js-subheader-content" style={{ width: '100%' }}>
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

SubHeader.propTypes = {
  renderContent: func,
};

export default SubHeader;
