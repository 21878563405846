import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import cx from 'classnames';

import './DotsLoader.module.scss';

export function DotsLoader({ className, size }) {
  const round = (num) => Math.round(num * 100) / 100;
  const dotStyles = {
    width: `${size}px`,
    height: `${size}px`,
    margin: `0 ${round(size / 3)}px`,
  };

  return (
    <div className={cx('dots-loader', { [className]: className })}>
      <div className="dots-loader__dot" style={dotStyles} />
      <div className="dots-loader__dot" style={dotStyles} />
      <div className="dots-loader__dot" style={dotStyles} />
    </div>
  );
}

DotsLoader.propTypes = {
  className: string,
  size: oneOfType([string, number]),
};

DotsLoader.defaultProps = {
  size: 14,
};

export default DotsLoader;
