/* eslint-disable no-case-declarations */
import { createSelector } from 'reselect';
import { testMode, operaHotelCode, operaChainCode } from '../../../config/config';
import roomsData from '../../components/AccommodationsList/accommodations.yaml';
import { getResultErrorResponse, getIn } from '../../utils/helpers';

const STATE_KEY = 'bookings';

const UPDATE_GUEST_COUNT = `${STATE_KEY}/UPDATE_GUEST_COUNT`;

const FETCH_ROOM = `${STATE_KEY}/FETCH_ROOM`;
const FETCH_ROOM_SUCCESS = `${STATE_KEY}/FETCH_ROOM_SUCCESS`;
const FETCH_ROOM_FAIL = `${STATE_KEY}/FETCH_ROOM_FAIL`;

const CREATE_PAYMENT = `${STATE_KEY}/CREATE_PAYMENT`;
const CREATE_PAYMENT_SUCCESS = `${STATE_KEY}/CREATE_PAYMENT_SUCCESS`;
const CREATE_PAYMENT_FAIL = `${STATE_KEY}/CREATE_PAYMENT_FAIL`;

const CLEAR = `${STATE_KEY}/CLEAR`;

const initialState = {
  room: null,
  isCreatingPayment: false,
  folioId: null,
  guestCount: {
    adultCount: null,
    childCount: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_ROOM:
      return { ...state, isBookingRoom: true };
    case FETCH_ROOM_SUCCESS: {
      const { result } = action;
      const roomId = testMode === 'true' ? 'test_id' : 'id';
      const roomData = roomsData.rooms.find((room) => room[roomId] === action.payload.room);
      const error = getResultErrorResponse(result, 'AvailabilityResponse');
      if (error) return { ...state, isBookingRoom: false };

      const priceRate = +getIn(
        result,
        'result.0.AvailabilityResponse.0.AvailResponseSegments.0.a:AvailResponseSegment.0.a:RoomStayList.0.hc:RoomStay.0.hc:RoomRates.0.hc:RoomRate.0.hc:Rates.0.hc:Rate.0.hc:Base.0._'
      );
      // const priceTotal = +getIn(
      //   result,
      //   'result.0.AvailabilityResponse.0.AvailResponseSegments.0.a:AvailResponseSegment.0.a:RoomStayList.0.hc:RoomStay.0.hc:RoomRates.0.hc:RoomRate.0.hc:Total.0._'
      // );
      const priceSubtotal = +getIn(
        result,
        'result.0.AvailabilityResponse.0.AvailResponseSegments.0.a:AvailResponseSegment.0.a:RoomStayList.0.hc:RoomStay.0.hc:ExpectedCharges.0.$.TotalRoomRateAndPackages'
      );
      const priceSurcharges = +getIn(
        result,
        'result.0.AvailabilityResponse.0.AvailResponseSegments.0.a:AvailResponseSegment.0.a:RoomStayList.0.hc:RoomStay.0.hc:ExpectedCharges.0.$.TotalTaxesAndFees'
      );
      const priceTotal = +priceSubtotal + +priceSurcharges;
      const nextRoom = {
        id: roomData[roomId],
        photo: roomData.photo,
        title: roomData.title,
        priceRate,
        priceSubtotal,
        priceSurcharges,
        priceTotal,
      };

      return {
        ...state,
        isBookingRoom: false,
        room: nextRoom,
      };
    }
    case FETCH_ROOM_FAIL:
      return { ...state, isBookingRoom: false };

    case CREATE_PAYMENT:
      return { ...state, isCreatingPayment: true };
    case CREATE_PAYMENT_SUCCESS:
      const folioId =
        getIn(action.result, 'result.0.CreateBookingResponse.0.HotelReservation.0.r:UniqueIDList.0.c:UniqueID.0._') ||
        '';
      return { ...state, isCreatingPayment: false, folioId };
    case CREATE_PAYMENT_FAIL:
      return { ...state, isCreatingPayment: false };

    case UPDATE_GUEST_COUNT:
      return { ...state, guestCount: action.payload };

    case CLEAR:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

export function updateGuestCount(newGuestCount) {
  return {
    type: UPDATE_GUEST_COUNT,
    payload: newGuestCount,
  };
}

export function fetchRoom(payload) {
  const data = {
    AvailabilityRequest: {
      $: {
        'xmlns:a': 'http://webservices.micros.com/og/4.3/Availability/',
        'xmlns:hc': 'http://webservices.micros.com/og/4.3/HotelCommon/',
        summaryOnly: 'false',
        xmlns: 'http://webservices.micros.com/ows/5.1/Availability.wsdl',
      },
      'a:AvailRequestSegment': {
        $: {
          availReqType: 'Room',
          numberOfRooms: '1',
          totalNumberOfGuests: '1',
          totalNumberOfChildren: '0',
        },
        'a:StayDateRange': {
          'hc:StartDate': {
            _: payload.arrivalDate,
          },
          'hc:EndDate': {
            _: payload.departureDate,
          },
        },
        'a:RatePlanCandidates': {
          'a:RatePlanCandidate': {
            $: {
              ratePlanCode: 'RACK',
            },
          },
        },
        'a:RoomStayCandidates': {
          'a:RoomStayCandidate': {
            $: {
              roomTypeCode: payload.room,
            },
          },
        },
        'a:HotelSearchCriteria': {
          'a:Criterion': {
            'a:HotelRef': {
              $: {
                chainCode: operaChainCode,
                hotelCode: operaHotelCode,
              },
            },
          },
        },
      },
    },
  };

  return {
    types: [FETCH_ROOM, FETCH_ROOM_SUCCESS, FETCH_ROOM_FAIL],
    promise: (client) => client.post('/api/availability', { data }),
    payload,
  };
}

export function createPayment(formData = {}, emailData = {}) {
  let packages = [];
  if (formData.addons) {
    packages = formData.addons.map((c) => ({
      'hc:Package': {
        $: {
          packageCode: c,
          quantity: '1',
        },
        'hc:StartDate': {
          _: formData.arrivalDate,
        },
        'hc:EndDate': {
          _: formData.departureDate,
        },
      },
    }));
  }

  const headers = { 'x-payment': true };

  const data = {
    emailData,
    CreateBookingRequest: {
      $: {
        xmlns: 'http://webservices.micros.com/ows/5.1/Reservation.wsdl',
      },
      HotelReservation: {
        'r:RoomStays': {
          'hc:RoomStay': {
            'hc:RatePlans': {
              'hc:RatePlan': {
                $: {
                  ratePlanCode: 'RACK',
                },
              },
            },
            'hc:RoomTypes': {
              'hc:RoomType': {
                $: {
                  roomTypeCode: formData.room,
                  numberOfUnits: '1',
                },
              },
            },
            'hc:RoomRates': {
              'hc:RoomRate': {
                $: {
                  roomTypeCode: formData.room,
                  ratePlanCode: 'DAILY',
                },
              },
            },
            'hc:GuestCounts': {
              'hc:GuestCount': [
                {
                  $: {
                    ageQualifyingCode: 'ADULT',
                    count: formData.guestCount.adultCount.toString(),
                  },
                },
                {
                  $: {
                    ageQualifyingCode: 'CHILD',
                    count: formData.guestCount.childCount.toString(),
                  },
                },
              ],
            },
            'hc:TimeSpan': {
              'hc:StartDate': {
                _: formData.arrivalDate,
              },
              'hc:EndDate': {
                _: formData.departureDate,
              },
            },
            'hc:Guarantee': {
              $: {
                guaranteeType: 'CC',
              },
              'hc:GuaranteesAccepted': {
                'hc:GuaranteeAccepted': {
                  'hc:GuaranteeCreditCard': {
                    'c:cardCode': {
                      _: formData.cardType,
                    },
                    'c:cardHolderName': {
                      _: formData.cardHolder,
                    },
                    'c:cardNumber': {
                      _: formData.cardNumber,
                    },
                    'c:expirationDate': {
                      _: formData.expiryDate,
                    },
                  },
                },
              },
            },
            'hc:Packages': packages,
            'hc:HotelReference': {
              $: {
                chainCode: operaChainCode,
                hotelCode: operaHotelCode,
              },
            },
            // 'hc:ResGuestRPHs': {
            //   ResGuestRPH: {
            //     $: {
            //       RPH: '0'
            //     }
            //   }
            // },
            // 'hc:Comments': {
            //   'hc:Comment': [
            //     {
            //       $: {
            //         guestViewable: 'false',
            //         commentOriginatorCode: 'CRO'
            //       },
            //       'hc:Text': {
            //         _: 'Comment 1'
            //       }
            //     },
            //     {
            //       $: {
            //         guestViewable: 'true'
            //       },
            //       'hc:Text': {
            //         _: 'Comment 2'
            //       }
            //     }
            //   ]
            // },
            // 'hc:SpecialRequests': {
            //   'hc:SpecialRequest': {
            //     $: {
            //       requestCode: 'COT'
            //     }
            //   }
            // }
          },
        },
        'r:ResGuests': {
          'r:ResGuest': {
            'r:Profiles': {
              'n:Profile': {
                'n:Customer': {
                  // $: {
                  //   gender: 'MALE'
                  // },
                  'n:PersonName': {
                    // 'c:nameTitle': {
                    //   _: 'Ms'
                    // },
                    'c:firstName': {
                      _: formData.fName,
                    },
                    'c:lastName': {
                      _: formData.lName,
                    },
                  },
                },
                'n:Addresses': {
                  'n:NameAddress': {
                    'c:AddressLine': {
                      _: formData.address,
                    },
                    'c:cityName': {
                      _: formData.city,
                    },
                    // 'c:stateProv': {
                    //   _: formData.stateProv
                    // },
                    // 'c:countryCode': {
                    //   _: 'US'
                    // },
                    'c:postalCode': {
                      _: formData.postalCode,
                    },
                  },
                },
                Phones: {
                  $: {
                    xmlns: 'http://webservices.micros.com/og/4.3/Name/',
                  },
                  NamePhone: [
                    {
                      $: {
                        phoneRole: 'PHONE',
                        phoneType: 'BUSINESS',
                        primary: 'true',
                        displaySequence: '1',
                      },
                      PhoneData: {
                        $: {
                          phoneNumber: emailData.PHONE,
                          xmlns: 'http://webservices.micros.com/og/4.3/Common/',
                        },
                      },
                    },
                    {
                      $: {
                        phoneRole: 'EMAIL',
                        phoneType: 'EMAIL',
                        primary: 'false',
                        displaySequence: '2',
                      },
                      PhoneData: {
                        $: {
                          phoneNumber: emailData.EMAIL,
                          xmlns: 'http://webservices.micros.com/og/4.3/Common/',
                        },
                      },
                    },
                  ],
                },
              },
            },
            // 'r:ArrivalTransport': {
            //   $: {
            //     carrierCode: 'AA',
            //     id: '421',
            //     locationCode: 'HEA',
            //     time: '2004-01-29T14:30:00.000'
            //   }
            // },
            // 'r:DepartureTransport': {
            //   $: {
            //     carrierCode: 'DE',
            //     id: '1421',
            //     locationCode: 'RSW',
            //     time: '2005-05-31T16:30:00.000',
            //     type: 'Train'
            //   }
            // }
          },
        },
      },
    },
  };

  return {
    types: [CREATE_PAYMENT, CREATE_PAYMENT_SUCCESS, CREATE_PAYMENT_FAIL],
    promise: (client) => client.post('/api/reservation', { data, headers }),
  };
}

export function clearBookings() {
  return { type: CLEAR };
}

// selectors
const getState = (state) => state[STATE_KEY];

export const getBookings = createSelector(getState, (state) => state);
export const getRoom = createSelector(getState, (state) => state.room);
export const getIsBookingRoom = createSelector(getState, (state) => state.isBookingRoom);
export const getIsCreatingPayment = createSelector(getState, (state) => state.isCreatingPayment);
export const getGuestCount = createSelector(getState, (state) => state.guestCount);
