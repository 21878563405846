/*
  Controls modals state and data.
*/
import { createSelector } from 'reselect';

const STATE_KEY = 'modals';

const SET_PARAMS = `${STATE_KEY}/SET_PARAMS`;

const UPDATE_MODAL = `${STATE_KEY}/UPDATE_MODAL`;

/**
 * @param {string} bookingFormModalData.type - type of the modal
 *   also controls whether modal is opened or not
 * @param {any} bookingFormModalData[.any] - rest of the passed content (if necessary)
 */
const initialState = {
  bookingFormModalData: {},
};

/*
  standard reducer Handling Actions
  In Redux, all the application state is stored as a single object.
  The reducer is a pure function that takes the previous state and an action, and returns the next state.
  It should calculate the next state and return it (according to the action.type).

  for more info please see
  https://redux.js.org/basics/actions
  https://redux.js.org/basics/reducers
*/
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PARAMS:
      return { ...state, ...action.params };
    case UPDATE_MODAL: {
      const { modalName, params } = action;
      const modal = state[modalName];
      if (!modal) {
        console.log('missing modal');
        return state;
      }

      return { ...state, [modalName]: { ...modal, ...params } };
    }
    default:
      return state;
  }
}

/**
 * Sets any data in reducer
 * @param {object} params - params to set any data of current reducer
 */
export function setModalParams(params = {}) {
  return { type: SET_PARAMS, params };
}

/**
 * Updates data in specific modal.
 * @param {string} modalName - name of the modal to update
 * @param {object} params - data to update specific modal
 */
export function updateModal(modalName, params = {}) {
  return { type: UPDATE_MODAL, modalName, params };
}

// selectors
const getState = (state) => state[STATE_KEY];

export const getBookingFormModalData = createSelector(getState, (state) => state.bookingFormModalData);
