/*
  Main component of the booking form
  that stores components for active step.
*/
import React, { Component } from 'react';
import { number, bool } from 'prop-types';
import { connect } from 'react-redux';
import { BookingSession, BookingFormModal } from '..';
import { getActiveStep, getFinishedStep, getIsLoaderVisible } from '../../redux/modules/bookingForm';

import * as Components from './components';
import BookingFormTransitions from './BookingFormTransitions';
import BookingFormLoader from './BookingFormLoader';
import styles from './BookingForm.module.scss';

const mapStateToProps = (state) => ({
  activeStep: getActiveStep(state),
  finishedStep: getFinishedStep(state),
  isLoaderVisible: getIsLoaderVisible(state),
});

const mapDispatchToProps = {};

class BookingForm extends Component {
  /*
    renders different components according to active step
  */
  renderActiveStep() {
    const { activeStep } = this.props;

    switch (activeStep) {
      case 1:
        return <Components.DateStep />;
      case 2:
        return <Components.Accomodations />;
      case 3:
        return <Components.Reservations />;
      case 4:
        return <Components.Checkout />;
      default:
        return <div>Sorry, there's currently no active step</div>; // eslint-disable-line react/no-unescaped-entities
    }
  }

  render() {
    const { activeStep, finishedStep, isLoaderVisible } = this.props;

    return (
      <>
        <div className={styles['booking-form']}>
          <BookingSession />
          {!!finishedStep && (
            // hide if finished step isn't set
            <Components.Steps />
          )}
          <BookingFormTransitions activeStep={activeStep}>{this.renderActiveStep()}</BookingFormTransitions>
          <BookingFormModal />
        </div>
        <BookingFormLoader isLoaderVisible={isLoaderVisible} />
      </>
    );
  }
}

BookingForm.propTypes = {
  activeStep: number,
  finishedStep: number,
  isLoaderVisible: bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingForm);
