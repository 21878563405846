const numberFormat = Intl.NumberFormat().format;
const round = (num) => Math.round(num * 100) / 100;

/**
 * Calculates passed value and percentage
 * @param {string|number} value
 * @param {string|number} percentage
 */
const getPercentageOf = function (value, percentage) {
  return (value * percentage) / 100;
};

/**
 * Formats value to $XXX.XX
 * @param {string|number} value - value to format
 */
export const getFormattedPrice = function (value) {
  return `$${Number(value).toFixed(2)}`;
};

/**
 * Formats value to X,XXX.XX
 * @param {string|number} value - value to format
 * @returns {string}
 */
export const getFormattedNumber = function (value) {
  const _value = +value;

  return numberFormat(round(_value));
};

/**
 * NOTE.
 * Taxes & Fees (or Taxes & Fees Total) = Taxes Total + Fees Total
 * is taken from FetchWebFolioBookingsRequest for room item = FolioSurcharges.
 *
 * Grand Total (or Total Charged = TOTALCHARGED) = Sub-Total + Taxes & Fees Total
 * is taken from payment server response.
 */
/**
 * Calculates prices (addonsTotal, taxes, fees)
 * @param {object} param0
 * @param {object} param0.room
 * @param {array} param0.addons
 */

export default function ({ room }) {
  if (!room) return {};
  const { priceSubtotal } = room;
  const getRoomTotalPercentage = getPercentageOf.bind(null, priceSubtotal);

  const CTT = getRoomTotalPercentage(12);
  // Tourism Assessment Tax = Room Total x 0.195%
  const TAT = getRoomTotalPercentage(0.195);
  // Taxes (Taxes Total) = California Transient Tax + Tourism Assessment Tax
  const TT = CTT + TAT;
  // Sustainability Fee = Room Total x 6%
  const SF = getRoomTotalPercentage(6);
  // Fees (Fees Total) = Sustainability Fee
  const FT = SF;

  return { CTT, TAT, TT, SF, FT };
}
