import { createSelector } from 'reselect';

import addonsData from '../../components/AddonsList/experiences.yaml';
import { createInitialStatePart, setReducerState } from '../../utils/helpers';

const STATE_KEY = 'session';

const GET_SESSION = `${STATE_KEY}/GET_SESSION`;
const GET_SESSION_SUCCESS = `${STATE_KEY}/GET_SESSION_SUCCESS`;
const GET_SESSION_FAIL = `${STATE_KEY}/GET_SESSION_FAIL`;

const UPDATE_SESSION = `${STATE_KEY}/UPDATE_SESSION`;
const UPDATE_SESSION_SUCCESS = `${STATE_KEY}/UPDATE_SESSION_SUCCESS`;
const UPDATE_SESSION_FAIL = `${STATE_KEY}/UPDATE_SESSION_FAIL`;

const DELETE_SESSION = `${STATE_KEY}/DELETE_SESSION`;
const DELETE_SESSION_SUCCESS = `${STATE_KEY}/DELETE_SESSION_SUCCESS`;
const DELETE_SESSION_FAIL = `${STATE_KEY}/DELETE_SESSION_FAIL`;

const initialState = {
  ...createInitialStatePart('request'),
  ...createInitialStatePart('update'),
  ...createInitialStatePart('delete'),
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_SESSION:
      return setReducerState('loading', 'request', { state });
    case GET_SESSION_SUCCESS:
      return setReducerState('loaded', 'request', { state, action });
    case GET_SESSION_FAIL:
      return setReducerState('error', 'request', { state, action });

    case UPDATE_SESSION:
      return setReducerState('loading', 'update', { state });
    case UPDATE_SESSION_SUCCESS:
      return setReducerState('loaded', 'request', { state, action });
    case UPDATE_SESSION_FAIL:
      return setReducerState('error', 'update', { state, action });

    case DELETE_SESSION:
      return setReducerState('loading', 'delete', { state });
    case DELETE_SESSION_SUCCESS:
      return { ...state, ...createInitialStatePart('delete') };
    // return setReducerState('loaded', 'delete', { state, action });
    case DELETE_SESSION_FAIL:
      return setReducerState('error', 'delete', { state, action });

    default:
      return state;
  }
}

export const getSession = () => ({
  types: [GET_SESSION, GET_SESSION_SUCCESS, GET_SESSION_FAIL],
  promise: (client) => client.get('/api/getSession'),
});

export const updateSession = (data = {}) => ({
  types: [UPDATE_SESSION, UPDATE_SESSION_SUCCESS, UPDATE_SESSION_FAIL],
  promise: (client) => client.post('/api/updateSession', { data }),
});

export const deleteSession = () => ({
  types: [DELETE_SESSION, DELETE_SESSION_SUCCESS, DELETE_SESSION_FAIL],
  promise: (client) => client.delete('/api/deleteSession'),
});

// getters
const getState = (state) => state[STATE_KEY];
const getRequestState = (state) => getState(state).request;
const getUpdateState = (state) => getState(state).update;
const getDeleteState = (state) => getState(state).delete;

// getting session
export const getUserSessionLoading = createSelector(
  getRequestState,
  (data) => data.requestLoading && !data.requestLoaded
);

export const getUserSession = createSelector(getRequestState, (data) => data);

export const getUserSessionArrivalDate = createSelector(getRequestState, (data) => data?.arrivalDate || null);

export const getUserSessionDepartureDate = createSelector(getRequestState, (data) => data?.departureDate || null);

export const getUserSessionRoom = createSelector(getRequestState, (data) => data?.room || null);

export const getUserSessionAddons = createSelector(getRequestState, (data) => data?.addons || []);

export const getUserSessionErrors = createSelector(getRequestState, (data) => data?.errors || {});

export const getUserSessionAddonsWithDetails = createSelector(getUserSessionAddons, (addons) => {
  const result = [];
  addons?.forEach((c) => {
    addonsData?.experiences?.forEach((group) => {
      const find = group?.experience?.find((addon) => addon?.id === c);
      if (find) {
        result.push(find);
      }
    });
  });
  return result;
});

export const getUserSessionUser = createSelector(getRequestState, (data) => data?.user || {});

export const getUserSessionError = createSelector(getRequestState, (data) => data.requestError);

// creating session
export const updateUserSessionLoading = createSelector(
  getUpdateState,
  (data) => data.updateLoading && !data.updateLoaded
);

export const updateUserSession = createSelector(getUpdateState, (data) => data.update);

export const updateUserSessionError = createSelector(getUpdateState, (data) => data.updateError);

// deleting session
export const deleteUserSessionLoading = createSelector(
  getDeleteState,
  (data) => data.deleteLoading && !data.deleteLoaded
);

export const deleteUserSession = createSelector(getDeleteState, (data) => data.delete);

export const deleteUserSessionError = createSelector(getDeleteState, (data) => data.deleteError);
