import memoize from 'lru-memoize';
import { createValidator, required, cardNumber, expirationDate } from '../../utils/validation';

const cardValidator = createValidator({
  number: [required, cardNumber],
  name: [required],
  expiry: [required, expirationDate],
  address: [required],
  zip: [required],
});

const validate = createValidator({
  card: cardValidator,
});

export default memoize(10)(validate);
