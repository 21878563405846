/*
  Accommodations substep.
  Loads room types when component mounts.
*/
import React, { PureComponent } from 'react';
import { bool, array, object, func } from 'prop-types';
import { connect } from 'react-redux';
import to from 'await-to-js';
import { setStep } from '../../redux/modules/bookingForm';
import {
  loadAccommodations,
  clearAccommodations,
  getAccommodationsLoaded,
  getAccommodations,
} from '../../redux/modules/bookingFormData';
import { updateSession, getUserSession } from '../../redux/modules/session';
import { getStartDate, getEndDate } from '../../redux/modules/rangeController';
import { fetchRoom, getRoom, getIsBookingRoom, updateGuestCount } from '../../redux/modules/bookings';
import { addNotificationQueue } from '../../redux/modules/notifications';
import { bookingRoomNotification } from '../../utils/notificationsFormat';
import { getResultErrorResponse, scrollToElement, getIn } from '../../utils/helpers';

import AccommodationItem from './AccommodationItem';
import AccommodationsListLoader from './AccommodationsListLoader';

import roomsData from './accommodations.yaml';
import styles from './AccommodationsList.module.scss';

import { testMode } from '../../../config/config';

const mapStateToProps = (state) => ({
  loaded: getAccommodationsLoaded(state),
  list: getAccommodations(state),
  sessionData: getUserSession(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  room: getRoom(state),
  isBookingRoom: getIsBookingRoom(state),
});

const mapDispatchToProps = {
  loadAccommodations,
  clearAccommodations,
  fetchRoom,
  addNotificationQueue,
  setStep,
  updateSession,
  updateGuestCount,
};

class AccommodationsList extends PureComponent {
  constructor(props) {
    super(props);
    const { sessionData } = this.props;
    this.state = {
      expandedData: {},
      guestCount: {
        adultCount: sessionData?.guestCount?.adultCount ? sessionData.guestCount.adultCount : '1',
        childCount: sessionData?.guestCount?.childCount ? sessionData.guestCount.childCount : '0',
      },
    };
    this._accomodationsInterval = null;
    this._itemIdKey = testMode === 'true' ? 'test_id' : 'id';
    // this._itemIdKey = 'id';
  }

  componentDidMount() {
    this.loadAccommodations();
    scrollToElement('#js-steps-wrapper', 60);

    /*
      Sets interval to periodically sync
      to accurately reflect the ResortSuite database.
    */
    clearInterval(this._accomodationsInterval);
    this._accomodationsInterval = setInterval(() => {
      this.loadAccommodations();
    }, 18e4);
  }

  componentDidUpdate(prevProps) {
    const { sessionData: prevSessionData } = prevProps;
    const { sessionData } = this.props;

    /**
     * triggers loadAccommodations after session has been loaded
     * for the case when user enters this page without loaded sessionData
     */
    if (!prevSessionData && sessionData) {
      this.loadAccommodations();
    }
  }

  componentWillUnmount() {
    const { clearAccommodations } = this.props; // eslint-disable-line no-shadow

    clearAccommodations();
    clearInterval(this._accomodationsInterval);
  }

  /**
   * One function to handle expandable data (description and details).
   * Only one room card can have Read More or Room Details open at a time.
   * Checks for previously expanded data.
   * @param {string} typeId - id of the clicked room item
   * @param {string} expandName - name of the expanded/collapsed data
   */
  handleExpand = (nextTypeId, expandName) => () => {
    const { expandedData } = this.state;
    const { typeId } = expandedData;
    const curExpandId = expandedData[expandName];

    this.setState({
      expandedData: {
        // if typeId and nextTypeId are the same keep previous data
        // else pass empty object and all previous expanded data will be closed
        ...(typeId === nextTypeId ? expandedData : {}),
        [expandName]: curExpandId === nextTypeId ? null : nextTypeId,
        typeId: nextTypeId,
      },
    });
  };

  handleGuestCountChange = async (e) => {
    if (e) {
      e.preventDefault();
    }
    await this.props.updateSession({
      guestCount: this.state.guestCount,
    });
    this.props.updateGuestCount(this.state.guestCount);
  };

  async handleAddRoom(roomData) {
    const { startDate, endDate, addNotificationQueue } = this.props; // eslint-disable-line

    await this.props.updateSession({
      room: roomData.typeId,
      guestCount: this.state.guestCount,
    });

    const [err, res = {}] = await to(
      this.props.fetchRoom({
        arrivalDate: startDate.format('YYYY-MM-DD'),
        departureDate: endDate.format('YYYY-MM-DD'),
        room: roomData.typeId,
      })
    );

    const _err = err || getResultErrorResponse(res.data, 'AvailabilityResponse');
    const bookingNotification = bookingRoomNotification(roomData.title, _err);

    addNotificationQueue(bookingNotification, {
      toastId: 'bookingRoom',
      type: _err ? 'warning' : 'default',
      autoClose: 3000,
    });

    if (!_err) {
      this.props.setStep(null, 2);
    }
  }

  handleBookRoom = async (roomData) => {
    this.handleAddRoom(roomData, this.state.guestCount);
  };

  handleSkip = () => {
    const { setStep } = this.props; // eslint-disable-line no-shadow

    setStep(2, 2);
  };

  /*
    loads accommodations data when the component mounts
  */
  async loadAccommodations() {
    const { startDate, endDate } = this.props;

    if (startDate && endDate) {
      const payload = {
        arrivalDate: startDate.format('YYYY-MM-DD'),
        departureDate: endDate.format('YYYY-MM-DD'),
        errors: {},
      };
      await this.props.updateSession(payload);
      this.props.loadAccommodations(payload);
    }
  }

  renderContinue() {
    const { room } = this.props;

    if (!room) return null;

    return (
      <button className="booking-form__btn-continue" type="button" onClick={this.handleSkip}>
        Don&apos;t change, please continue to Add-ons
      </button>
    );
  }

  /*
    renders items of the accommodations list
  */
  renderItems() {
    const { expandedData } = this.state;
    const { loaded, list, isBookingRoom, sessionData } = this.props;

    if (!loaded) {
      return (
        <div className={styles['accom-list__loader']}>
          <AccommodationsListLoader />
        </div>
      );
    }

    const roomRate = getIn(list, ['0', 'hc:RoomRates', '0', 'hc:RoomRate']);

    return roomRate.map((c) => {
      const avgRate = getIn(c, ['hc:Rates', '0', 'hc:Rate', '0', 'hc:Base', '0', '_']);
      const typeId = getIn(c, ['$', 'roomTypeCode']);
      const curData = roomsData.rooms.find((room) => room[this._itemIdKey] === typeId);

      if (!curData) {
        console.warn('missing data', typeId);
        return null;
      }
      if (curData.max_occupancy.adults_only && parseInt(sessionData.guestCount.childCount, 10) === 0) {
        if (curData.max_occupancy.adults_only < parseInt(sessionData.guestCount.adultCount, 10)) {
          return null;
        }
      } else if (
        curData.max_occupancy.adults < parseInt(sessionData.guestCount.adultCount, 10) ||
        curData.max_occupancy.children < parseInt(sessionData.guestCount.childCount, 10)
      ) {
        return null;
      }

      return (
        <li key={typeId} className={styles['accom-list__item']}>
          <AccommodationItem
            typeId={typeId}
            title={curData.title}
            avgRate={avgRate}
            description={curData.description}
            maxOccupancy={curData.max_occupancy}
            featuresList={curData.has_features}
            amenitiesList={curData.has_amenities}
            photo={curData.photo}
            expandedData={expandedData}
            handleExpand={this.handleExpand}
            handleBookRoom={this.handleBookRoom}
            isBookingRoom={isBookingRoom}
          />
        </li>
      );
    });
  }

  render() {
    const { sessionData } = this.props;
    return (
      <div className={styles['accom-list']}>
        <div className="booking-form__step-top">
          <div className="booking-form__step-header">
            <div className="booking-form__left booking-form__guestCount-container">
              <h2 className="booking-form__ttl">Enter number of guests:</h2>
              <form onSubmit={this.handleGuestCountChange}>
                <div className="booking-form__guestCount-inner-container">
                  <div className="booking-form__guestCount__ttl">Adults:</div>
                  <div className="adult-input booking-form__guestCount__input-container">
                    <button
                      className="booking-form__guestCount__input-button"
                      type="button"
                      onClick={() =>
                        this.setState((prevState) => ({
                          guestCount: {
                            ...prevState.guestCount,
                            adultCount: Math.max(1, parseInt(prevState.guestCount.adultCount, 10) - 1).toString(),
                          },
                        }))
                      }
                    >
                      -
                    </button>
                    <input
                      id="adult-count"
                      className="booking-form__guestCount__input"
                      type="number"
                      value={this.state.guestCount.adultCount}
                      onChange={(e) =>
                        this.setState((prevState) => ({
                          guestCount: {
                            ...prevState.guestCount,
                            adultCount: e.target.value,
                          },
                        }))
                      }
                      min="1"
                      max="7"
                    />
                    <button
                      className="booking-form__guestCount__input-button"
                      type="button"
                      onClick={() =>
                        this.setState((prevState) => ({
                          guestCount: {
                            ...prevState.guestCount,
                            adultCount: Math.min(7, parseInt(prevState.guestCount.adultCount, 10) + 1).toString(),
                          },
                        }))
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="booking-form__guestCount-inner-container">
                  <div className="booking-form__guestCount__ttl">Children:</div>
                  <div className="child-input booking-form__guestCount__input-container">
                    <button
                      className="booking-form__guestCount__input-button"
                      type="button"
                      onClick={() =>
                        this.setState((prevState) => ({
                          guestCount: {
                            ...prevState.guestCount,
                            childCount: Math.max(0, parseInt(prevState.guestCount.childCount, 10) - 1).toString(),
                          },
                        }))
                      }
                    >
                      -
                    </button>
                    <input
                      id="child-count"
                      className="booking-form__guestCount__input"
                      type="number"
                      value={this.state.guestCount.childCount}
                      onChange={(e) =>
                        this.setState((prevState) => ({
                          guestCount: {
                            ...prevState.guestCount,
                            childCount: e.target.value,
                          },
                        }))
                      }
                      min="0"
                      max="4"
                    />
                    <button
                      className="booking-form__guestCount__input-button"
                      type="button"
                      onClick={() =>
                        this.setState((prevState) => ({
                          guestCount: {
                            ...prevState.guestCount,
                            childCount: Math.min(4, parseInt(prevState.guestCount.childCount, 10) + 1).toString(),
                          },
                        }))
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
                {(sessionData?.guestCount?.adultCount !== this.state.guestCount.adultCount ||
                  sessionData?.guestCount?.childCount !== this.state.guestCount.childCount) && (
                  <button type="submit" className="booking-form__guestCount__confirmButton">
                    Confirm
                  </button>
                )}
              </form>
            </div>
            {sessionData?.guestCount?.adultCount && (
              <div className="booking-form__left">
                <h2 className="booking-form__ttl">Select your room</h2>
              </div>
            )}
          </div>
          {this.renderContinue()}
        </div>
        {sessionData?.guestCount?.adultCount && <ul className={styles['accom-list__list']}>{this.renderItems()}</ul>}
      </div>
    );
  }
}

AccommodationsList.propTypes = {
  loaded: bool,
  list: array,
  loadAccommodations: func,
  clearAccommodations: func,
  sessionData: object,
  fetchRoom: func,
  startDate: object,
  endDate: object,
  room: object,
  isBookingRoom: bool,
  addNotificationQueue: func,
  setStep: func,
  updateSession: func,
  updateGuestCount: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccommodationsList);
