/*
  component that stores common methods and logic
  for multiple headers (full and minified)
*/
import React, { PureComponent, Fragment } from 'react';
import { oneOfType, string, object, func, array, number } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { setModalParams } from '../../redux/modules/modals';
import { getBookings } from '../../redux/modules/bookings';
import { getStartDate, getEndDate } from '../../redux/modules/rangeController';
import { setStep, getActiveStep } from '../../redux/modules/bookingForm';
import { getUserSessionAddonsWithDetails } from '../../redux/modules/session';
import { getAddonsPrices } from '../../redux/modules/bookingFormData';

import * as Components from './index';
import styles from './HeadersWrapper.module.scss';

const TOP_OFFSET = 30;

const mapStateToProps = (state) => ({
  activeStep: getActiveStep(state),
  addons: getUserSessionAddonsWithDetails(state),
  bookings: getBookings(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  addonsPrices: getAddonsPrices(state),
});

const mapDispatchToProps = {
  setModalParams,
  setStep,
};

class HeadersWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMinified: false,
    };
    this._header = null;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    this._header = document.getElementById('js-main-header');
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  /*
    fires when user scrolls the content
    and checks scroll position
  */
  handleScroll = () => {
    const { isMinified } = this.state;
    const offset = window.pageYOffset;
    const headerHeight = this._header.offsetHeight;
    const triggerPoint = headerHeight + TOP_OFFSET;

    if (offset >= triggerPoint && !isMinified) {
      this.setState({ isMinified: true });
    } else if (offset < triggerPoint && isMinified) {
      this.setState({ isMinified: false });
    }
  };

  /*
    triggers when the users clicks on the "new search" button
  */
  handleNewSearch = () => {
    this.props.setModalParams({
      bookingFormModalData: { type: 'new search' },
    });
  };

  /*
    renders date blocks
    when dates are selected (if selected)
  */
  renderDates() {
    const { startDate, endDate } = this.props;
    const renderDateItem = (date) => (
      <div className="header__date">
        <em className="header__date-month">{date.format('MMM')}</em>
        <strong className="header__date-day">{date.format('DD')}</strong>
      </div>
    );

    return (
      <>
        {startDate && (
          <div className="header__item header__item--start-d">
            <div className="header__box">
              <div className="header__item-name">arrive</div>
              {renderDateItem(startDate)}
            </div>
          </div>
        )}
        {endDate && (
          <div className="header__item header__item--end-d">
            <div className="header__box">
              <div className="header__item-name">depart</div>
              {renderDateItem(endDate)}
            </div>
          </div>
        )}
      </>
    );
  }

  /*
    renders new search
    to be able reset selected items
  */
  renderNewSearch() {
    return (
      <div className="header__reset">
        <div className="header__box">
          <button className="header__btn-reset" type="button" onClick={this.handleNewSearch}>
            New Search
          </button>
        </div>
      </div>
    );
  }

  /*
    renders selected content blocks
    if not selected renders block with text
  */
  renderContent = () => {
    const { startDate, endDate, bookings, setStep, addons, addonsPrices } = this.props; // eslint-disable-line no-shadow
    const hasNoDates = !startDate && !endDate;

    if (hasNoDates) {
      return <div className="header__empty">Select date from the calendar below.</div>;
    }

    return (
      <div className="header__content">
        {this.renderDates()}
        <Components.HeadersBookingItems
          startDate={startDate}
          endDate={endDate}
          bookings={bookings}
          setStep={setStep}
          addons={addons}
          addonsPrices={addonsPrices}
        />
        {!hasNoDates && this.renderNewSearch()}
      </div>
    );
  };

  render() {
    const { isMinified } = this.state;
    const { startDate, endDate, activeStep } = this.props;

    return (
      <div
        className={cx(styles['headers-wrapper'], {
          [styles['headers-wrapper--minified']]: isMinified,
          [styles['headers-wrapper--no-dates']]: !startDate || !endDate,
        })}
      >
        <div className={styles['headers-wrapper__full']}>
          <Components.Header />
          <Components.SubHeader {...this.props} renderContent={this.renderContent} />
        </div>
        <Components.MiniHeader
          {...this.props}
          isActive={isMinified}
          activeStep={activeStep}
          renderContent={this.renderContent}
        />
      </div>
    );
  }
}

HeadersWrapper.propTypes = {
  addons: array,
  startDate: object,
  endDate: object,
  notifications: array,
  bookings: object,
  setModalParams: func,
  setStep: func,
  setPromo: func,
  activeStep: number,
  addonsPrices: oneOfType([string, number]),
};

export default connect(mapStateToProps, mapDispatchToProps)(HeadersWrapper);
