/**
 * Renders add-on of the Reservations step.
 */
import React, { Component } from 'react';
import { oneOfType, string, number, object, func, bool } from 'prop-types';
import cx from 'classnames';

import styles from './Reservations.module.scss';

export default class ReservationRoom extends Component {
  /**
   * RENDER methods BELOW
   */
  renderVisual() {
    const { title, photo } = this.props;
    const folderPath = '/images/addons';

    return (
      <div className={styles['reservation-item__visual']}>
        <div className={styles['reservation-item__img-wrap']}>
          <img
            className={styles['reservation-item__img']}
            src={`${folderPath}/${photo.normal}`}
            srcSet={`${folderPath}/${photo.normal} 1x,
              ${folderPath}/${photo.double} 2x`}
            alt={title}
          />
        </div>
      </div>
    );
  }

  renderContent() {
    const { title, price } = this.props;

    return (
      <div className={styles['reservation-item__info']}>
        <h3 className={styles['reservation-item__ttl']}>{title}</h3>
        <div className={styles['reservation-item__descr']}>Add-on</div>
        <div className={styles['reservation-item__price']}>
          <div className={styles['reservation-item__price-name']}>Add-on service (USD)</div>
          <div className={styles['reservation-item__price-value']}>
            <strong>Starting at </strong>
            <strong>${price}</strong>
          </div>
          <div className={styles['reservation-item__summ']}>
            Our personal concierge will reach out to you with booking details and payment information.
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { handleRemoveAddon, removeDisabled } = this.props;

    return (
      <div className={cx(styles['reservation-item'], styles['reservation-item--addon'])}>
        {this.renderVisual()}
        <div className={styles['reservation-item__content-box']}>{this.renderContent()}</div>
        <button
          className={styles['reservation-item__btn-remove']}
          type="button"
          disabled={removeDisabled}
          onClick={handleRemoveAddon}
        >
          X
        </button>
      </div>
    );
  }
}

ReservationRoom.propTypes = {
  title: string,
  photo: object,
  price: oneOfType([string, number]),
  removeDisabled: bool,
  handleRemoveAddon: func,
};
