import React, { PureComponent } from 'react';
import { func, object, string } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import memoizeOne from 'memoize-one';
import { DateRangePickerController } from '../../..';
import { datesNotification, datesBlocked } from '../../../../utils/notificationsFormat';
import { setStep } from '../../../../redux/modules/bookingForm';
import { loadAvailableDates, getAvailableDates } from '../../../../redux/modules/bookingFormData';
import {
  setDates,
  setFocusedInput,
  getStartDate,
  getEndDate,
  getFocusedInput,
} from '../../../../redux/modules/rangeController';
import { addNotificationQueue } from '../../../../redux/modules/notifications';
import { setModalParams } from '../../../../redux/modules/modals';

import styles from '../../BookingForm.module.scss';

const getMemoizedProps = memoizeOne((obj) => obj);
const initVisibleMonthCreator = () => moment();

const mapStateToProps = (state) => ({
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  focusedInput: getFocusedInput(state),
  availableDates: getAvailableDates(state),
});

const mapDispatchToProps = {
  loadAvailableDates,
  setDates,
  setFocusedInput,
  addNotificationQueue,
  setStep,
  setModalParams,
};

class DateStep extends PureComponent {
  componentWillUnmount() {
    const { startDate, endDate, setDates, setFocusedInput } = this.props; // eslint-disable-line no-shadow

    if ((startDate && !endDate) || (!startDate && endDate)) {
      setDates(null, null);
    }

    setFocusedInput(null);
  }

  /**
   * triggers when user selects any date
   * @param {object} startDate - moment object start date
   * @param {object} endDate - moment object end date
   */
  handleDatesChange = (startDate, endDate) => {
    const { setStep, addNotificationQueue } = this.props; // eslint-disable-line no-shadow
    const message = datesNotification(startDate, endDate);
    const dateModifier = startDate && endDate && `${startDate.format('Y-M-D')}_${endDate.format('Y-M-D')}`;

    startDate && endDate && setStep(2, 1, 1); // eslint-disable-line

    // the built-in notifications logic assumes notification type id
    // see notifications reducer (redux/modules/notifications) for further reading
    setTimeout(() => {
      addNotificationQueue(message, {
        toastId: !endDate ? 'range_startDate' : `range_startDate_${dateModifier}`,
      });
    }, 4);
  };

  /*
    fires when user picks some range with blocked days
    doesn't take arguments
  */
  handleDatesChangeBlocked = (data) => {
    const message = datesBlocked(data);

    if (data.type === 'required nights') {
      this.props.setModalParams({
        bookingFormModalData: {
          type: 'alert',
          data: {
            content: message,
          },
        },
      });
      return;
    }

    this.props.addNotificationQueue(message, { toastId: 'range_blocked', type: 'warning' });
  };

  /**
   * callback that triggers after DateRangePickerController is initialized
   * @param {object} datesRange - dates range with today's date and end of the last initialized month
   * @param {string} datesRange.startDate - today's date
   * @param {string} datesRange.endDate - end of the last initialized month
   * @param {object} datesRange.momentObject - moment object with last added months.
   * @param {string} datesRange.dateFormat - date format pattern
   */
  handlePickerMount = (datesRange) => {
    const { loadAvailableDates } = this.props; // eslint-disable-line no-shadow
    const preloadRange = this.getPreloadRange();

    const onError = () => {
      this.handleServiceOffline();
    };

    loadAvailableDates(datesRange).catch(onError);
    loadAvailableDates(preloadRange).catch(onError);
  };

  handleServiceOffline() {
    this.props.setModalParams({
      bookingFormModalData: { type: 'service error', disableClickClose: true, disableClose: true },
    });
  }

  /**
   * method to get preload months range with start and end of the month due to passed arguments
   * @param {object} [momentObject] - moment object
   * @param {number} [startMonthNum] - number of the mont(s) to add to start range from
   * @param {number} [endMonthNum] - number of the mont(s) to add to finish range
   * @param {string} [dateFormat] - date format pattern
   * @example momentObject is Sep 3. startMonthNum = 2. endMonthNum = 3; result: startDate - Nov 1. endDate - Dec 31
   */
  getPreloadRange = (momentObject, startMonthNum = 2, endMonthNum = 3, dateFormat = 'YYYY-MM-DD') => {
    const _momentObject = momentObject || moment();
    const deltaEndMonthNum = endMonthNum - startMonthNum;

    return {
      startDate: _momentObject.add(startMonthNum, 'months').startOf('month').format(dateFormat),
      endDate: _momentObject.add(deltaEndMonthNum, 'months').endOf('month').format(dateFormat),
    };
  };

  /**
   * callback that triggers when new months are added
   * @param {object} datesRange - dates range with new months
   * @param {string} datesRange.startDate - start date of the first added month
   * @param {string} datesRange.endDate - end date of the last added month
   * @param {object} datesRange.momentObject - moment object with last added months.
   *   To be able manipulate with dates without requiring moment
   * @param {string} datesRange.dateFormat - date format pattern
   */
  handleMonthAdded = (datesRange) => {
    const { momentObject } = datesRange;
    const preloadRange = this.getPreloadRange(momentObject, 1, 2);

    const onError = () => {
      this.handleServiceOffline();
    };

    this.props.loadAvailableDates(preloadRange).catch(onError);
  };

  render() {
    const {
      startDate,
      endDate,
      focusedInput,
      setDates, // eslint-disable-line no-shadow
      setFocusedInput, // eslint-disable-line no-shadow
      availableDates,
    } = this.props;

    return (
      <div className={styles['booking-form__date-step']}>
        <DateRangePickerController
          pickerProps={getMemoizedProps({
            startDate,
            endDate,
            focusedInput,
            initialVisibleMonth: initVisibleMonthCreator,
            orientation: 'verticalScrollable',
          })}
          availableDates={availableDates}
          setDates={setDates}
          setFocusedInput={setFocusedInput}
          onMount={this.handlePickerMount}
          onDatesChange={this.handleDatesChange}
          onDatesChangeBlocked={this.handleDatesChangeBlocked}
          onMonthAdded={this.handleMonthAdded}
        />
      </div>
    );
  }
}

DateStep.propTypes = {
  startDate: object,
  endDate: object,
  focusedInput: string,
  setDates: func,
  setFocusedInput: func,
  loadAvailableDates: func,
  addNotificationQueue: func,
  availableDates: object,
  setStep: func,
  setModalParams: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateStep);
