/*
  Loader for AccommodationsList.
  Shows when room types are loading.
*/
import React from 'react';
import { number, string } from 'prop-types';
import ContentLoader from 'react-content-loader';

function AccommodationsListLoader({ width, height, title }) {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      title={title}
      backgroundColor="rgb(0,0,0)"
      foregroundColor="rgb(0,0,0)"
      backgroundOpacity={0.06}
      foregroundOpacity={0.12}
    >
      <rect x="0.49" width="540" height="550" />
      <rect x="617.23" y="42.4" width="760" height="26" />
      <rect x="617.32" y="91.93" width="525" height="16" />
      <rect x="617.96" y="141.41" width="890" height="16" />
      <rect x="617.96" y="175.52" width="950" height="16" />
      <rect x="617.23" y="322.06" width="72" height="72" />
      <rect x="755.45" y="322.06" width="72" height="72" />
      <rect x="883.45" y="322.06" width="72" height="72" />
      <rect x="1016.45" y="322.06" width="72" height="72" />
      <rect x="1377.23" y="322.06" width="150" height="16" />
      <rect x="1377.23" y="359.06" width="150" height="16" />
      <rect x="617.23" y="428.69" width="72" height="16" />
      <rect x="755.45" y="428.69" width="72" height="16" />
      <rect x="883.45" y="428.69" width="72" height="16" />
      <rect x="1016.45" y="428.69" width="72" height="16" />
      <rect x="617.23" y="498.63" width="138.22" height="16" />
      <rect x="617.23" y="246.54" width="72" height="16" />
      <rect x="1377.23" y="427.69" width="209.58" height="87.94" />
    </ContentLoader>
  );
}

AccommodationsListLoader.defaultProps = {
  width: 1640,
  height: 550,
  title: 'Loading Rooms...',
};

AccommodationsListLoader.propTypes = {
  width: number,
  height: number,
  title: string,
};

export default AccommodationsListLoader;
