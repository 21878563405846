/**
 * Fires different gtm actions.
 */

export function gtmPurchase(room = {}, addons = [], paymentData = {}) {
  const transactionRoom = {
    sku: room.id,
    name: room.title,
    price: room.priceSubtotal,
    quantity: 1,
  };
  const transactionAddons = addons.map((c) => ({
    sku: c.id,
    name: c.title,
    price: c.price,
    quantity: 1,
  }));
  window.dataLayer.push({
    event: 'purchase',
    transactionId: +paymentData.Invoice || '',
    transactionTotal: +paymentData.Total || '',
    transactionTax: +paymentData.Taxes || '',
    transactionProducts: [transactionRoom, ...transactionAddons],
  });
}
