/*
  Contols booking session
  (expired/expires/service error).
*/

import { Component } from 'react';
import { func, array } from 'prop-types';
import { connect } from 'react-redux';
import to from 'await-to-js';
import moment from 'moment';
import { setBookingFormParams, setRoute, clearRoute } from '../../redux/modules/bookingForm';
import { setDates } from '../../redux/modules/rangeController';
import {
  addNotificationQueue,
  clearNotifications,
  updateNotificationQueue,
  getNotificationQueue,
} from '../../redux/modules/notifications';
import { setModalParams } from '../../redux/modules/modals';
import { fetchRoom } from '../../redux/modules/bookings';
import { simplePluralize } from '../../utils/helpers';
import {
  getSession,
  deleteSession,
  getUserSessionArrivalDate,
  getUserSessionDepartureDate,
  getUserSessionRoom,
} from '../../redux/modules/session';
import { clearSessionStorage } from '../../utils/sessionActions';

const CHECK_INTERVAL = 1000 * 60; // 1 min
const WARN_MIN_LEFT = 5; // 5 min

let PREVIOUS_SESSION_ID;
let PREVIOUS_SESSION_TIME;

const storeRestoredStepOnce = function (restoredStep) {
  if (typeof localStorage === 'undefined') return;
  localStorage.setItem('restoredStep', JSON.stringify(restoredStep));
  setTimeout(() => {
    localStorage.removeItem('restoredStep');
  }, 4);
};

const mapStateToProps = (state) => ({
  notificationsQueue: getNotificationQueue(state),
  arrivalDate: getUserSessionArrivalDate(state),
  departureDate: getUserSessionDepartureDate(state),
  room: getUserSessionRoom(state),
});

const mapDispatchToProps = {
  setBookingFormParams,
  setDates,
  addNotificationQueue,
  clearNotifications,
  updateNotificationQueue,
  setModalParams,
  getSession,
  deleteSession,
  fetchRoom,
};

class BookingSession extends Component {
  constructor(props) {
    super(props);
    this._sessionInterval = null;
  }

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.clearSessionInterval();
  }

  async handleServiceOffline() {
    const modal = { type: 'service error', disableClickClose: true, disableClose: true };

    this.props.setModalParams({ bookingFormModalData: modal });
    this.clearSessionInterval();
  }

  /*
    Gets invoked when the session became inactive.
  */
  handleSessionExpired() {
    const { setModalParams, notificationsQueue, clearNotifications } = this.props; // eslint-disable-line no-shadow

    this.clearSessionInterval();

    // if there are some notifications in the queue
    // clear them all when the session ends
    if (notificationsQueue.length) {
      clearNotifications();
    }

    setModalParams({
      bookingFormModalData: {
        type: 'session expired',
        disableClickClose: true,
        disableClose: true,
      },
    });
  }

  /*
    Gets invoked when the session is ending.
    Checks if notification in the queue and updates it or creates new notification type if not.
    Prevents notification counts downs consecutively when the user returns to inactive tab.
  */
  handleSessionExpiring(remainingTime) {
    const { notificationsQueue, addNotificationQueue, updateNotificationQueue } = this.props; // eslint-disable-line no-shadow

    const toastId = 'session_expires';
    const timeleftMessage = `Your session will be closed automatically if you don’t take any action in ${remainingTime} ${simplePluralize(
      'minute',
      remainingTime
    )}.`;
    const isNotificationInQueue = !!notificationsQueue.find((c) => c.toastId === toastId);

    if (isNotificationInQueue) {
      updateNotificationQueue(toastId, timeleftMessage);
      return;
    }

    addNotificationQueue(timeleftMessage, {
      toastId,
      autoClose: 5000,
      closeOnClick: false,
      forceAdd: true,
    });
  }

  async handleBookings() {
    const { arrivalDate, departureDate, room } = this.props; // eslint-disable-line

    const onError = () => {
      this.handleServiceOffline();
    };

    if (arrivalDate && departureDate) {
      const reducerBookingForm = JSON.parse(localStorage.getItem('reducerBookingForm'));
      if (reducerBookingForm) {
        const { activeStep, steps } = reducerBookingForm;
        const restoredStep = steps[activeStep];
        storeRestoredStepOnce(restoredStep);
        setRoute(activeStep, steps);
        this.props.setDates(moment(arrivalDate), moment(departureDate));
        this.props.setBookingFormParams(reducerBookingForm);
      }

      if (room) {
        this.props
          .fetchRoom({
            arrivalDate,
            departureDate,
            room,
          })
          .catch(onError);
      }
    }

    this.showLoader(false);
  }

  /*
  sets interval to check remaining timeleft every CHECK_INTERVAL
  */
  setSessionInterval() {
    const tick = async () => {
      const [err, data] = await to(this.checkSession());
      if (err) {
        this.handleServiceOffline();
      } else if (data.isExpired) {
        this.handleSessionExpired();
      } else if (data.isExpiring) {
        this.handleSessionExpiring(data.leftSessionTime);
      }
    };

    if (!this._sessionInterval) {
      this._sessionInterval = setInterval(tick, CHECK_INTERVAL);
    }
  }

  clearSessionInterval() {
    clearInterval(this._sessionInterval);
    this._sessionInterval = null;
  }

  /*
    checks the remaining session time
    and returns session data (expired, expiring, leftSessionTime etc)
    returns reject if service is offline
  */
  async checkSession() {
    const { getSession, deleteSession } = this.props; // eslint-disable-line no-shadow
    const [err, res = {}] = await to(getSession());
    const currentSessionId = res.data.sessionId;
    const maxAge = res.data.maxAge; // eslint-disable-line
    const date = new Date();
    const currentTime = date.getTime();

    if (err) {
      return Promise.reject(Error(err));
    }
    const isExpired = typeof PREVIOUS_SESSION_ID !== 'undefined' && PREVIOUS_SESSION_ID !== currentSessionId;
    const leftSessionTime = Math.round((PREVIOUS_SESSION_TIME - currentTime) / 1000 / 60); // how much minutes left
    const isExpiring = leftSessionTime <= WARN_MIN_LEFT;

    if (isExpired) {
      PREVIOUS_SESSION_ID = undefined;
      PREVIOUS_SESSION_TIME = undefined;
      deleteSession();
      clearSessionStorage();
      clearRoute();
    } else {
      PREVIOUS_SESSION_ID = currentSessionId;
      if (typeof PREVIOUS_SESSION_TIME === 'undefined') {
        PREVIOUS_SESSION_TIME = currentTime + maxAge;
      }
    }

    return Promise.resolve({ ...res, isExpired, isExpiring, leftSessionTime });
  }

  /*
  Initializes session data.
  */
  async init() {
   const [err, data] = await to(this.checkSession()); // eslint-disable-line

    if (err) {
      this.showLoader(false);
      this.handleServiceOffline();
      return;
    }
    await this.handleBookings();

    this.showLoader(false);

    this.setSessionInterval();
  }

  showLoader(isLoaderVisible) {
    this.props.setBookingFormParams({ isLoaderVisible });
  }

  render() {
    return null;
  }
}

BookingSession.propTypes = {
  addNotificationQueue: func,
  fetchRoom: func.isRequired,
  clearNotifications: func,
  deleteSession: func,
  getSession: func,
  notificationsQueue: array,
  setBookingFormParams: func,
  setDates: func,
  setModalParams: func,
  updateNotificationQueue: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingSession);
