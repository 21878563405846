/* eslint-disable no-useless-escape */
import React from 'react';
import { numberOrdinalSuffix } from './helpers';

export const DEFAULT_ERR_MSG = 'Something went wrong. Please try to reload the page';

/**
 * returns notification message according to passed startDate and endDate
 * when the user selects valid dates range
 * @param {object} startDate - moment object
 * @param {object} [endDate] - moment object
 */
export function datesNotification(startDate, endDate) {
  const getDates = function () {
    const monthStart = startDate.format('MMMM');
    const dayStart = startDate.format('D');
    const monthEnd = endDate.format('MMMM');
    const dayEnd = endDate.format('D');

    if (monthStart === monthEnd) {
      return `${monthStart} ${numberOrdinalSuffix(dayStart)} to ${numberOrdinalSuffix(dayEnd)}`;
    }
    return `${monthStart} ${numberOrdinalSuffix(dayStart)} to ${monthEnd} ${numberOrdinalSuffix(dayEnd)}`;
  };

  if (startDate && !endDate) {
    return (
      <div>
        Great. <strong>Now select your departure date.</strong>
      </div>
    );
  }
  if (startDate && endDate) {
    return (
      <div>
        <div>
          Great. Your stay is from <strong>{`${getDates()}.`}</strong>
        </div>
        <div>
          <strong>Select your room.</strong>
        </div>
      </div>
    );
  }

  return '';
}

/*
  returns notification message when the user selects
  invalid dates range (with blocked days) or selected dates have promotion
*/
export function datesBlocked(data) {
  if (data.type === 'required nights') {
    const { selectedNights, requiredNights } = data;
    const missingRoomNights = requiredNights - selectedNights;
    const extendNightsText = missingRoomNights === 1 ? 'one more night' : `for ${missingRoomNights} nights`;

    return (
      <div>
        The date you have selected requires a <strong>{requiredNights}-night minimum stay.</strong>
        <br />
        Please extend your stay with us {extendNightsText}.
      </div>
    );
  }

  return (
    <div>
      <strong>{`You\'ve selected one or more unavailable dates.  Please try different dates.`}</strong>
    </div>
  );
}

/**
 * Returns formatted notification when the user books a room.
 * @param {string} roomType - room that has been boked
 * @param {boolean} [isError] - error that might be passed if there's an error
 */
export function bookingRoomNotification(roomType, isError) {
  if (isError) {
    return (
      <div>
        <span>Sorry, </span>
        <strong style={{ textTransform: 'capitalize' }}>{roomType}</strong>
        <span> is no longer available. Please select another room or different date.</span>
      </div>
    );
  }

  return (
    <div>
      You are booking <strong style={{ textTransform: 'capitalize' }}>{`${roomType}.`}</strong>
    </div>
  );
}
