import React, { Component, Fragment } from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { Confirm } from '../../../elements';
import { updateModal, getBookingFormModalData } from '../../../redux/modules/modals';
import { updateSession } from '../../../redux/modules/session';

const mapStateToProps = (state) => ({
  bookingFormModalData: getBookingFormModalData(state),
});

const mapDispatchToProps = {
  updateModal,
  updateSession,
};

class NewSearch extends Component {
  /**
   * Update the session and then resets all the data.
   */
  handleNewSearch = async () => {
    const { resetAllData, updateSession } = this.props; // eslint-disable-line

    updateSession({
      arrivalDate: null,
      departureDate: null,
      room: null,
      guestCount: {},
      addons: [],
      user: {},
      errors: {},
    });
    resetAllData();
  };

  render() {
    const { closeModal } = this.props;
    const description = 'Are you sure you want to reset the form? This action cannot be undone.';

    return (
      <div className="modal__note">
        <Confirm
          title={
            <>
              <span>important note</span>
              <span className="modal__icon-info" />
            </>
          }
          description={description}
          cancelText="Cancel"
          confirmText="Reset"
          onConfirm={this.handleNewSearch}
          onCancel={closeModal}
        />
      </div>
    );
  }
}

NewSearch.propTypes = {
  closeModal: func,
  resetAllData: func,
  updateSession: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSearch);
