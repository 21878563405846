/*
  Single accommodation item of the AccommodationsList.
*/
import React, { PureComponent } from 'react';
import { string, array, object, func, bool } from 'prop-types';
import { TruncateToggle, Toggler, DotsLoader } from '../../elements';

import styles from './AccommodationItem.module.scss';

const INIT_VISIBLE_FEATURES = 4;

class AccommodationItem extends PureComponent {
  /*
    gets invoked when the book button is clicked
  */
  handleBookClick = () => {
    const { typeId, title, handleBookRoom } = this.props;

    handleBookRoom({ typeId, title });
  };

  /*
    below are render methods
  */
  renderVisual() {
    const { title, photo } = this.props;
    const folderPath = '/images/room_types';

    return (
      <div className={styles['accom-item__visual']}>
        <div className={styles['accom-item__img-wrap']}>
          <img
            className={styles['accom-item__img']}
            src={`${folderPath}/${photo.normal}`}
            srcSet={`${folderPath}/${photo.normal} 1x,
              ${folderPath}/${photo.double} 2x`}
            alt={title}
          />
        </div>
      </div>
    );
  }

  renderOccupancy() {
    const { maxOccupancy: { adults, children, adults_only: adultsOnly } = {} } = this.props;
    if (children && adultsOnly) {
      const occupancyChildren = `and ${children} ${children === 1 ? 'Child' : 'Children'}`;

      const occupancyAdultsOnly = `or up to ${adultsOnly} ${adultsOnly === 1 ? 'Adult' : 'Adults'}`;

      return `Max Room Occupancy | ${adults} Adults ${occupancyChildren} ${occupancyAdultsOnly}`;
    }
    const occupancyChildren = children ? `and ${children} ${children === 1 ? 'Child' : 'Children'}` : '';

    return `Max Room Occupancy | ${adults} Adults ${occupancyChildren}`;
  }

  renderContent() {
    const { title, description, typeId, expandedData, handleExpand } = this.props;
    const isExpanded = typeId === expandedData.descriptionId;
    const onToggleClick = handleExpand(typeId, 'descriptionId');

    return (
      <div className={styles['accom-item__info']}>
        <h3 className={styles['accom-item__ttl']}>{title}</h3>
        <div className={styles['accom-item__occupancy']}>{this.renderOccupancy()}</div>
        <div className={styles['accom-item__descr']}>
          <TruncateToggle isExpanded={isExpanded} onToggleClick={onToggleClick}>
            {description}
          </TruncateToggle>
        </div>
      </div>
    );
  }

  renderFeatures() {
    const { featuresList, typeId, expandedData } = this.props;
    if (!featuresList.length) return null;
    const isDescriptionExpanded = typeId === expandedData.descriptionId;
    const _featuresList = !isDescriptionExpanded ? featuresList.slice(0, INIT_VISIBLE_FEATURES) : featuresList;

    return (
      <div className={styles['accom-item__features']}>
        {_featuresList.map((c) => {
          const { title, icon } = c;

          return (
            <div key={title} className={styles['accom-item__features-item']}>
              <div className={styles['accom-item__features-img']}>
                <img src={`/icons/rooms_features/${icon}`} alt={title} title={title} />
              </div>
              <div className={styles['accom-item__features-text']}>{title}</div>
            </div>
          );
        })}
      </div>
    );
  }

  renderAmenitiesInfo() {
    const { amenitiesList, typeId, expandedData, handleExpand } = this.props;
    const isExpanded = typeId === expandedData.detailsId;
    const onTriggerClick = handleExpand(typeId, 'detailsId');

    const details = amenitiesList.map((c) => {
      const { title, description } = c;

      return (
        <li key={title} className={styles['accom-item__details-item']}>
          {description}
        </li>
      );
    });

    return (
      <div className={styles['accom-item__details']}>
        <Toggler
          className={styles['accom-item__details-toggler']}
          isOpened={isExpanded}
          onTriggerClick={onTriggerClick}
          trigger={(isOpened) => (
            <button className="btn-show-more" type="button">
              <span className="btn-show-more__txt">room details</span>
              <span className="btn-show-more__icon">{!isOpened ? <span>+</span> : <span>&ndash;</span>}</span>
            </button>
          )}
        >
          <ul className={styles['accom-item__details-list']}>{details}</ul>
        </Toggler>
      </div>
    );
  }

  renderFooter() {
    const { avgRate, isBookingRoom } = this.props;

    return (
      <div className={styles['accom-item__footer']}>
        <div className={styles['accom-item__features-box']}>{this.renderFeatures()}</div>
        <div className={styles['accom-item__book-box']}>
          <div className={styles['accom-item__avg-rate']}>
            <span>average daily rate (USD)</span>
            <span className={styles['accom-item__avg-text-mobile']}>Avg/Night (USD)</span>
            <strong>${avgRate}</strong>
          </div>
          <button
            className={styles['accom-item__btn-book']}
            type="button"
            onClick={this.handleBookClick}
            disabled={isBookingRoom}
          >
            {!isBookingRoom ? 'book' : <DotsLoader />}
          </button>
        </div>
        <div className={styles['accom-item__details-box']}>{this.renderAmenitiesInfo()}</div>
      </div>
    );
  }

  render() {
    return (
      <div className={styles['accom-item']}>
        {this.renderVisual()}
        <div className={styles['accom-item__content-box']}>
          {this.renderContent()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }
}

AccommodationItem.propTypes = {
  typeId: string,
  title: string,
  description: string,
  avgRate: string,
  maxOccupancy: object,
  featuresList: array,
  amenitiesList: array,
  photo: object,
  expandedData: object,
  handleExpand: func,
  handleBookRoom: func,
  isBookingRoom: bool,
};

export default AccommodationItem;
