import React, { Component } from 'react';
import { object, func, number, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import {
  setStep,
  setBookingFormParams,
  getSteps,
  getActiveStep,
  getFinishedStep,
  getIsAnimatingProgress,
} from '../../../../redux/modules/bookingForm';
import { setModalParams } from '../../../../redux/modules/modals';

import styles from './Steps.module.scss';
import StepsAnimation from './StepsAnimation';

const getStepsArr = function (stepsObject) {
  return Object.entries(stepsObject).map((c) => {
    const [, step] = c;

    return step.name;
  });
};

const mapStateToProps = (state) => ({
  steps: getSteps(state),
  activeStep: getActiveStep(state),
  finishedStep: getFinishedStep(state),
  isAnimatingProgress: getIsAnimatingProgress(state),
});

const mapDispatchToProps = {
  setStep,
  setBookingFormParams,
  setModalParams,
};

class Steps extends Component {
  constructor(props) {
    super(props);
    this._availableSteps = getStepsArr(props.steps);
  }

  handleNewSearch() {
    this.props.setModalParams({
      bookingFormModalData: { type: 'new search' },
    });
  }

  /**
   * fires when the user clicks on the step button
   * and saves this step to the bookingForm reducer
   * @param {number} - step number that the user clicked on
   */
  handleChangeStep = (step) => {
    const { finishedStep } = this.props;
    const maxAvailableStep = finishedStep + 1;
    let subStep;

    // prevent click if step is not available
    // available step is finished step PLUS next active step
    if (step > maxAvailableStep) {
      return;
    }
    if (step === 1) {
      this.handleNewSearch();
      return;
    }

    // make default substeps for specific steps
    switch ( // eslint-disable-line
      step
    ) {
      case 2:
        subStep = 1;
        break;
    }

    this.props.setStep(step, subStep);
  };

  /*
    renders available steps
  */
  renderSteps() {
    const { steps, activeStep, finishedStep, isAnimatingProgress } = this.props;

    return (
      <div
        className={cx(styles.steps, {
          [styles['steps--disabled']]: isAnimatingProgress,
        })}
      >
        <StepsAnimation
          className={styles['steps__svg-animation']}
          steps={steps}
          activeStep={activeStep}
          finishedStep={finishedStep}
          setBookingFormParams={this.props.setBookingFormParams}
        />
        <div className={styles['steps__items-holder']}>
          {this._availableSteps.map((c, i) => {
            const stepIndex = i + 1;
            const maxAvailableStep = finishedStep + 1;
            const isActive = stepIndex === activeStep;
            const isDisabled = !isActive && stepIndex > maxAvailableStep;

            return (
              <div
                key={c}
                className={cx(styles.steps__item, {
                  [styles['steps__item--is-active']]: isActive,
                  [styles['steps__item--is-disabled']]: isDisabled,
                })}
              >
                <div className={styles['steps__item-box']} onClick={() => this.handleChangeStep(stepIndex)}>
                  <div className={styles['steps__item-num']} />
                  <div className={styles['steps__item-content']}>{c}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="js-steps-wrapper" className={styles['steps-wrapper']}>
        {this.renderSteps()}
      </div>
    );
  }
}

Steps.propTypes = {
  steps: object,
  setStep: func,
  setBookingFormParams: func,
  setModalParams: func,
  activeStep: number,
  finishedStep: number,
  isAnimatingProgress: bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
