import cardValidator from 'card-validator';

export function executionEnvironment() {
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

  return {
    canUseDOM,
    canUseWorkers: typeof Worker !== 'undefined',
    canUseEventListeners: canUseDOM && !!(window.addEventListener || window.attachEvent),
    canUseViewport: canUseDOM && !!window.screen,
  };
}

export function loadImages(arr) {
  if (!executionEnvironment().canUseDOM) return Promise.reject(Error('no server rendering for new Image'));

  const createImg = (path) => {
    const img = new Image(); // eslint-disable-line
    img.src = path;
    img.alt = 'img';

    return new Promise((res) => {
      if (img.naturalWidth) res(img);

      img.onload = () => res(img);
      img.onerror = () => res(img);
    });
  };

  return Promise.all(arr.map((c) => createImg(c)));
}

/* eslint-disable */
// https://learn.javascript.ru/task/debounce
export function debounce(f, ms) {
  let timer = null;

  return function (...args) {
    const onComplete = () => {
      f.apply(this, args);
      timer = null;
    };

    clearTimeout(timer);
    timer = setTimeout(onComplete, ms);
  };
}

// https://learn.javascript.ru/task/throttle
export function throttle(func, ms) {
  var isThrottled = false,
    savedArgs,
    savedThis;

  function wrapper() {
    if (isThrottled) {
      savedArgs = arguments;
      savedThis = this;
      return;
    }

    func.apply(this, arguments);

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false;
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  wrapper.immediateStop = function () {
    savedArgs = savedThis = null;
  };

  return wrapper;
}
/* eslint-enable */

// http://stackoverflow.com/a/30376660/3708754
export const getSvgPath = {
  /**
   * @param {node} el
   */
  line(el) {
    const x1 = el.getAttribute('x1');
    const x2 = el.getAttribute('x2');
    const y1 = el.getAttribute('y1');
    const y2 = el.getAttribute('y2');
    const lineLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)); // eslint-disable-line no-restricted-properties

    return lineLength;
  },
  /**
   * @param {node} el
   */
  circle(el) {
    const r = el.getAttribute('r');
    const circleLength = 2 * Math.PI * r;

    return circleLength;
  },
};

/**
 * @param {number} n
 */
export function numberOrdinalSuffix(n) {
  const j = n % 10;
  const k = n % 100;

  if (j === 1 && k !== 11) {
    return `${n}st`;
  }
  if (j === 2 && k !== 12) {
    return `${n}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${n}rd`;
  }

  return `${n}th`;
}

/**
 * Simplest pluralization function.
 * This doesn't support all english edge-cases, but it's suitable for most purposes.
 *
 * @param {string} noun - word for pluralization
 * @param {number|string} - number to determine whether a word should be pluralized
 * @param {string} suffix - pluralization suffix
 */
export function simplePluralize(noun, count, suffix = 's') {
  return count != 1 ? `${noun}${suffix}` : noun; // eslint-disable-line
}

export function capitalizeEachWord(str) {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function createInitialStatePart(name, initialData = {}) {
  return {
    [`${name}Loading`]: false,
    [`${name}Loaded`]: false,
    [name]: initialData,
    [`${name}Error`]: null,
  };
}

export function setReducerState(status, name, { state, action, data, error }) {
  switch (status) {
    case 'loading':
      return {
        ...state,
        [`${name}Loading`]: true,
      };
    case 'loaded':
      return {
        ...state,
        [`${name}Loading`]: false,
        [`${name}Loaded`]: true,
        [name]: data || action.result,
        [`${name}Error`]: null,
      };
    case 'error':
      return {
        ...state,
        [`${name}Loading`]: false,
        [`${name}Loaded`]: false,
        [`${name}Error`]: error || action.error,
      };
    default:
      return state;
  }
}

export function getIn(obj = {}, path, separator = '.') {
  const paths = path.constructor === Array ? path : path.split(separator);
  const pathsLength = paths.length;
  let resultData = obj;

  // loop is much faster than something like the reduce:
  // paths.reduce((p, c) => p && p[c], obj);

  for (let i = 0; i < pathsLength; i++) {
    const c = paths[i];
    const curData = resultData[c];
    if (!curData) return curData;

    resultData = curData;
  }

  return resultData;
}

/**
 * Returns error when the API returns 200 OK but the data has error
 * @param {object} data - data to get error from
 */

export function getResultError(data = {}) {
  const resultValue = getIn(data, 'Result.0.$.value');

  if (resultValue !== 'SUCCESS') {
    return getIn(data, 'Result.0.Text.0') || 'Something went wrong. Please try to reload the page.';
  }

  return null;
}

export function getResultErrorResponse(data = {}, response) {
  const resultValue = getIn(data, ['result', '0', response, '0', 'Result', '0', '$', 'resultStatusFlag']);

  if (resultValue !== 'SUCCESS') {
    return 'Request completed successfully';
  }

  return null;
}

/**
 * Returns card formatted card type according to passed number
 * @param {number|string} number - card number
 */
export function getCardType(number) {
  const { card } = cardValidator.number(number);
  const type = (card && card.type) || '';
  switch (type) {
    case 'mastercard':
      return 'MC';
    case 'visa':
      return 'VA';
    case 'american-express':
      return 'AX';
    case 'discover':
      return 'DS';
    default:
      return type.toUpperCase();
  }
}

/**
 * Checks if restored step is the same as mounted step
 * to prevent some actions (and calls to the API) to be invoked twice
 * @param {string} stepName - name of the step
 * @param {number} activeSubStep - number of active sub step
 */
export function checkSameAsRestoredStep(stepName, activeSubStep) {
  if (typeof localStorage === 'undefined') return false;
  const restoredStep = JSON.parse(localStorage.getItem('restoredStep'));

  if (!restoredStep) return false;

  return restoredStep.name === stepName && restoredStep.subSteps.active === activeSubStep;
}

/**
 * Scrolls to the passed node element (or css query).
 * NOTE: smooth Scroll behavior polyfill (smoothscroll-polyfill) is required
 * for cross browser support and is initialized in App.js
 *
 * @param {string|node} query - node element or a valid css query to scroll to
 * @param {number} margin - margin to add to the top offset
 */
export function scrollToElement(query, margin = 20) {
  const header = document.getElementById('js-mini-header');
  const element = query instanceof HTMLElement ? query : document.querySelector(query); // eslint-disable-line

  if (header && element) {
    const offset = header.offsetHeight + margin;
    const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
    const fromTop = elementTop - offset;

    window.scroll({ top: fromTop, left: 0, behavior: 'smooth' });
  }
}
