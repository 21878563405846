/* component that shows notification from the queue */
import { Component } from 'react';
import { array, func } from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { deleteNotificationQueue, getNotificationQueue } from '../../redux/modules/notifications';

const mapStateToProps = (state) => ({
  queue: getNotificationQueue(state),
});

const mapDispatchToProps = {
  deleteNotificationQueue,
};

class ToastQueue extends Component {
  componentDidUpdate(prevProps) {
    const { queue: prevQueue } = prevProps;
    const { queue, deleteNotificationQueue } = this.props; // eslint-disable-line no-shadow

    const prevActiveToast = prevQueue[0] || {};
    const activeToast = queue[0];

    // shows notification if there's a notification in the queue
    // checks if the queue is not empty and a notification id is changed
    if (queue.length && prevActiveToast.toastId !== activeToast.toastId) {
      const { message, ...restOptions } = activeToast;

      toast(message, { onClose: deleteNotificationQueue, ...restOptions });
    }
  }

  render() {
    return null;
  }
}

ToastQueue.propTypes = {
  queue: array,
  deleteNotificationQueue: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastQueue);
