/**
 * Renders form to enter payment info.
 */
import React, { PureComponent } from 'react';
import { oneOfType, func, object, bool, array, string, number } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import Card from 'react-credit-cards';
import { reduxForm, getFormValues } from 'redux-form';
import cardValidator from 'card-validator';
import * as Sentry from '@sentry/react';
import { FormInput, DotsLoader } from '../../elements';
import { setStep, setBookingFormParams, setRoute, clearRoute } from '../../redux/modules/bookingForm';
import { createPayment, getBookings } from '../../redux/modules/bookings';
import { getStartDate, getEndDate } from '../../redux/modules/rangeController';
import { getAddonsPrices } from '../../redux/modules/bookingFormData';
import { formatCreditCardNumber, formatCreditCardName, formatExpirationDate, formatNum } from '../../utils/normalizers';
import { scrollToElement, getCardType, getResultError, capitalizeEachWord, getIn } from '../../utils/helpers';
import { showErrorNotif } from '../../utils/notificationActions';
import priceBreakdown, { getFormattedPrice } from '../../utils/priceBreakdown';
import { gtmPurchase } from '../../utils/gtmActions';
import {
  getUserSessionAddonsWithDetails,
  getUserSessionAddons,
  deleteSession,
  updateSession as updateSessionAction,
  getUserSessionErrors,
  getUserSessionUser,
} from '../../redux/modules/session';
import { setModalParams as setModalAction } from '../../redux/modules/modals';
import validate from './paymentInfoValidation';
import styles from './PaymentInfo.module.scss';
import { sentryReactDsn } from '../../../config/config';

const getExperiencesTemplate = function (bookings = []) {
  return bookings.reduce((p, c, i) => {
    const { title } = c;
    const index = i + 1;

    p[`EXP${index}`] = title; // eslint-disable-line
    p[`EXPRATE${index}`] = 'Requested'; // eslint-disable-line
    // alternatives
    p[`ADDON${index}`] = title; // eslint-disable-line
    p[`ADDONRTE${index}`] = 'Requested'; // eslint-disable-line

    return p;
  }, {});
};

const getFormattedExpirationDate = function (value) {
  const { month, year } = cardValidator.expirationDate(value);

  if (month && year) {
    const nextMonth = `0${month}`.slice(-2);
    const nextYear = year.length > 2 ? year.slice(2) : year;

    return `${nextMonth}/${nextYear}`;
  }

  return value;
};

const mapStateToProps = (state) => {
  const user = getUserSessionUser(state);
  return {
    addons: getUserSessionAddons(state),
    addonsWithDetails: getUserSessionAddonsWithDetails(state),
    bookings: getBookings(state),
    startDate: getStartDate(state),
    endDate: getEndDate(state),
    initialValues: user,
    formValues: getFormValues('booking')(state) || {},
    addonsPrices: getAddonsPrices(state),
    sessionErrors: getUserSessionErrors(state),
  };
};

const mapDispatchToProps = {
  setStep,
  createPayment,
  setBookingFormParams,
  setModalParams: setModalAction,
  deleteSession,
  updateSession: updateSessionAction,
};

const mapToForm = {
  form: 'booking',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
};

class PaymentInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      focused: '',
    };
  }

  componentDidMount() {
    scrollToElement('#js-steps-wrapper');

    this.showPaymentTimeoutError();
  }

  showNotificationPaymentTimeoutError = () => {
    showErrorNotif('Sorry, looks like we have a system error.', { autoClose: 10000 });
  };

  showPaymentTimeoutError = () => {
    const { sessionErrors, setModalParams } = this.props;
    // show a modal with timeout error
    if (sessionErrors && sessionErrors.paymentTimeout) {
      setModalParams({
        bookingFormModalData: {
          type: 'payment error',
          disableClickClose: false,
          disableClose: false,
          onClose: () => {
            this.showNotificationPaymentTimeoutError();
          },
        },
      });
      return true;
    }
    return false;
  };

  handleInputFocus = (e, name) => {
    const _name = name.split('.')[1];

    this.setState({ focused: _name });
  };

  handleInputBlur = () => {
    this.setState({ focused: '' });
  };

  handleExpirationBlur = (e) => {
    const { change } = this.props;
    const {
      target: { value },
    } = e;
    const nextValue = getFormattedExpirationDate(value);

    if (value !== nextValue) {
      setTimeout(() => {
        change('card.expiry', nextValue);
      }, 4);
    }

    this.handleInputBlur();
  };

  handleSubmit = async () => {
    const isPaymentError = this.showPaymentTimeoutError();
    if (isPaymentError) {
      this.showNotificationPaymentTimeoutError();
      return;
    }

    // blurs before submit to update form fields
    if (document && document.activeElement) {
      document.activeElement.blur();
    }
    const { addonsWithDetails, addons, formValues, bookings, startDate, endDate, addonsPrices, updateSession } =
      this.props; // eslint-disable-line no-shadow
    const { card, email } = formValues; // eslint-disable-line camelcase
    const { room, guestCount } = bookings;

    const emailLookup = email; // eslint-disable-line camelcase
    const numNights = endDate.diff(startDate, 'days');

    const cardType = getCardType(card.number);

    const cardNumber = card.number.replace(/ /g, '');
    /**
     * when user enters all values
     * then he changes expiration date to another valid value
     * and hits enter and form is submitted with previous non-formatted field
     * due to format is set on input blur event.
     */
    const cardExpirationFormatted = getFormattedExpirationDate(card.expiry);
    const cardExpiry = moment(`${cardExpirationFormatted}/1`, 'MM/YY/DD').endOf('month').format('YYYY-MM-DD');
    const cardNumHidden = card.number.replace(/\S(?=.{4,}$)/g, 'x');

    const addonsWithTitles = addonsWithDetails.map((c) => c.title);
    const addonsTitles = addonsWithTitles.join(', ');
    const subtotal = room.priceSubtotal + addonsPrices;
    const pricesData = priceBreakdown({ room });

    return this.props
      .createPayment(
        {
          address: card.address,
          postalCode: card.zip,
          city: card.city,
          stateProv: card.state,
          cardType: cardType, // eslint-disable-line
          cardHolder: card.name,
          cardNumber: cardNumber, // eslint-disable-line
          expiryDate: cardExpiry,
          fName: formValues.first_name,
          lName: formValues.last_name,
          room: room.id,
          arrivalDate: startDate.format('YYYY-MM-DD'),
          departureDate: endDate.format('YYYY-MM-DD'),
          addons,
          guestCount,
        },
        {
          FNAME: formValues.first_name,
          LNAME: formValues.last_name,
          EMAIL: email,
          EMAILLOOKUP: emailLookup,
          PHONE: formValues.phone_number,
          /**
           */
          ARRIVAL: startDate.format('M/D/YYYY'),
          DEPARTURE: endDate.format('M/D/YYYY'),
          ROOMTYPE: capitalizeEachWord(room.title),
          NIGHTS: numNights,
          AVGRATE: getFormattedPrice(room.priceRate),
          ROOMTOTAL: getFormattedPrice(room.priceSubtotal),
          EXPTOTAL: getFormattedPrice(addonsPrices),
          SUBTOTAL: getFormattedPrice(subtotal),
          WEBFOLIOID: room.id,
          /* -- TAXES AND FEES */
          TRANSIENTTAX: getFormattedPrice(pricesData.CTT),
          TOURISMTAX: getFormattedPrice(pricesData.TAT),
          TAXES: getFormattedPrice(pricesData.TT),
          SUSTAINFEE: getFormattedPrice(pricesData.SF),
          FEES: getFormattedPrice(pricesData.FT),
          TAXESFEES: getFormattedPrice(room.priceSurcharges),
          /* TAXES AND FEES -- */
          /**
           * NOTE.
           * Values from server response and should be changed there:
           * TOTALCHARGED
           * INVOICE
           * BOOKEDON
           * src/server/server.js
           */
          CREDITCARD: `${cardType} ${cardNumHidden}`,
          BILLINGADDRESS: card.address,
          BILLINGADD: card.address, // 10 char limit for subscriber template merge tags
          BILLINGZIP: card.zip,
          EXPERIENCES: addonsTitles,
          EXPS: addonsTitles, // for limited merge_tag characters in subscriber templates
          ADDONS: addonsTitles, // alternative
          ...getExperiencesTemplate(addonsWithDetails),
        }
      )
      .then((res) => {
        const err = getIn(res.data, 'result.0.soap:Fault.0.faultstring.0');
        if (err) {
          showErrorNotif(err, { autoClose: 3000 });
          return;
        }

        const errorPayment = getIn(res.data, 'result.0.CreateBookingResponse.0.Result.0.$.resultStatusFlag');
        if (errorPayment?.toLocaleLowerCase() === 'fail') {
          showErrorNotif(getIn(res.data, 'result.0.CreateBookingResponse.0.Result.0.c:OperaErrorCode')?.join(' '), {
            autoClose: 3000,
          });
          return;
        }

        this.props.setBookingFormParams({ isFinalStep: true });
        setRoute(null, null, { isFinalStep: true });

        const Invoice = getIn(
          res.data,
          'result.0.CreateBookingResponse.0.HotelReservation.0.r:UniqueIDList.0.c:UniqueID.1._'
        );
        const Total = getIn(
          res.data,
          'result.0.CreateBookingResponse.0.HotelReservation.0.r:RoomStays.0.hc:RoomStay.0.hc:Total.0._'
        );

        const Taxes = getIn(
          res.data,
          'result.0.CreateBookingResponse.0.HotelReservation.0.r:RoomStays.0.hc:RoomStay.0.hc:ExpectedCharges.0.$.TotalTaxesAndFees'
        );

        gtmPurchase(room, addonsWithDetails, {
          Invoice,
          Total,
          Taxes,
        });

        this.props.deleteSession();
        clearRoute();
      })
      .catch((err) => {
        if (err.response.status === 503) {
          updateSession({ errors: { paymentTimeout: true } }).then(() => {
            if (sentryReactDsn) {
              Sentry.captureException({
                method: 'POST',
                url: window.location.pathname,
                error: 'Proxy timeout error',
              });
            }
            this.showPaymentTimeoutError();
          });
        } else {
          showErrorNotif(getResultError(err.data), { autoClose: 3000 });
        }
      });
  };

  renderCardField({ isCardPreview, ...rest }) {
    const { submitting } = this.props;

    return (
      <FormInput
        onFocus={isCardPreview ? this.handleInputFocus : undefined}
        onBlur={isCardPreview ? this.handleInputBlur : undefined}
        disabled={submitting}
        {...rest}
      />
    );
  }

  renderCard() {
    const { focused = '' } = this.state;
    const {
      formValues: { card = {} },
    } = this.props;
    const { number = '', name = '', expiry = '', cvc = '' } = card; // eslint-disable-line

    return <Card number={number} name={name} expiry={expiry} cvc={cvc} focused={focused} />;
  }

  renderFormSubmit() {
    const { valid, submitting } = this.props;

    return (
      <div className={styles['payment-form__btns']}>
        <button
          className={cx('btn-submit', {
            'btn-submit--valid': valid,
          })}
          type="submit"
          disabled={submitting}
        >
          <>
            <span style={{ opacity: submitting ? 0 : undefined }}>take me to malibu!</span>
            {submitting && <DotsLoader />}
          </>
        </button>
      </div>
    );
  }

  renderForm() {
    const { handleSubmit } = this.props;

    return (
      <form className={styles['payment-form']} onSubmit={handleSubmit(this.handleSubmit)}>
        <div className={styles['payment-form__card']}>{this.renderCard()}</div>
        <div className={styles['payment-form__row']}>
          {this.renderCardField({
            name: 'card.number',
            placeholder: 'Card Number',
            type: 'tel',
            normalize: formatCreditCardNumber,
            isCardPreview: true,
          })}
        </div>
        <div className={styles['payment-form__row']}>
          {this.renderCardField({
            name: 'card.name',
            placeholder: 'Name on card',
            isCardPreview: true,
            normalize: formatCreditCardName,
          })}
        </div>
        <div className={cx(styles['payment-form__row'], styles['payment-form__row--cols'])}>
          {this.renderCardField({
            name: 'card.expiry',
            placeholder: 'Valid Thru',
            type: 'tel',
            normalize: formatExpirationDate,
            onBlur: this.handleExpirationBlur,
            isCardPreview: true,
          })}
        </div>
        <div className={cx(styles['payment-form__row'], styles['payment-form__row--cols'])}>
          {this.renderCardField({
            name: 'card.address',
            placeholder: 'Billing Address',
          })}
          {this.renderCardField({
            name: 'card.zip',
            placeholder: 'Billing Zip Code',
            type: 'tel',
            normalize: formatNum,
          })}
        </div>
        {this.renderFormSubmit()}
      </form>
    );
  }

  render() {
    return (
      <div className={styles['payment-info']}>
        <div className="booking-form__step-header">
          <div className="booking-form__left">
            <h2 className="booking-form__ttl">Payment info</h2>
            <button className="booking-form__btn-back" type="button" onClick={() => this.props.setStep(4, 1)}>
              back to my info
            </button>
          </div>
          <div className="booking-form__sub-ttl">Complete your booking using our secure payment service.</div>
        </div>
        <div className={styles['payment-info__content']}>{this.renderForm()}</div>
      </div>
    );
  }
}

PaymentInfo.propTypes = {
  addons: array,
  addonsPrices: oneOfType([string, number]),
  addonsWithDetails: array,
  bookings: object,
  change: func,
  createPayment: func,
  deleteSession: func,
  endDate: object,
  formValues: object,
  sessionErrors: object,
  handleSubmit: func,
  setBookingFormParams: func,
  setModalParams: func,
  setStep: func,
  startDate: object,
  submitting: bool,
  valid: bool,
  updateSession: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(mapToForm)(PaymentInfo));
