/*
  One of the steps of the BookingForm.
*/
import React, { Component } from 'react';
import { number, object } from 'prop-types';
import { connect } from 'react-redux';
import { AccommodationsList, AddonsList } from '../../..';
import { getActiveStep, getSteps } from '../../../../redux/modules/bookingForm';

import BookingFormTransitions from '../../BookingFormTransitions';
import styles from './Accomodations.module.scss';

const mapStateToProps = (state) => ({
  activeStep: getActiveStep(state),
  steps: getSteps(state),
});

const mapDispatchToProps = {};

class Accomodations extends Component {
  shouldComponentUpdate(nextProps) {
    // prevents possible redundant children mounts
    // do not proceed if not equal actual step (activeStep)
    if (nextProps.activeStep !== 2) return false;

    return true;
  }

  /*
    renders substep data according to the active step and substep
  */
  renderSubSteps = (activeSubStep) => {
    switch (activeSubStep) {
      case 1:
        return <AccommodationsList />;
      case 2:
        return <AddonsList />;
      default:
        return "Sorry, there's no substep";
    }
  };

  render() {
    const { activeStep, steps } = this.props;
    const activeSubStep = steps[activeStep].subSteps.active;

    return (
      <div className={styles.accomodations}>
        <BookingFormTransitions activeStep={activeSubStep}>{this.renderSubSteps(activeSubStep)}</BookingFormTransitions>
      </div>
    );
  }
}

Accomodations.propTypes = {
  activeStep: number,
  steps: object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Accomodations);
