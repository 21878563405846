// https://github.com/fkhadra/react-toastify
import React, { Fragment } from 'react';
import { ToastContainer, cssTransition } from 'react-toastify';

import ToastQueue from './ToastQueue';
import './toastify.module.scss';
import styles from './toastTransitions.module.scss';

const customSlide = cssTransition({
  enter: styles.slideDown,
  exit: styles.slideUp,
  collapse: false,
});

const defaultToastOptions = {
  className: 'large-full-width',
  transition: customSlide,
  hideProgressBar: true,
  autoClose: 2000,
  closeButton: false,
  draggable: false,
  pauseOnHover: false,
};

function ToastContainerWrapper() {
  return (
    <>
      <ToastContainer {...defaultToastOptions} />
      <ToastQueue />
    </>
  );
}

export default ToastContainerWrapper;
