import { destroy as formDestroy } from 'redux-form';
import { setRangeControllerParams } from '../redux/modules/rangeController';
import { clear as clearForm } from '../redux/modules/bookingForm';
import { clearAllButDates as clearFormDataButDates } from '../redux/modules/bookingFormData';
import { clearBookings } from '../redux/modules/bookings';

import { store } from '../redux/store';

export function clearSessionStorage() {
  localStorage.removeItem('reducerBookingForm');
}

export function resetSessionReducers(excludedData = {}) {
  const { excludedFormData } = excludedData;

  store.dispatch(
    setRangeControllerParams({
      startDate: null,
      endDate: null,
      focusedInput: 'startDate',
    })
  );
  store.dispatch(clearForm(excludedFormData));
  store.dispatch(clearFormDataButDates());
  store.dispatch(clearBookings());
  store.dispatch(formDestroy('booking'));
}

export function resetAllSessionData(excludedData) {
  clearSessionStorage();
  resetSessionReducers(excludedData);
}
