/*
  renders error content when the service is down
  or returns error
*/
import React, { Fragment } from 'react';
import { Confirm } from '../../../elements';

function onConfirm() {
  window.location.reload();
}

function ServiceError() {
  return (
    <div className="modal__note">
      <Confirm
        title={
          <>
            <span>important note</span>
            <span className="modal__icon-info" />
          </>
        }
        description={
          <span>
            Our site is currently under maintenance.
            <br />
            Please call our front desk at <a href="tel:818-575-4400">818-575-4400</a> to book your stay.
          </span>
        }
        confirmText="reload"
        onConfirm={onConfirm}
        isAlert
      />
    </div>
  );
}

export default ServiceError;
