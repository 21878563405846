import React from 'react'; // eslint-disable-line
import { renderRoutes } from 'react-router-config';
import { object } from 'prop-types';
// import { Header, Footer } from '../index';
import withDevice from './withDevice';
// import './section.module.scss';

export const Section = ({ route: { routes } }) => renderRoutes(routes);

Section.propTypes = {
  route: object,
};

Section.defaultProps = {
  route: object,
};

export default withDevice(Section);
