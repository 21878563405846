import { Section } from '../components';
import { Home } from './index';

export default (store) => { // eslint-disable-line
  // we can get an access to store
  return [
    {
      component: Section,
      routes: [
        {
          path: '*',
          exact: true,
          component: Home,
        },
      ],
    },
  ];
};
