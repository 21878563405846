/**
 * Renders booking form steps with transition.
 */
import React, { Component } from 'react';
import { node, number, string } from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const TRANSITION_DURATION = 400;

// the childFactory allows to change the transition of the leaving component
// https://github.com/reactjs/react-transition-group/issues/182
const childFactoryCreator = (props) => (child) => React.cloneElement(child, props);

export default class BookingFormTransitions extends Component {
  constructor(props) {
    super(props);
    this._prevActiveStep = null;
  }

  handleTransitionEnter = () => {
    const { activeStep } = this.props;

    this._prevActiveStep = activeStep;
    document.body.classList.add('transition-group-enter');
  };

  handleTransitionEntered = () => {
    document.body.classList.remove('transition-group-enter');
  };

  render() {
    const { className, children, activeStep } = this.props;
    const classNames = activeStep < this._prevActiveStep ? 'slide-reverse' : 'slide';

    return (
      <TransitionGroup
        className={`transition-group ${className}`}
        childFactory={childFactoryCreator({ classNames, timeout: TRANSITION_DURATION })}
      >
        <CSSTransition
          key={activeStep}
          timeout={TRANSITION_DURATION} // fixes server propType warning
          onEnter={this.handleTransitionEnter}
          onEntered={this.handleTransitionEntered}
        >
          <div className="transition-group__section">{children}</div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

BookingFormTransitions.propTypes = {
  children: node.isRequired,
  activeStep: number.isRequired,
  className: string,
};

BookingFormTransitions.defaultProps = {
  className: 'transition-group--booking-form',
};
