import React from 'react';
import { func } from 'prop-types';
import { Confirm } from '../../../elements';

import termsData from '../../MyInfo/terms_conditions.yaml';

/**
 * Gets invoked when the accept button is clicked
 * @param {object} param0 - props
 * @param {function} param0.setStep
 * @param {function} param0.closeModal
 */
function handleAccept({ setStep, closeModal }) {
  // activeStep (or current activeStep if not passed), activeSubStep
  setStep(4, 2);
  closeModal();
}

function TermsConditions(props) {
  const { closeModal } = props;

  return (
    <div className="modal__note">
      <Confirm
        title={<span>accept terms & conditions</span>}
        htmlDescription={termsData.terms}
        cancelText="cancel"
        confirmText="accept"
        onConfirm={() => handleAccept(props)}
        onCancel={closeModal}
        isScrollable
      />
    </div>
  );
}

TermsConditions.propTypes = {
  closeModal: func,
};

export default TermsConditions;
