import React from 'react';
import { HelmetWrapper } from '../../elements';
import { BookingForm } from '../../components';

import styles from './Home.module.scss';

const Home = () => {
  const title = 'Book | Calamigos Guest Ranch and Beach Club';
  const description = 'Book | Calamigos Guest Ranch and Beach Club';

  return (
    <div className={styles['home-wrapper']}>
      <HelmetWrapper title={title} description={description} />
      <div className={styles.home}>
        <BookingForm />
      </div>
    </div>
  );
};

export default Home;
