import memoize from 'lru-memoize';
import { createValidator, required, email, phoneNumber } from '../../utils/validation';

const phoneNumberError = 'Phone number must be valid US or International number.';

const validate = createValidator({
  first_name: [required],
  last_name: [required],
  email: [required, email],
  phone_number: [required, phoneNumber(phoneNumberError)],
});

export default memoize(10)(validate);
