/*
  reducer for react-dates
  stores react-dates data in redux to be able get it in any component
*/
import { START_DATE } from 'react-dates/lib/constants';
import { createSelector } from 'reselect';

const STATE_KEY = 'rangeController';

const SET_DATES = `${STATE_KEY}/SET_DATES`;

const SET_FOCUSED_INPUT = `${STATE_KEY}/SET_FOCUSED_INPUT`;

const SET_RANGE_PARAMS = `${STATE_KEY}/SET_RANGE_PARAMS`;

const CLEAR = `${STATE_KEY}/CLEAR`;

const initialState = {
  startDate: null,
  endDate: null,
  focusedInput: START_DATE,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_DATES:
      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate,
      };
    case SET_FOCUSED_INPUT:
      return {
        ...state,
        focusedInput: action.focusedInput,
      };
    case SET_RANGE_PARAMS:
      return {
        ...state,
        ...action.params,
      };
    case CLEAR:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}

/*
  standard reducer action
  but returns Promise to be able combine this action with other Promises or async/await
*/
export function setDates(startDate, endDate) {
  return {
    type: SET_DATES,
    startDate,
    endDate,
  };
}

/*
  triggers when focused input changes. required for react-dates
*/
export function setFocusedInput(focusedInput) {
  return {
    type: SET_FOCUSED_INPUT,
    // force the focusedInput to always be truthy so that dates are always selectable
    focusedInput: focusedInput || START_DATE,
  };
}

export function setRangeControllerParams(params = {}) {
  return {
    type: SET_RANGE_PARAMS,
    params,
  };
}

export function clear() {
  return {
    type: CLEAR,
  };
}

// selectors
const getState = (state) => state[STATE_KEY];

export const getStartDate = createSelector(getState, (state) => state.startDate);
export const getEndDate = createSelector(getState, (state) => state.endDate);
export const getFocusedInput = createSelector(getState, (state) => state.focusedInput);
