/*
Substep of the Accommodations step.
Loads addons when component mounts.
*/
import React, { PureComponent } from 'react';
import { func, object, bool, array } from 'prop-types';
import { connect } from 'react-redux';
import { setStep } from '../../redux/modules/bookingForm';
import { updateSession, getUserSessionAddons } from '../../redux/modules/session';
import {
  loadAddons,
  clearAddons,
  getAddons,
  getAddonsLoaded,
  getAddonsPrices,
} from '../../redux/modules/bookingFormData';
import { getStartDate, getEndDate } from '../../redux/modules/rangeController';
import { addNotificationQueue } from '../../redux/modules/notifications';
import { setModalParams } from '../../redux/modules/modals';
import { scrollToElement } from '../../utils/helpers';
import { DEFAULT_ERR_MSG } from '../../utils/notificationsFormat';

import AddonItem from './AddonItem';
import AddonsListLoader from './AddonsListLoader';
import addonsData from './experiences.yaml';
import styles from './AddonsList.module.scss';

const MAX_FLIPPED_ITEMS = 4; // number (0 = disable)

/*
  Filters enabled/disabled groups/addons
  from the imported yaml file.
*/
const getAddonsList = function () {
  const addonsList = [];

  addonsData.experiences.forEach((c) => {
    if (!c.enabled) return;

    c.experience.forEach((cc) => {
      cc.enabled && addonsList.push(cc); // eslint-disable-line
    });
  });
  return addonsList.sort((a, b) => a.order - b.order);
};

const mapStateToProps = (state) => ({
  list: getAddons(state),
  loaded: getAddonsLoaded(state),
  startDate: getStartDate(state),
  endDate: getEndDate(state),
  addons: getUserSessionAddons(state),
  addonsPrices: getAddonsPrices(state),
});

const mapDispatchToProps = {
  loadAddons,
  clearAddons,
  addNotificationQueue,
  setStep,
  setModalParams,
  updateSession,
};

class AddonsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      flippedItems: [],
    };

    this._numberFormat = Intl.NumberFormat();
    this._addonsList = getAddonsList();
    this._itemIdKey = 'id';
  }

  componentDidMount() {
    this.loadAddons();
    scrollToElement('#js-steps-wrapper');
  }

  componentWillUnmount() {
    this.props.clearAddons();
  }

  handleBooking = (addonData) => async () => {
    const { updateSession, addons: prevAddons } = this.props; // eslint-disable-line no-shadow
    let nextAddons = [...prevAddons];
    const isExists = prevAddons.includes(addonData.id);
    if (isExists) {
      nextAddons = nextAddons.filter((c) => c !== addonData.id);
    } else {
      nextAddons = [...nextAddons, addonData.id];
    }
    return updateSession({ addons: nextAddons });
  };

  /**
   * Handles opened (flipped) items.
   * Maximum of 4 items can be open at a time.
   * If next clicked item is already opened remove it from state.
   *
   * Else checks if there are more than MAX_FLIPPED_ITEMS items and
   * removes the earliest open card and adds new opened card to the state.
   *
   * @param {number} id - id of the clicked addon card
   */
  handleFlip = (id) => {
    const { flippedItems } = this.state;
    const isFlipped = flippedItems.includes(id);
    let nextFlippedItems;

    if (isFlipped) {
      nextFlippedItems = flippedItems.filter((c) => c !== id);
    } else {
      const flippedLength = flippedItems.length;
      const _flippedItems =
        MAX_FLIPPED_ITEMS && flippedLength >= MAX_FLIPPED_ITEMS ? flippedItems.slice(1, flippedLength) : flippedItems;

      nextFlippedItems = [..._flippedItems, id];
    }

    this.setState({ flippedItems: nextFlippedItems });
  };

  /**
   * Gets invoked when the done button is clicked.
   * @callback <AddonItem> done button
   */
  handleDone = () => {
    const { setModalParams } = this.props; // eslint-disable-line no-shadow

    setModalParams({
      bookingFormModalData: {
        type: 'addons agreement',
        disableClickClose: true,
      },
    });
  };

  /**
   * Gets invoked when the skip button is clicked.
   * @callback AddonItem skip button
   */
  handleSkip = () => {
    const { setStep } = this.props; // eslint-disable-line no-shadow

    setStep(3, null, 2);
  };

  /*
    Loads addons.
    Location id is required field
    so gets id first than loads addons.
  */
  async loadAddons() {
    const { startDate, endDate, loadAddons } = this.props; // eslint-disable-line no-shadow

    if (!startDate || !endDate) {
      this.showWarning();
      return;
    }

    loadAddons({
      arrivalDate: startDate.format('YYYY-MM-DD'),
      departureDate: endDate.format('YYYY-MM-DD'),
    });
  }

  showWarning() {
    this.props.addNotificationQueue(DEFAULT_ERR_MSG, {
      toastId: 'customer_err',
      type: 'warning',
      autoClose: false,
      closeOnClick: false,
    });
  }

  // render methods below
  renderContinue() {
    const { addons, loaded } = this.props;
    if (addons?.length) {
      return (
        <button className="booking-form__btn-done" type="button" disabled={!loaded} onClick={this.handleDone}>
          I&apos;m done
        </button>
      );
    }

    return (
      <button className="booking-form__btn-continue" type="button" disabled={!loaded} onClick={this.handleSkip}>
        No thanks, please continue with booking
      </button>
    );
  }

  /*
    renders items of the addons list
  */
  renderItems() {
    const { flippedItems } = this.state;
    const { loaded } = this.props;

    if (!loaded) {
      return (
        <div className={styles['addons-list__loader']}>
          <AddonsListLoader />
        </div>
      );
    }

    return this._addonsList.map((c, i) => {
      const { addons } = this.props;
      const { title, photo } = c;
      const id = c[this._itemIdKey];
      const isFlipped = flippedItems.includes(id);
      const addonData = { id, title, photo, itemIndex: i };
      const isBooked = addons.findIndex((v) => v === id) > -1;

      return (
        <li key={id} className={styles['addons-list__item']}>
          <AddonItem
            id={id}
            title={title}
            description={c.description}
            photo={photo}
            price={c.price}
            isFlipped={isFlipped}
            isBooked={isBooked}
            handleFlip={this.handleFlip}
            handleBooking={this.handleBooking(addonData)}
          />
        </li>
      );
    });
  }

  render() {
    return (
      <div className={styles['addons-list']}>
        <div className="booking-form__step-top">
          <div className="booking-form__step-header">
            <div className="booking-form__left">
              <h2 className="booking-form__ttl">Add-ons</h2>
              <button className="booking-form__btn-back" type="button" onClick={() => this.props.setStep(2, 1)}>
                back to select your room
              </button>
            </div>
            <div className="booking-form__sub-ttl">
              To further enhance your stay, please choose from our selection of activities & experiences and our
              personal concierge will reach out to you:
            </div>
          </div>
          {this.renderContinue()}
        </div>
        <ul className={styles['addons-list__list']}>{this.renderItems()}</ul>
      </div>
    );
  }
}

AddonsList.propTypes = {
  loadAddons: func,
  clearAddons: func,
  addons: array,
  addNotificationQueue: func,
  startDate: object,
  endDate: object,
  loaded: bool,
  setStep: func,
  setModalParams: func,
  updateSession: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddonsList);
