import React, { Component } from 'react';
import { bool } from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Loader } from '../../elements';

export default class BookingFormLoader extends Component {
  constructor(props) {
    super(props);
    this._elementPlaceholder = null;
  }

  componentDidMount() {
    const { isLoaderVisible } = this.props;

    this._elementPlaceholder = document.createElement('div');

    if (isLoaderVisible) {
      disableBodyScroll(this._elementPlaceholder);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isLoaderVisible && this.props.isLoaderVisible) {
      disableBodyScroll(this._elementPlaceholder);
    } else if (prevProps.isLoaderVisible && !this.props.isLoaderVisible) {
      enableBodyScroll(this._elementPlaceholder);
    }
  }

  render() {
    const { isLoaderVisible } = this.props;

    if (!isLoaderVisible) return null;

    return <Loader />;
  }
}

BookingFormLoader.propTypes = {
  isLoaderVisible: bool,
};
