/**
 * Renders form to enter personal info.
 */
import React, { PureComponent, Fragment } from 'react';
import { func, object, bool } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { reduxForm } from 'redux-form';
import { FormInput, DotsLoader } from '../../elements';
import { updateSession, getUserSessionUser } from '../../redux/modules/session';
import { setModalParams } from '../../redux/modules/modals';
import { scrollToElement } from '../../utils/helpers';
import { showErrorNotif } from '../../utils/notificationActions';

import validate from './myInfoValidation';
import styles from './MyInfo.module.scss';

const mapStateToProps = (state) => {
  const user = getUserSessionUser(state);
  return {
    initialValues: user,
    user,
  };
};

const mapDispatchToProps = {
  setModalParams,
  updateSession,
};

const mapToForm = {
  form: 'booking',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate,
};

class MyInfo extends PureComponent {
  componentDidMount() {
    scrollToElement('#js-steps-wrapper', 60);
  }

  /**
   * Shows modal with confirmation
   */
  handleContinue() {
    this.props.setModalParams({
      bookingFormModalData: { type: 'terms conditions' },
    });
  }

  /**
   * Gets invoked when the form is valid
   * @param {object} data - data from the form
   */
  handleSubmit = (data) => {
    if (data.website) return;

    return this.props
      .updateSession({ user: { ...this.props.user, ...data } })
      .then(() => {
        this.handleContinue();
      })
      .catch((err) => {
        console.log(err);
        showErrorNotif();
      });
  };

  renderFormSubmit() {
    const { valid, submitting } = this.props;

    return (
      <div className={styles['my-info__btns']}>
        <button
          className={cx('btn-submit', {
            'btn-submit--valid': valid,
          })}
          type="submit"
          disabled={submitting}
        >
          <>
            <span style={{ opacity: submitting ? 0 : undefined }}>continue to payment</span>
            {submitting && <DotsLoader />}
          </>
        </button>
      </div>
    );
  }

  renderForm() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className={styles['my-info__row']}>
          <FormInput name="first_name" label="name*" placeholder="First" />
          <FormInput
            classNameField="form-field--no-label"
            name="last_name"
            label={<span>&nbsp;</span>}
            placeholder="Last"
          />
        </div>
        <div className={cx(styles['my-info__row'], styles['my-info__row--margin-mobile'])}>
          <FormInput name="email" label="email*" placeholder="Email Adress" type="email" />
          <FormInput name="phone_number" label="contact*" placeholder="Phone Number" type="tel" />
        </div>
        <div className={styles['my-info__row']} style={{ display: 'none' }}>
          <FormInput name="website" />
        </div>
        {this.renderFormSubmit()}
      </form>
    );
  }

  render() {
    return (
      <div className={styles['my-info']}>
        <div className={styles['my-info__header']}>
          <h2 className="booking-form__ttl">My info</h2>
          <div className="booking-form__sub-ttl">
            Please fill in your information to continue your booking. Fields marked with an asterisk (*) are required.
          </div>
        </div>
        {this.renderForm()}
      </div>
    );
  }
}

MyInfo.propTypes = {
  handleSubmit: func.isRequired,
  setModalParams: func.isRequired,
  submitting: bool,
  updateSession: func.isRequired,
  user: object,
  valid: bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm(mapToForm)(MyInfo));
