/*
  Single item of the AddonsList.
*/
import React, { Component, Fragment } from 'react';
import { oneOfType, number, string, func, bool, object } from 'prop-types';
import cx from 'classnames';
import { DotsLoader } from '../../elements';

import styles from './AddonItem.module.scss';
import CheckIcon from './checkicon.svg';

const MOBILE_WIDTH = 767;

class AddonsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  /*
    gets invoked when the add/remove button is clicked
  */
  handleBookItem = async (e) => {
    const { handleBooking } = this.props;

    e.stopPropagation();

    this.setState({ isLoading: true });

    try {
      await handleBooking();
    } catch (err) {
      console.log(err);
    }

    this.setState({ isLoading: false });
  };

  /*
    Gets invoked when the whole item is clicked.

    For desktop, user has to click button to flip add-on card BUT
    the front side of add-on card is entirely clickable.
    For mobile, user can just click the entire thumbnail to flip card.

    Triggers flipCard method only on mobile and item is already flipped.
  */
  handleItemClick = () => {
    const { isFlipped } = this.props;
    const isMobile = window.innerWidth <= MOBILE_WIDTH;

    if (isFlipped && !isMobile) return;

    this.flipCard();
  };

  /*
    Gets invoked when the close button of the card is clicked.
  */
  handleCloseClick = (e) => {
    this.flipCard();

    e.stopPropagation();
  };

  /*
    flips current item
  */
  flipCard() {
    const { id, handleFlip } = this.props;

    handleFlip(id);
  }

  renderFrontSide() {
    const { title, photo, isBooked } = this.props;
    const folderPath = '/images/addons';

    return (
      <div className={cx(styles['addon-item__side'], styles['addon-item__side--front'])} style={{ background: '#ccc' }}>
        <div className={styles['addon-item__front-images']}>
          <div
            className={styles['addon-item__front-img']}
            style={{ backgroundImage: `url(${folderPath}/${photo.normal})` }}
          />
          <div
            className={cx(styles['addon-item__front-img'], styles['addon-item__front-img--retina'])}
            style={{ backgroundImage: `url(${folderPath}/${photo.double})` }}
          />
        </div>
        <div className={styles['addon-item__front-ttl']} title={title}>
          <span>{title}</span>
        </div>
        {isBooked && <CheckIcon className={styles['addon-item__img-booked']} alt="item is booked" />}
      </div>
    );
  }

  renderBackSide() {
    const { title, price, description, isBooked } = this.props;

    const _price = ((price || price === 0) && `$${price}`) || 'N/A';
    const _description = description || 'N/A';
    // booking can be disabled for every add-on with props
    // or for current clicked add-on with state
    const isLoading = this.props.isLoading || this.state.isLoading;

    return (
      <div className={cx(styles['addon-item__side'], styles['addon-item__side--back'])}>
        <div className={styles['addon-item__back-box']}>
          <div className={styles['addon-item__back-ttl']}>{title}</div>
          <div className={styles['addon-item__starting_at']}>Starting at</div>
          <div className={styles['addon-item__price']}>{_price}</div>
          <div className={styles['addon-item__descr']} title={_description}>
            {_description}
          </div>
          <button
            className={cx(styles['addon-item__btn-book'], {
              [styles['addon-item__btn-book--booked']]: isBooked,
              [styles['addon-item__btn-book--loading']]: isLoading,
            })}
            type="button"
            disabled={isLoading}
            onClick={this.handleBookItem}
          >
            <>
              <span style={{ opacity: isLoading ? 0 : undefined }}>
                {!isBooked ? "I'm Interested!" : 'remove item'}
              </span>
              {isLoading && <DotsLoader size="10" />}
            </>
          </button>
        </div>
        <button className={styles['addon-item__btn-close']} type="button" onClick={this.handleCloseClick}>
          X
        </button>
      </div>
    );
  }

  render() {
    const { isFlipped } = this.props;

    return (
      <div
        className={cx(styles['addon-item'], {
          [styles['addon-item--is-flipped']]: isFlipped,
        })}
        onClick={this.handleItemClick}
      >
        {this.renderFrontSide()}
        {this.renderBackSide()}
      </div>
    );
  }
}

AddonsItem.propTypes = {
  id: oneOfType([number, string]).isRequired,
  title: string.isRequired,
  price: oneOfType([number, string]).isRequired,
  description: string,
  photo: object,
  handleFlip: func.isRequired,
  handleBooking: func.isRequired,
  isFlipped: bool,
  isBooked: bool,
  isLoading: bool,
};

export default AddonsItem;
