/*
  Shows booking items when they are added in headers.
*/
import React, { Fragment } from 'react';
import { oneOfType, string, number, object, array } from 'prop-types';
import { Dropdown } from '../../elements';
import { simplePluralize, capitalizeEachWord } from '../../utils/helpers';
import { getFormattedNumber } from '../../utils/priceBreakdown';

import HeadersAddons from './HeadersAddons';
import PriceItems from './PriceItems';

/**
 * renders added room
 * @param {object} room - booked room
 * @param {function} setStep - function to change step
 */
function renderRooms({ bookings: { room }, setStep }) {
  const capitalizedTitle = capitalizeEachWord(room.title);

  return (
    <div
      className="header__stay-item header__stay-item--clickable"
      title={capitalizedTitle}
      onClick={() => setStep(2, 1)}
    >
      <span>{capitalizedTitle}</span>
    </div>
  );
}

/**
 * renders added add-ons
 * if empty add-ons returns nothing
 * if there's one added add-on returns this element
 * if there are more than one added add-ons returns
 *   single element and dropdown with rest of the elements
 *
 * @param {array} addons - added add-ons
 * @param {function} setStep - function to change step
 */
function renderAddons({ addons, setStep }) {
  if (!addons.length) return null;

  if (addons.length === 1) {
    return <HeadersAddons list={addons} setStep={setStep} />;
  }

  const singleAddon = addons.slice(0, 1);
  const restAddons = addons.slice(1, addons.length);

  return (
    <>
      <HeadersAddons list={singleAddon} setStep={setStep} />
      <Dropdown
        trigger={(isOpened) => (
          <button className="btn-show-more" type="button">
            <span className="btn-show-more__txt">{`view ${!isOpened ? 'more' : 'less'}`}</span>
            <span className="btn-show-more__icon">{!isOpened ? <span>+</span> : <span>&ndash;</span>}</span>
          </button>
        )}
      >
        <HeadersAddons list={restAddons} setStep={setStep} />
      </Dropdown>
    </>
  );
}

/*
  renders booking item blocks
  when there are selected booking items
*/
function HeadersBookingItems(props) {
  const { startDate, endDate, bookings, addons, addonsPrices } = props;
  const deltaDays = startDate && endDate && endDate.diff(startDate, 'days');

  const adultGuestCount = bookings.guestCount.adultCount ? parseInt(bookings.guestCount.adultCount, 10) : 0;
  const childGuestCount = bookings.guestCount.childCount ? parseInt(bookings.guestCount.childCount, 10) : 0;

  if (!deltaDays) return null;

  return (
    <>
      <div className="header__item header__item--stay">
        <div className="header__box">
          <div className="header__item-name">stay</div>

          <div className="header__stay-item">{`${deltaDays} ${simplePluralize('Night', deltaDays)}`}</div>
          {adultGuestCount > 0 && (
            <div className="header__stay-item">
              {bookings.guestCount.adultCount} {adultGuestCount === 1 ? 'Adult ' : 'Adults '}
              {childGuestCount > 0 && (
                <span className="header__stay-item">
                  & {bookings.guestCount.childCount}
                  {childGuestCount === 1 ? ' Child' : ' Children'}
                </span>
              )}
            </div>
          )}

          {bookings.room && renderRooms(props)}
          {!!addons && renderAddons(props)}
        </div>
      </div>
      {bookings.room && (
        <>
          <div className="header__item header__item--prices">
            <div className="header__box">
              <div className="header__item-name">price breakdown</div>
              <PriceItems room={bookings.room} addons={addonsPrices} />
            </div>
          </div>
          <div className="header__item header__item--total">
            <div className="header__box">
              <div className="header__item-name">grand total (USD)</div>
              <div className="header__item-price">${getFormattedNumber(bookings.room.priceTotal + addonsPrices)}</div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

HeadersBookingItems.propTypes = {
  startDate: object,
  endDate: object,
  bookings: object,
  addons: array,
  addonsPrices: oneOfType([string, number]),
};

export default HeadersBookingItems;
