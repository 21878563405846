/**
 * Renders room of the Reservations step.
 */
import React, { Component } from 'react';
import { oneOfType, string, number, object, func } from 'prop-types';
import cx from 'classnames';

import styles from './Reservations.module.scss';

export default class ReservationRoom extends Component {
  /**
   * RENDER methods BELOW
   */
  renderVisual() {
    const { title, photo } = this.props;
    const folderPath = '/images/room_types';

    return (
      <div className={styles['reservation-item__visual']}>
        <div className={styles['reservation-item__img-wrap']}>
          <img
            className={styles['reservation-item__img']}
            src={`${folderPath}/${photo.normal}`}
            srcSet={`${folderPath}/${photo.normal} 1x,
              ${folderPath}/${photo.double} 2x`}
            alt={title}
          />
        </div>
      </div>
    );
  }

  renderContent() {
    const { title, price, startDate, endDate, handleEditRoom } = this.props;

    return (
      <div className={styles['reservation-item__info']}>
        <h3 className={styles['reservation-item__ttl']}>{title}</h3>
        <div className={styles['reservation-item__dates']}>
          <div className={styles['reservation-item__dates-item']}>
            <span>Arrive: </span>
            <strong>{startDate}</strong>
          </div>
          <div className={styles['reservation-item__dates-item']}>
            <span>Depart: </span>
            <strong>{endDate}</strong>
          </div>
        </div>
        <div className={styles['reservation-item__wrap']}>
          <div className={styles['reservation-item__price']}>
            <div className={styles['reservation-item__price-name']}>Room total (USD)</div>
            <div className={styles['reservation-item__price-value']}>
              <strong>${price}</strong>
            </div>
          </div>
          <button className={styles['reservation-item__link']} type="button" onClick={handleEditRoom}>
            edit room
          </button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={cx(styles['reservation-item'], styles['reservation-item--room'])}>
        {this.renderVisual()}
        <div className={styles['reservation-item__content-box']}>{this.renderContent()}</div>
      </div>
    );
  }
}

ReservationRoom.propTypes = {
  title: string,
  photo: object,
  startDate: string,
  endDate: string,
  price: oneOfType([string, number]),
  handleEditRoom: func,
};
