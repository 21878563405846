// ES6 polyfill.
import 'core-js/stable';
// `async/await` support.
import 'regenerator-runtime/runtime';

import React from 'react';
import { hydrate, render } from 'react-dom';
import { createBrowserHistory as createHistory } from 'history';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { Root } from './components';
import { ApiClient } from './helpers';
import getRoutes from './routes/routes';
import configureStore from './redux/store';
import { sentryReactDsn } from '../config/config';

import './styles/layout/_base.scss';

if (sentryReactDsn) {
  Sentry.init({
    dsn: sentryReactDsn,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// https://developer.mozilla.org/en-US/docs/Web/API/History/scrollRestoration
if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual';
}
const client = new ApiClient();
const initialState = window.INITIAL_STATE;
const history = createHistory();
const store = configureStore(history, client, initialState);
const dest = document.getElementById('root');
const renderRoot = process.env.NODE_ENV === 'development' ? render : hydrate;

renderRoot(<Root history={history} routes={getRoutes(store)} store={store} />, dest);
