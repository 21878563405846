/*
  Stores static booking form data (not loaded from the API).
*/
import { createSelector } from 'reselect';

const STATE_KEY = 'bookingForm';

const SET_PARAMS = `${STATE_KEY}/SET_PARAMS`;

const SET_STEP = `${STATE_KEY}/SET_STEP`;

const CLEAR = `${STATE_KEY}/CLEAR`;

const storeState = function (state) {
  localStorage.setItem('reducerBookingForm', JSON.stringify(state));
};

/**
 * route funcs might be temp.
 * TODO: remove them after/if normal router
 */
const getRoute = function (activeStep, steps, params = {}) {
  if (params.isFinalStep) {
    return '/booking-confirmation';
  }
  const activeSubStep = steps[activeStep].subSteps.active;

  switch (`${activeStep}${activeSubStep}`) {
    case '11':
      return '/';
    case '21':
      return '/select-room';
    case '22':
      return '/add-experiences';
    case '31':
      return '/reservation-summary';
    case '41':
      return '/my-info';
    case '42':
      return '/payment-info';
    default:
      return '';
  }
};

export const setRoute = function (activeStep, steps, params = {}) {
  const route = getRoute(activeStep, steps, params);

  if (route) {
    window.history.replaceState({}, '', route);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Pageview',
        page: route,
      });
    }
  }
};

export const clearRoute = function () {
  const {
    location: { pathname },
  } = window;

  if (pathname !== '/') {
    window.history.replaceState({}, '', '/');

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'Pageview',
        page: '/',
      });
    }
  }
};

/**
 * STEPS:
 * 1.1 - date step
 * 2.1 - rooms list
 * 2.2 - add-ons list
 * 3.1 - summary with reservations list
 * 4.1 - my info
 * 4.2 - payment info
 * 4.3 - confirmations
 *
 * export for src/server/SSR/createSSR.js
 */
export const initialState = {
  steps: {
    1: {
      name: 'Date',
      subSteps: { active: 1, max: 1 },
    },
    2: {
      name: 'Accomodations',
      subSteps: { active: 1, max: 2 },
    },
    3: {
      name: 'Summary', // name of the component is Reservations
      subSteps: { active: 1, max: 1 },
    },
    4: {
      name: 'Checkout',
      subSteps: { active: 1, max: 3 },
    },
  },
  activeStep: 1,
  finishedStep: null,
  isFinalStep: false,
  isAnimatingProgress: false,
  /**
   * first initial isLoaderVisible passed from src/server/SSR/createSSR.js
   * see comments there for more info
   */
  isLoaderVisible: false,
};

/*
  standard reducer Handling Actions
  In Redux, all the application state is stored as a single object.
  The reducer is a pure function that takes the previous state and an action, and returns the next state.
  It should calculate the next state and return it (according to the action.type).

  eg. setBookingFormParams function (that has defined action type SET_PARAMS) is called
  case SET_PARAMS is triggered and state is updated with new state (via setBookingFormParams func)
  note: { ...state, ...nextObject } is the same as Object.assign({}, state, nextObject) - just new syntax

  for more info please see
  https://redux.js.org/basics/actions
  https://redux.js.org/basics/reducers
*/
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PARAMS:
      return { ...state, ...action.params };
    case SET_STEP: {
      const { activeStep: prevActiveStep, finishedStep: prevFinishedStep } = state;
      const { activeStep, activeSubStep, finishedStep } = action;

      const nextActiveStep = activeStep || prevActiveStep;
      // finished step can only be increased
      // or it should be set with setBookingFormParams
      const nextFinishedStep = finishedStep && finishedStep > prevFinishedStep ? finishedStep : prevFinishedStep;
      let nextSteps = state.steps;

      if (activeSubStep) {
        const curStep = nextSteps[nextActiveStep];

        nextSteps = {
          ...nextSteps,
          [nextActiveStep]: {
            ...curStep,
            subSteps: { ...curStep.subSteps, active: activeSubStep },
          },
        };
      }

      const nextState = {
        ...state,
        activeStep: nextActiveStep,
        finishedStep: nextFinishedStep,
        steps: nextSteps,
      };

      storeState(nextState);
      setRoute(nextActiveStep, nextSteps);

      return nextState;
    }
    case CLEAR:
      /**
       * do not clear the route when it's final step
       * 'case all the data is reset but the route shouldn't be cleared
       * might be changed later
       */
      if (!state.isFinalStep) clearRoute();

      return { ...state, ...initialState, ...action.excludeData };
    default:
      return state;
  }
}

// standard reducer actions goes below
export function setBookingFormParams(params = {}) {
  return { type: SET_PARAMS, params };
}

export function setStep(activeStep, activeSubStep, finishedStep) {
  return { type: SET_STEP, activeStep, activeSubStep, finishedStep };
}

export function clear(excludeData = {}) {
  return { type: CLEAR, excludeData };
}

// selectors
const getState = (state) => state[STATE_KEY];

export const getIsFinalStep = createSelector(getState, (state) => state.isFinalStep);
export const getActiveStep = createSelector(getState, (state) => state.activeStep);
export const getFinishedStep = createSelector(getState, (state) => state.finishedStep);
export const getIsLoaderVisible = createSelector(getState, (state) => state.isLoaderVisible);
export const getSteps = createSelector(getState, (state) => state.steps);
export const getIsAnimatingProgress = createSelector(getState, (state) => state.isAnimatingProgress);
