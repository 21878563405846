import React, { Component } from 'react';
import { oneOfType, string, object, node } from 'prop-types';
import { Field } from 'redux-form';
import cx from 'classnames';
import { FormField } from '..';

import './FormInput.module.scss';

class Input extends Component { // eslint-disable-line
  render() {
    const { classNameField, className, placeholder, type, label, meta, input, ...rest } = this.props;
    const { name } = input;

    return (
      <FormField className={classNameField} name={name} meta={meta} label={label}>
        <input
          className={cx('form-input', {
            [className]: !!className,
          })}
          {...input}
          {...rest}
          name={name}
          id={name}
          placeholder={placeholder}
          type={type}
        />
      </FormField>
    );
  }
}

Input.propTypes = {
  classNameWrapper: string,
  classNameField: string,
  className: string,
  placeholder: string,
  label: oneOfType([string, node]),
  type: string,
  input: object,
  meta: object,
};

Input.defaultProps = {
  placeholder: '',
  type: 'text',
};

export default (props) => <Field {...props} component={Input} />;
