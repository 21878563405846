import React from 'react';
import { string } from 'prop-types';

import './FormFieldError.module.scss';

function FormFieldError({ text }) {
  return <div className="form-field__error">{text}</div>;
}

FormFieldError.propTypes = {
  text: string,
};

export default FormFieldError;
