/*
  Renders modal and modal content according to active type.
*/
import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { setModalParams, getBookingFormModalData } from '../../redux/modules/modals';
import { setStep } from '../../redux/modules/bookingForm';
import { Modal } from '../../elements';
import { clearSessionStorage, resetSessionReducers } from '../../utils/sessionActions';

import * as Components from './components';

const mapStateToProps = (state) => ({
  bookingFormModalData: getBookingFormModalData(state),
});

const mapDispatchToProps = {
  setModalParams,
  setStep,
};

class BookingFormModal extends Component {
  /**
   * callback for <Modal> component
   * fires when modal close event is triggered (esc key, click on the close button, etc)
   *
   * @callback <Modal>
   */
  closeModal = () => {
    const { setModalParams, bookingFormModalData } = this.props; // eslint-disable-line no-shadow
    bookingFormModalData?.onClose?.();
    setModalParams({ bookingFormModalData: {} });
  };

  /**
   * get invoked when the new search button is clicked or when the session is expired
   * @callback <NewSearch>
   * @callback <SessionExpired>
   */
  resetAllData = () => {
    resetSessionReducers();
    this.closeModal();

    clearSessionStorage();
    window.scrollTo(0, 0);
  };

  /**
   * renders modal content according to the set type of the modal
   */
  renderModalContent() {
    const { bookingFormModalData, setStep } = this.props; // eslint-disable-line no-shadow
    const Empty = () => null;

    switch (bookingFormModalData.type) {
      case 'new search':
        return <Components.NewSearch resetAllData={this.resetAllData} />;
      case 'session expired':
        return <Components.SessionExpired resetAllData={this.resetAllData} />;
      case 'addons agreement':
        return <Components.AddonsAgreement setStep={setStep} />;
      case 'terms conditions':
        return <Components.TermsConditions setStep={setStep} />;
      case 'service error':
        return <Components.ServiceError />;
      case 'payment error':
        return <Components.PaymentError closeModal={this.closeModal} />;
      case 'alert':
        return <Components.Alert {...bookingFormModalData.data} />;
      default:
        return <Empty />;
    }
  }

  render() {
    const { bookingFormModalData } = this.props;

    const modalType = bookingFormModalData.type;
    const modalHyphenatedType = modalType && modalType.replace(/ /g, '-');

    if (!modalType) return null;

    return (
      <Modal
        className={`modal--booking-form modal--${modalHyphenatedType}`}
        opened={!!modalType}
        handleClose={this.closeModal}
        disableClickClose={bookingFormModalData.disableClickClose}
        disableClose={bookingFormModalData.disableClose}
        innerButtonClose
      >
        {this.renderModalContent()}
      </Modal>
    );
  }
}

BookingFormModal.propTypes = {
  setModalParams: func,
  bookingFormModalData: object,
  setStep: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingFormModal);
