import React, { Component } from 'react';
import { oneOfType, string, func, bool, node } from 'prop-types';
import cx from 'classnames';

import './Confirm.module.scss';

export default class Confirm extends Component {
  componentDidMount() {
    const { disableKeydown } = this.props;
    const { activeElement } = document;

    // prevents missclick when the confirm is opened
    if (activeElement) {
      activeElement.blur();
    }

    !disableKeydown && document.addEventListener('keydown', this.handleKeydown); // eslint-disable-line
  }

  componentWillUnmount() {
    const { disableKeydown } = this.props;

    !disableKeydown && document.removeEventListener('keydown', this.handleKeydown); // eslint-disable-line
  }

  handleKeydown = (e) => {
    if (e.keyCode === 13) {
      this.triggerDefaultBtn();
    }
  };

  handleConfirm = () => {
    const { onConfirm, closeModal, disableCloseModal } = this.props;

    onConfirm();
    if (closeModal && !disableCloseModal) closeModal();
  };

  triggerDefaultBtn() {
    const { defaultBtnOnEnter, onCancel } = this.props;

    if (defaultBtnOnEnter === 'confirm') {
      this.handleConfirm();
    } else {
      onCancel();
    }
  }

  render() {
    const {
      className,
      title,
      description,
      htmlDescription,
      confirmText,
      cancelText,
      onCancel,
      isAlert,
      isScrollable,
      disabled,
    } = this.props;

    return (
      <div
        className={cx('confirm', {
          [className]: !!className,
        })}
      >
        <div
          className={cx('confirm__scroll', {
            'js-scrollable-content': isScrollable,
          })}
        >
          <h3 className="confirm__title">{title}</h3>
          {description && <div className="confirm__descr">{description}</div>}
          {htmlDescription && (
            <div
              className="confirm__descr confirm__descr--html"
              dangerouslySetInnerHTML={{ __html: htmlDescription }} // eslint-disable-line
            />
          )}
        </div>
        <div className="confirm__buttons">
          {!isAlert && (
            <button className="confirm__btn-cancel" type="button" onClick={onCancel} disabled={disabled}>
              <span>{cancelText}</span>
            </button>
          )}
          <button className="confirm__btn-confirm" type="button" onClick={this.handleConfirm} disabled={disabled}>
            <span>{confirmText}</span>
          </button>
        </div>
      </div>
    );
  }
}

Confirm.defaultProps = {
  title: 'Confirm action',
  confirmText: 'OK',
  cancelText: 'Cancel',
  defaultBtnOnEnter: 'confirm',
  disableKeydown: true,
  disableCloseModal: true,
};

Confirm.propTypes = {
  className: string,
  title: oneOfType([string, node]),
  description: oneOfType([string, node]),
  htmlDescription: string,
  confirmText: oneOfType([string, node]),
  cancelText: oneOfType([string, node]),
  defaultBtnOnEnter: string,
  onConfirm: func.isRequired,
  onCancel: func,
  closeModal: func,
  isAlert: bool,
  isScrollable: bool,
  disabled: bool,
  disableKeydown: bool,
  disableCloseModal: bool,
};
