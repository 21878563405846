import React, { PureComponent } from 'react';
import { func, object } from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { DayPickerRangeController } from 'react-dates';
import { HORIZONTAL_ORIENTATION, END_DATE } from 'react-dates/lib/constants';
import { Waypoint } from 'react-waypoint';

const DATE_FORMAT = 'YYYY-MM-DD';
const DAY_FORMAT = 'D';
const WAYPOINT_ANCESTOR = typeof window !== 'undefined' && window;

export default class DateRangePickerController extends PureComponent {
  constructor(props) {
    super(props);
    const {
      pickerProps: { startDate, endDate },
    } = props;

    this._rangeControllerWrapper = null;
    this._btnNext = null;
    this._prevStartDate = null;
    this._prevMomentObject = null;
    this._isInitialSelectedDates = !!(startDate && endDate);

    this.updateTodayData();

    // https://github.com/airbnb/react-dates/issues/1102
    this.renderDayContents = this.renderDayContentsFactory();
    this.isDayBlocked = this.isDayBlockedFactory(props.pickerProps);
  }

  componentDidMount() {
    const { onMount } = this.props;
    const datesRange = this.getInitialDatesRange();

    onMount(datesRange);
  }

  UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line
    if (JSON.stringify(this.props.availableDates) !== JSON.stringify(nextProps.availableDates)) {
      this.renderDayContents = this.renderDayContentsFactory();
      this.isDayBlocked = this.isDayBlockedFactory(nextProps.pickerProps);
    }
    if (this.props.pickerProps.startDate !== nextProps.pickerProps.startDate) {
      this.isDayBlocked = this.isDayBlockedFactory(nextProps.pickerProps);
    }
  }

  componentWillUnmount() {
    window._visibleMonths = [];
  }

  getPickerProps() {
    return { ...this.props.defaultPickerProps, ...this.props.pickerProps };
  }

  /*
    to get initial dates range when picked is initialized
  */
  getInitialDatesRange() {
    const { numberOfMonths } = this.getPickerProps();
    const momentObject = moment();
    // number of month showed in the calendar minus current month
    // if today is Sep 3 and numberOfMonths === 2
    // last visible month will be Oct
    const endMonthNum = numberOfMonths - 1;
    const startDate = momentObject.format(DATE_FORMAT);
    const endDate = momentObject.add(endMonthNum, 'months').endOf('month').format(DATE_FORMAT);

    this._prevMomentObject = moment(momentObject);

    return {
      dateFormat: DATE_FORMAT,
      momentObject: moment(momentObject),
      startDate,
      endDate,
    };
  }

  /**
   * get month(s) range of startDate and endDate due to passed moment object and numberOfMonths prop
   * @param {object} momentObject - moment object
   */
  getDatesRange(momentObject) {
    const _momentObject = moment(momentObject);
    const { numberOfMonths } = this.getPickerProps();

    const endMonthNum = numberOfMonths - 1;
    const startDate = _momentObject.add(1, 'months').startOf('month').format(DATE_FORMAT);
    const endDate = _momentObject.add(endMonthNum, 'months').endOf('month').format(DATE_FORMAT);

    this._prevMomentObject = _momentObject;

    return {
      dateFormat: DATE_FORMAT,
      momentObject: moment(_momentObject),
      startDate,
      endDate,
    };
  }

  /*
   clears the selection that stored in rangeController reducer
   */
  clearSelection = () => {
    this.props.setDates(null, null);
  };

  /*
    triggers when date changes
    and checks if range has blocked dates
    and call whether successful cb (onDatesChange) or unsuccessful (onDatesChangeBlocked)
  */
  handleDatesChange = async ({ startDate, endDate }) => {
    const { setDates, onDatesChange, onDatesChangeBlocked, setFocusedInput } = this.props; // eslint-disable-line no-shadow

    /*
      The calendar should automatically redirect to next step
      when the startDate and endDate are selected.

      When the user returns to the step with calendar
      he should be able to select new dates so
      if there are previously selected dates
      first date selection should always be the new dates range.
    */
    if (this._isInitialSelectedDates) {
      setDates(startDate, null);
      setFocusedInput(END_DATE);
      onDatesChange(startDate, null);

      this._isInitialSelectedDates = false;
      return;
    }

    const withBlockedDays = startDate && endDate && this.checkForBlockedDays(startDate, endDate);

    // react-dates has strange issue with some classnames left
    // when preventing click on range with blocked dates
    // so wait until dates are set anyway and only then check if there are blocked days
    await this.props.setDates(startDate, endDate);

    if (withBlockedDays) {
      if (startDate < this._prevStartDate) {
        setFocusedInput(null);
      }

      this.clearSelection();
      onDatesChangeBlocked(withBlockedDays);
    } else {
      onDatesChange(startDate, endDate);
    }

    this._prevStartDate = startDate;
  };

  /**
   * gets invoked when the next month button is in viewport
   * @callback <Waypoint>
   */
  handleNavNextEnter = () => {
    /**
     * Prevents redundant request and month render while animating (transition-group)
     * src/components/BookingForm/BookingFormTransitions.js
     */
    const isAnimating = document.body.classList.contains('transition-group-enter');
    if (isAnimating) return;

    // const diffMonths = moment(this._prevMomentObject).diff(moment(), 'months');
    const diffMonths = moment(this._prevMomentObject).startOf('month').diff(moment().startOf('month'), 'months');

    // max months should be rendered are 12,
    // but since react-dates numberOfMonths is set to two months, it will render 2 extra months,
    // so diffMonths should be >= 11
    if (diffMonths >= 11) {
      return;
    }

    this._btnNext.click();
  };

  handleNavNextClick = () => {
    const { onMonthAdded } = this.props;
    const datesRange = this.getDatesRange(this._prevMomentObject);

    onMonthAdded(datesRange);
  };

  handleMonthEnter = (data) => () => {
    if (!window._visibleMonths) {
      window._visibleMonths = [];
    }

    window._visibleMonths.push(data);
  };

  handleMonthLeave = (id) => () => {
    if (!window._visibleMonths) {
      window._visibleMonths = [];
    }

    window._visibleMonths = window._visibleMonths.filter((c) => c.id !== id);
  };

  // a little bit modified: https://github.com/airbnb/react-dates/issues/23#issuecomment-294043371
  /**
   * checks whether or not selected range has blocked or promotion dates
   * @param {object} start - moment object start date
   * @param {object} end - moment object end date
   * @returns {object}
   */
  // TODO: check and fix, if need, this method
  checkForBlockedDays(start, end) {
    const { availableDates } = this.props;
    const diffNights = moment(end).diff(start, 'days');

    for (let i = 0; i < diffNights; i++) {
      const curDate = moment(start).add(i, 'd').format(DATE_FORMAT);
      const curAvailable = availableDates[curDate] || {};

      if (diffNights < curAvailable.restrictionValue) {
        return {
          type: 'required nights',
          selectedNights: diffNights,
          requiredNights: curAvailable.restrictionValue,
        };
      }

      if (typeof curAvailable.minAllRoomsPrice !== 'number' || curAvailable.totalRooms === 0) {
        return { type: 'blocked' };
      }
    }

    return false;
  }

  // https://github.com/airbnb/react-dates/issues/1102
  // method to block days according to conditions
  // TODO: check and fix, if need, this method
  isDayBlockedFactory(pickerProps = {}) {
    const { startDate } = pickerProps;
    const checkDayBlocked = (dayData) => {
      const _dayData = dayData || {};
      // while data is loading roomsAvailable is undefined so just return true
      // if roomsAvailable is 0 - return true due to no available rooms or false if roomsAvailable is higher than 0
      // !0 is true !anyOtherNumberHigherZero is false
      return typeof _dayData.minAllRoomsPrice !== 'number' || _dayData.totalRooms === 0;
    };
    let firstDateBlockedAfterStartDate;

    return (day) => {
      const { availableDates } = this.props;
      const formattedDate = day.format(DATE_FORMAT);
      const curDayData = availableDates[formattedDate] || {};
      const isDayBlocked = checkDayBlocked(curDayData);

      /**
       * when start date is selected it's necessary to extend available days for one more day
       * so that the user can select departure date.
       * @example
       * let's say 2020-09-16 is available and 2020-09-17 is blocked
       * and the user selects 2020-09-16
       * then he should be able to select next day (2020-09-17) 'cause it's departure date
       * NOTE. specific date should be saved due to some react-dates rerendering
       */
      if (startDate) {
        if (!firstDateBlockedAfterStartDate && isDayBlocked && day > startDate) {
          firstDateBlockedAfterStartDate = formattedDate;
        }
        if (firstDateBlockedAfterStartDate === formattedDate) {
          return false;
        }
      }

      return isDayBlocked;
    };
  }

  /*
    updates today's data
  */
  updateTodayData() {
    const momentObject = moment();

    this._todayDay = momentObject.format(DAY_FORMAT);
  }

  renderMonthElement = ({ month }) => {
    const id = `${month.month()}_${month.year()}`;
    const ts = month.format('x');
    const caption = month.format('MMMM YYYY');

    return (
      <Waypoint
        scrollableAncestor={WAYPOINT_ANCESTOR}
        onEnter={this.handleMonthEnter({ id, ts, caption })}
        onLeave={this.handleMonthLeave(id)}
      >
        <strong data-caption-id={id}>{caption}</strong>
      </Waypoint>
    );
  };

  // https://github.com/airbnb/react-dates/issues/1102
  /*
    renders custom content to react-dates day
  */
  renderDayContentsFactory() {
    const todayDateFormatted = moment().format(DATE_FORMAT);

    return (day) => {
      const { availableDates } = this.props;

      const formattedDate = day.format(DATE_FORMAT);
      const formattedDay = day.format(DAY_FORMAT);
      const curDayData = availableDates[formattedDate] || {};
      const { /* roomsAvailable, */ minAllRoomsPrice } = curDayData;

      return (
        <div
          className={cx('range-controller__day', {
            'range-controller__day--today': formattedDate === todayDateFormatted,
            // 'range-controller__day-unavailable': roomsAvailable === 0
          })}
          style={{ height: '100%' }}
        >
          <div className="range-controller__day-num">{formattedDay}</div>
          {typeof minAllRoomsPrice === 'number' && (
            <div className="range-controller__day-price">{`$${minAllRoomsPrice}`}</div>
          )}
        </div>
      );
    };
  }

  render() {
    const { setFocusedInput } = this.props;

    return (
      <div
        className="range-controller"
        ref={(n) => {
          this._rangeControllerWrapper = n;
        }}
      >
        <DayPickerRangeController
          {...this.getPickerProps()}
          onDatesChange={this.handleDatesChange}
          onFocusChange={setFocusedInput}
          renderMonthElement={this.renderMonthElement}
          renderDayContents={this.renderDayContents}
          isDayBlocked={this.isDayBlocked}
          navNext={
            <Waypoint scrollableAncestor={WAYPOINT_ANCESTOR} onEnter={this.handleNavNextEnter}>
              <div
                ref={(n) => {
                  this._btnNext = n;
                }}
                className="range-controller__next"
                onClick={this.handleNavNextClick}
              />
            </Waypoint>
          }
        />
      </div>
    );
  }
}

DateRangePickerController.propTypes = {
  availableDates: object,
  pickerProps: object,
  defaultPickerProps: object,
  renderDayContents: func,
  onDatesChange: func,
  onMonthAdded: func,
  onDatesChangeBlocked: func,
  setDates: func.isRequired,
  setFocusedInput: func.isRequired,
  onMount: func,
};

DateRangePickerController.defaultProps = {
  defaultPickerProps: {
    minimumNights: 1,
    numberOfMonths: 2,
    isDayBlocked: () => false,
    enableOutsideDays: false,
    orientation: HORIZONTAL_ORIENTATION,
    withPortal: false,
    keepOpenOnDateSelect: false,
    hideKeyboardShortcutsPanel: true,
    monthFormat: 'MMMM YYYY',
  },
  pickerProps: {},
  onDatesChange() {},
  onDatesChangeBlocked() {},
  onMount() {},
  onMonthAdded() {},
};
