import React from 'react';
import { string } from 'prop-types';
import cx from 'classnames';

import styles from './Loader.module.scss';

function Loader({ className }) {
  return (
    <div
      className={cx(styles.loader, {
        [styles[className]]: !!className,
      })}
    >
      <div className={styles.loader__container}>
        <div className={styles.object} />
        <div className={styles.object} />
        <div className={styles.object} />
        <div className={styles.object} />
        <div className={styles.object} />
        <div className={styles.object} />
        <div className={styles.object} />
        <div className={styles.object} />
        <div className={cx(styles.object, styles['object--big'])} />
      </div>
    </div>
  );
}

Loader.propTypes = {
  className: string,
};

export default Loader;
