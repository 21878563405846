/*
  renders error content when the user is trying to pay by visa
  or returns error
*/
import React from 'react';
import { func } from 'prop-types';
import { Confirm } from '../../../elements';

function PaymentError({ closeModal }) {
  const onConfirm = () => {
    closeModal?.();
  };

  return (
    <div className="modal__note">
      <Confirm
        title={
          <>
            <span>important note</span>
            <span className="modal__icon-info" />
          </>
        }
        description={
          <span>
            Sorry, there was an issue with our system. Your reservation may have been processed. Please call{' '}
            <a href="tel:818-575-4400">818-575-4400</a> to confirm your reservation.
            <br />
            <br />
            We are working to resolve this issue. Sorry for the inconvenience!
          </span>
        }
        confirmText="close"
        onConfirm={onConfirm}
        isAlert
      />
    </div>
  );
}

PaymentError.propTypes = {
  closeModal: func,
};

export default PaymentError;
