/*
  Loader for AddonsList.
  Shows when add-ons are loading.
*/
import React from 'react';
import { number, string } from 'prop-types';
import ContentLoader from 'react-content-loader';

function AddonsListLoader({ width, height, title }) {
  return (
    <ContentLoader
      width="100%"
      height="100%"
      viewBox={`0 0 ${width} ${height}`}
      title={title}
      backgroundColor="rgb(0,0,0)"
      foregroundColor="rgb(0,0,0)"
      backgroundOpacity={0.06}
      foregroundOpacity={0.12}
    >
      <rect x="0" y="0" width="504" height="340" />
      <rect x="568" y="0" width="504" height="340" />
      <rect x="1136" y="0" width="504" height="340" />
      <rect x="0" y="0" width="504" height="340" transform="translate(0.000000, 411.000000)" />
      <rect x="568" y="0" width="504" height="340" transform="translate(0.000000, 411.000000)" />
      <rect x="1136" y="0" width="504" height="340" transform="translate(0.000000, 411.000000)" />
    </ContentLoader>
  );
}

AddonsListLoader.defaultProps = {
  width: 1640,
  height: 751,
  title: 'Loading add-ons...',
};

AddonsListLoader.propTypes = {
  width: number,
  height: number,
  title: string,
};

export default AddonsListLoader;
