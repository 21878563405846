/*
  Stores data that loaded from the API.
*/
import { createSelector } from 'reselect';
import { testMode, operaHotelCode, operaChainCode } from '../../../config/config';
import { getIn, createInitialStatePart, setReducerState, getResultErrorResponse } from '../../utils/helpers';
import { getUserSessionAddons } from './session';
import roomsData from '../../components/AccommodationsList/accommodations.yaml';

const STATE_KEY = 'bookingFormData';

const LOAD_AVAILABLE_DATES = `${STATE_KEY}/LOAD_AVAILABLE_DATES`;
const LOAD_AVAILABLE_DATES_SUCCESS = `${STATE_KEY}/LOAD_AVAILABLE_DATES_SUCCESS`;
const LOAD_AVAILABLE_DATES_FAIL = `${STATE_KEY}/LOAD_AVAILABLE_DATES_FAIL`;

const LOAD_ACCOMMODATIONS = `${STATE_KEY}/LOAD_ACCOMMODATIONS`;
const LOAD_ACCOMMODATIONS_SUCCESS = `${STATE_KEY}/LOAD_ACCOMMODATIONS_SUCCESS`;
const LOAD_ACCOMMODATIONS_FAIL = `${STATE_KEY}/LOAD_ACCOMMODATIONS_FAIL`;

const LOAD_ADDONS = `${STATE_KEY}/LOAD_ADDONS`;
const LOAD_ADDONS_SUCCESS = `${STATE_KEY}/LOAD_ADDONS_SUCCESS`;
const LOAD_ADDONS_FAIL = `${STATE_KEY}/LOAD_ADDONS_FAIL`;

const CLEAR_ACCOMMODATIONS = `${STATE_KEY}/CLEAR_ACCOMMODATIONS`;

const CLEAR_ADDONS = `${STATE_KEY}/CLEAR_ADDONS`;

const CLEAR_ALL_BUT_DATES = `${STATE_KEY}/CLEAR_ALL_BUT_DATES`;

const CLEAR = `${STATE_KEY}/CLEAR`;

const initialState = {
  ...createInitialStatePart('availableDates'),
  ...createInitialStatePart('accommodations', []),
  ...createInitialStatePart('addons', []),
};

/**
 * parse values from all available rooms
 * @param {rooms} rooms - all rooms
 * @returns {object}
 */
function getAllRoomValues(rooms) {
  if (!rooms) return {};

  const data = {};
  const allPrices = [];
  const roomsLength = rooms.length;

  for (let i = 0; i < roomsLength; i++) {
    const c = rooms[i];
    const rate = +getIn(c, ['Rates', '0', 'Rate', '0', 'Base', '0', '_']);
    const roomId = getIn(c, ['$', 'roomTypeCode']);

    if (typeof rate !== 'number') {
      continue; // eslint-disable-line
    }

    if (testMode === 'true') {
      const testModeData = roomsData.rooms.find((room) => room.test_id === roomId);
      testModeData && testModeData.test_id && allPrices.push(rate); // eslint-disable-line
    } else {
      const nonTestModeData = roomsData.rooms.find((room) => room.id === roomId);
      nonTestModeData && nonTestModeData.id && allPrices.push(rate); // eslint-disable-line
    }
  }

  if (allPrices.length) {
    data.minAllRoomsPrice = Math.min(...allPrices);
  }

  return data;
}

/**
 * prepares and converts data to object
 * gets rid of redundant data and returns only the necessary data to work with
 * @param {array} dates - all dates from the API
 * @returns {object}
 */
function parseAvailableDates(dates) {
  if (!dates) return {};

  return dates.reduce((acc, c) => {
    const date = c.$.Date;
    const allRoomValues = getAllRoomValues(getIn(c, ['Rates', '0', 'RateList', '0', 'Rate']));
    const roomRestriction = getIn(c, ['Rates', '0', 'RestrictionList', '0', 'Restriction']) || [];

    const filteredRoomRestriction =
      roomRestriction?.filter((restriction) => {
        const roomType = getIn(restriction, ['$', 'roomType']);
        if (testMode !== 'true' && roomType.indexOf('TEST') === -1) {
          return true;
        }
        return false;
      }) || [];

    const findRestrictionValue = filteredRoomRestriction.find((restriction) => {
      let numberOfDays = 0;
      const restrictionType = getIn(restriction, ['$', 'restrictionType']);
      if (
        restrictionType.indexOf('MINIMUM_STAY_THROUGH') > -1 ||
        restrictionType.indexOf('MINIMUM_LENGTH_OF_STAY') > -1
      ) {
        numberOfDays = +getIn(restriction, ['$', 'numberOfDays']) || 0;
      }
      return numberOfDays > 0;
    });

    const restrictionValue = +getIn(findRestrictionValue, ['$', 'numberOfDays']) || 0;

    const roomTypeInventory = getIn(c, ['Occupancy', '0', 'RoomTypeInventory']) || [];
    const totalRooms = roomTypeInventory.reduce((accumulator, currentValue) => {
      const value = getIn(currentValue, ['$']);
      const total = +getIn(value, ['totalAvailableRooms']) || 0;
      return accumulator + total;
    }, 0);

    acc[date] = {
      date,
      ...allRoomValues,
      restrictionValue,
      totalRooms,
    };
    return acc;
  }, {});
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_AVAILABLE_DATES:
      return setReducerState('loading', 'availableDates', { state });
    case LOAD_AVAILABLE_DATES_SUCCESS: {
      const parsedDates = parseAvailableDates(
        getIn(action.result, ['result', '0', 'FetchCalendarResponse', '0', 'Calendar', '0', 'CalendarDay'])
      );
      return setReducerState('loaded', 'availableDates', {
        state,
        data: { ...state.availableDates, ...parsedDates },
      });
    }
    case LOAD_AVAILABLE_DATES_FAIL:
      return setReducerState('error', 'availableDates', { state, action });
    case LOAD_ACCOMMODATIONS:
      return setReducerState('loading', 'accommodations', { state });
    case LOAD_ACCOMMODATIONS_SUCCESS: {
      const list = getIn(action.result, [
        'result',
        '0',
        'AvailabilityResponse',
        '0',
        'AvailResponseSegments',
        '0',
        'a:AvailResponseSegment',
        '0',
        'a:RoomStayList',
        '0',
        'hc:RoomStay',
      ]);
      return setReducerState('loaded', 'accommodations', {
        state,
        data: list,
      });
    }
    case LOAD_ACCOMMODATIONS_FAIL:
      return setReducerState('error', 'accommodations', { state, action });
    case LOAD_ADDONS:
      return setReducerState('loading', 'addons', { state });
    case LOAD_ADDONS_SUCCESS: {
      const { result } = action;
      const error = getResultErrorResponse(result, 'FetchAvailablePackagesResponse');
      if (error) {
        return setReducerState('error', 'addons', { state, error });
      }

      const fetchPackages = getIn(result, 'result.0.FetchAvailablePackagesResponse');
      return setReducerState('loaded', 'addons', { state, data: fetchPackages });
    }
    case LOAD_ADDONS_FAIL:
      return setReducerState('error', 'addons', { state, action });
    case CLEAR_ACCOMMODATIONS:
      return { ...state, ...createInitialStatePart('accommodations', []) };
    case CLEAR_ADDONS:
      return { ...state, ...createInitialStatePart('addons', []) };
    case CLEAR_ALL_BUT_DATES:
      return { ...state, ...initialState, availableDates: state.availableDates };
    case CLEAR:
      return { ...state, ...initialState };
    default:
      return state;
  }
}

export function loadAvailableDates(datesRange = {}) {
  const data = {
    FetchCalendarRequest: {
      $: {
        'xmlns:a': 'http://webservices.micros.com/og/4.3/Availability/',
        'xmlns:hc': 'http://webservices.micros.com/og/4.3/HotelCommon/',
        xmlns: 'http://webservices.micros.com/ows/5.1/Availability.wsdl',
      },
      HotelReference: {
        $: {
          hotelCode: operaHotelCode,
          chainCode: operaChainCode,
        },
      },
      StayDateRange: {
        'hc:StartDate': {
          _: datesRange.startDate,
        },
        'hc:EndDate': {
          _: datesRange.endDate,
        },
      },
      GuestCounts: {
        'hc:GuestCount': {
          $: {
            ageQualifyingCode: 'ADULT',
            count: '1',
          },
        },
        // 'hc:GuestCount': {
        //   $: {
        //     ageQualifyingCode: 'CHILD',
        //     count: '0'
        //   }
        // }
      },
      RoomTypeCode: {
        _: 'ALL',
      },
    },
  };

  return {
    types: [LOAD_AVAILABLE_DATES, LOAD_AVAILABLE_DATES_SUCCESS, LOAD_AVAILABLE_DATES_FAIL],
    promise: (client) => client.post('/api/availability', { data }),
  };
}

export function loadAccommodations(datesRange = {}) {
  const data = {
    AvailabilityRequest: {
      $: {
        'xmlns:a': 'http://webservices.micros.com/og/4.3/Availability/',
        'xmlns:hc': 'http://webservices.micros.com/og/4.3/HotelCommon/',
        summaryOnly: 'true',
        xmlns: 'http://webservices.micros.com/ows/5.1/Availability.wsdl',
      },
      'a:AvailRequestSegment': {
        $: {
          availReqType: 'Room',
          numberOfRooms: '1',
          totalNumberOfGuests: '1',
          totalNumberOfChildren: '0',
        },
        'a:StayDateRange': {
          'hc:StartDate': {
            _: datesRange.arrivalDate,
          },
          'hc:EndDate': {
            _: datesRange.departureDate,
          },
        },
        'a:RatePlanCandidates': {
          'a:RatePlanCandidate': {
            $: {
              ratePlanCode: 'RACK',
            },
          },
        },
        'a:HotelSearchCriteria': {
          'a:Criterion': {
            'a:HotelRef': {
              $: {
                hotelCode: operaHotelCode,
                chainCode: operaChainCode,
              },
            },
          },
        },
      },
    },
  };

  return {
    types: [LOAD_ACCOMMODATIONS, LOAD_ACCOMMODATIONS_SUCCESS, LOAD_ACCOMMODATIONS_FAIL],
    promise: (client) => client.post('/api/availability', { data }),
  };
}

export function loadAddons(datesRange = {}) {
  const data = {
    FetchAvailablePackagesRequest: {
      $: {
        'xmlns:a': 'http://webservices.micros.com/og/4.3/Availability/',
        'xmlns:hc': 'http://webservices.micros.com/og/4.3/HotelCommon/',
        xmlns: 'http://webservices.micros.com/ows/5.1/Availability.wsdl',
      },
      HotelReference: {
        $: {
          hotelCode: operaHotelCode,
          chainCode: operaChainCode,
        },
      },
      StayDateRange: {
        'hc:StartDate': {
          _: datesRange.arrivalDate,
        },
        'hc:EndDate': {
          _: datesRange.departureDate,
        },
      },
      NumberOfRooms: {
        _: 1,
      },
      NumberOfAdults: {
        _: 1,
      },
      NumberOfChildren: {
        _: 0,
      },
    },
  };

  return {
    types: [LOAD_ADDONS, LOAD_ADDONS_SUCCESS, LOAD_ADDONS_FAIL],
    promise: (client) => client.post('/api/availability', { data }),
  };
}

export function clearAccommodations() {
  return { type: CLEAR_ACCOMMODATIONS };
}

export function clearAddons() {
  return { type: CLEAR_ADDONS };
}

export function clearAllButDates() {
  return { type: CLEAR_ALL_BUT_DATES };
}

export function clear() {
  return { type: CLEAR };
}

// selectors
const getState = (state) => state[STATE_KEY];

export const getAddons = createSelector(getState, (state) => state.addons || []);
export const getAccommodationsLoaded = createSelector(getState, (state) => state.accommodationsLoaded);
export const getAccommodations = createSelector(getState, (state) => state.accommodations || []);
export const getAddonsLoaded = createSelector(getState, (state) => state.addonsLoaded);
export const getAvailableDates = createSelector(getState, (state) => state.availableDates);
export const getAddonsList = createSelector([getAddons], (addons) => {
  const packageElements = getIn(addons, '0.PackageElements') || [];
  return packageElements;
});
export const getAddonsPrices = createSelector([getAddonsList, getUserSessionAddons], (addons, bookedAddons) => {
  const priceAddonsTotal = addons.reduce((sum, c) => {
    if (bookedAddons?.length) {
      const id = getIn(c, 'Package.0.$.packageCode');
      const findIndex = bookedAddons.findIndex((item) => item === id);
      if (findIndex > -1) {
        const price = +getIn(c, 'Package.0.$.totalDepositAmount');
        return sum + price;
      }
    }
    return sum;
  }, 0);
  return priceAddonsTotal;
});
