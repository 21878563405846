/**
 * Renders form to enter personal info.
 */
import React, { PureComponent } from 'react';
import { object } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { resetAllSessionData } from '../../utils/sessionActions';
import { getBookings } from '../../redux/modules/bookings';

import img1 from './img-1.jpg';
import img2x from './img-1@2x.jpg';
import Logo from '../Headers/logo.svg';
import styles from './Confirmations.module.scss';

const mapStateToProps = (state) => ({
  bookings: getBookings(state),
});

const mapDispatchToProps = {};

class Confirmations extends PureComponent {
  constructor(props) {
    super(props);
    // saves data in component to show to user
    // while real data has been cleared
    this._bookings = { ...props.bookings };
  }

  componentDidMount() {
    // clears data with delay larger than animatin transition
    // to prevent content dissapearing during the animation
    // and redundant requests that could be sent when
    // data has been reset to initial step
    setTimeout(() => {
      this.clearData();
    }, 600);
  }

  clearData = () => {
    resetAllSessionData({
      excludedFormData: { isFinalStep: true },
    });
  };

  render() {
    const { folioId } = this._bookings;

    return (
      <div className={styles.confirmations}>
        <div className={styles.confirmations__hero}>
          <div className={styles['confirmations__front-img']} style={{ backgroundImage: `url(${img1})` }} />
          <div
            className={cx(styles['confirmations__front-img'], styles['confirmations__front-img--retina'])}
            style={{ backgroundImage: `url(${img2x})` }}
          />
        </div>
        <div className={styles.confirmations__wrap}>
          <h2 className="booking-form__ttl">Your Booking #{folioId} is confirmed</h2>
          <div className="booking-form__sub-ttl">
            Thank you for choosing Calamigos Guest Ranch & Beach Club! We are looking forward to your arrival. You will
            receive and email confirmation to the address you provided. For questions, please call{' '}
            <a href="tel:8184450000">(818) 575-4400.</a>
          </div>
          <div className={styles['confirmations__logo-wrap']}>
            <Logo className={styles['confirmations__logo-img']} alt="Calamigos Guest Ranch & Beach Club" />
          </div>
        </div>
      </div>
    );
  }
}

Confirmations.propTypes = {
  bookings: object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmations);
