import React, { Component } from 'react';
import { oneOfType, string, node, func, bool } from 'prop-types';
import cx from 'classnames';

import './Dropdown.module.scss';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
    };

    const { isOpened, trigger } = props;

    this._isControlled = isOpened !== undefined;
    this._trigger = typeof trigger === 'function' ? (nextIssOpened) => trigger(nextIssOpened) : () => props.trigger;
    this._dropdown = null;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClose);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClose);
  }

  handleTriggerClick = () => {
    if (this._isControlled) {
      const { onTriggerClick } = this.props;

      onTriggerClick();
      return;
    }

    this.setState((prevState) => ({
      isOpened: !prevState.isOpened,
    }));
  };

  handleClose = (e) => {
    const { isOpened } = this.state;

    if (isOpened && !this._dropdown.contains(e.target)) {
      this.close();
    }
  };

  close = () => {
    const { handleClose } = this.props;

    !this._isControlled ? this.setState({ isOpened: false }) : handleClose(); // eslint-disable-line
  };

  render() {
    const { children, className, classNameBody, isDisabled } = this.props;
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, { closeDropdown: this.close })
    );
    const isOpened = this.props.isOpened || this.state.isOpened;

    return (
      <div
        ref={(n) => {
          this._dropdown = n;
        }}
        className={cx('dropdown', {
          [className]: !!className,
          'is-opened': isOpened,
          'is-disabled': isDisabled,
        })}
      >
        <div className="dropdown__trigger" onClick={!isDisabled ? this.handleTriggerClick : undefined}>
          {this._trigger(isOpened)}
        </div>
        {isOpened && (
          <div
            className={cx('dropdown__body', {
              [classNameBody]: !!classNameBody,
            })}
          >
            {childrenWithProps}
          </div>
        )}
      </div>
    );
  }
}

Dropdown.defaultProps = {
  onTriggerClick: () => {},
};

Dropdown.propTypes = {
  className: string,
  classNameBody: string,
  trigger: oneOfType([string, node, func]).isRequired,
  children: node.isRequired,
  isOpened: bool,
  isDisabled: bool,
  onTriggerClick: func,
  handleClose: func,
};

export default Dropdown;
