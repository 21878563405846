/*
  Controls notifications queue.
*/
import { toast as reactToast } from 'react-toastify';
import { createSelector } from 'reselect';

const STATE_KEY = 'notifications';

const ADD_NOTIFICATION_QUEUE = `${STATE_KEY}/ADD_NOTIFICATION_QUEUE`;

const DELETE_NOTIFICATION_QUEUE = `${STATE_KEY}/DELETE_NOTIFICATION_QUEUE`;

const UPDATE_NOTIFICATION_QUEUE = `${STATE_KEY}/UPDATE_NOTIFICATION_QUEUE`;

const CLEAR_NOTIFICATIONS = `${STATE_KEY}/CLEAR_NOTIFICATIONS`;

const MAX_QUEUE = 2; // max number of the queue

/**
 * function that handles logic for adding notification
 * this logic requires specific id for specific notification
 * so it wasn't possible to add the same notification one after the other
 * note: see the ToastQueue component (ToastContainer/ToastQueue)
 * for better understanding how notifications queue work
 *
 * @param {array} queue
 * @param {object} toast - object with data and options for the toast
 *   https://github.com/fkhadra/react-toastify#toast
 * @param {bool} toast.forceAdd - param to force a notification to the queue
 */
function addQueue(queue, toast) {
  // do not add toast to queue without id
  if (!toast.toastId) {
    console.warn('missing notifications queue id', toast);
    return queue;
  }
  const { toastId: nextToastId } = toast;

  const queueLength = queue.length;
  const lastQueueToast = queue[queueLength - 1] || {};

  // if queue is empty just add new notification
  // else check previous added notification type and MAX_QUEUE
  if (!queueLength) {
    return [toast];
  }
  if (lastQueueToast.toastId !== nextToastId) {
    // prevents queue overloading
    return queueLength < MAX_QUEUE || toast.forceAdd ? [...queue, toast] : [...queue.slice(0, MAX_QUEUE - 1), toast];
  }

  return queue;
}

const initialState = {
  queue: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_NOTIFICATION_QUEUE: {
      const toast = { message: action.message, ...action.options };
      const nextQueue = addQueue(state.queue, toast);

      return { ...state, queue: nextQueue };
    }
    case DELETE_NOTIFICATION_QUEUE: {
      const { queue } = state;
      const nextQueue = queue.slice(1, queue.length); // remove last added notification from queue

      return { ...state, queue: nextQueue };
    }
    case UPDATE_NOTIFICATION_QUEUE: {
      const { toastId, message, options } = action;
      const nextQueue = state.queue.map((c) => (c.toastId === toastId ? { ...c, message, ...options } : c));

      return { ...state, queue: nextQueue };
    }
    case CLEAR_NOTIFICATIONS:
      return { ...state, queue: [] };
    default:
      return state;
  }
}

// standard reducer actions goes below
export function addNotificationQueue(message, options = {}) {
  return {
    type: ADD_NOTIFICATION_QUEUE,
    message,
    options,
  };
}

export function deleteNotificationQueue() {
  return {
    type: DELETE_NOTIFICATION_QUEUE,
  };
}

export function updateNotificationQueue(toastId, message, options = {}) {
  const isActive = reactToast.isActive(toastId);

  if (isActive) {
    // https://github.com/fkhadra/react-toastify#update-a-toast
    reactToast.update(toastId, { render: message, ...options });
  }

  return {
    type: UPDATE_NOTIFICATION_QUEUE,
    toastId,
    message,
    options,
  };
}

export function clearNotifications() {
  // https://github.com/fkhadra/react-toastify#remove-a-toast-programmatically
  reactToast.dismiss();

  return { type: CLEAR_NOTIFICATIONS };
}

// selectors
const getState = (state) => state[STATE_KEY];

export const getNotificationQueue = createSelector(getState, (state) => state.queue);
