import React from 'react';
import styles from './Header.module.scss';

const Header = () => (
  <div id="js-main-header" className={styles.header}>
    <div className={styles.header__container}>
      <a className={styles.header__logo} href="https://calamigosguestranch.com">
        Calamigos guest ranch and beach club
      </a>
    </div>
  </div>
);

export default Header;
