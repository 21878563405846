import React, { PureComponent } from 'react';
import { node, string, bool, func } from 'prop-types';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cx from 'classnames';

import './Modal.module.scss';

export default class Modal extends PureComponent {
  constructor(props) {
    super(props);
    this._modal = React.createRef();
    this._modalScrollableContent = React.createRef();
  }

  componentDidMount() {
    const { opened } = this.props;

    // when the modal is mounted opened
    opened && this.disableBodyScroll(); // eslint-disable-line

    window.addEventListener('keydown', this.close);
  }

  componentDidUpdate(prevProps) {
    const { opened: prevOpened } = prevProps;
    const { opened } = this.props;

    if (!prevOpened && opened) {
      this.disableBodyScroll();
    } else if (prevOpened && !opened) {
      this.enableBodyScroll();
    }
  }

  componentWillUnmount() {
    this.enableBodyScroll();
    window.removeEventListener('keydown', this.close);
  }

  close = (e) => {
    if (!this.props.disableClose && this.props.opened && e.keyCode === 27) {
      this.props.handleClose();
    }
  };

  handleClickClose = (e) => {
    if (!this._modal.current.contains(e.target)) {
      this.props.handleClose();
    }
  };

  enableBodyScroll = () => {
    clearAllBodyScrollLocks();
  };

  disableBodyScroll = () => {
    const scrollableChild = this._modal.current.querySelector('.js-scrollable-content');
    const scrollableElement = scrollableChild || this._modalScrollableContent.current;

    disableBodyScroll(scrollableElement);
  };

  render() {
    const {
      children,
      className,
      opened,
      handleClose,
      disableClickClose,
      disableClose,
      innerButtonClose,
      header,
      footer,
    } = this.props;
    const childrenWithProps = React.Children.map(children, (child) =>
      React.cloneElement(child, { closeModal: handleClose })
    );

    if (!opened) return null;

    return (
      <div
        className={cx('modal', {
          [className]: !!className,
          'modal--inner-btn-close': !!innerButtonClose,
        })}
        ref={this._modalScrollableContent}
        onClick={!disableClickClose ? this.handleClickClose : undefined}
      >
        {!disableClose && !innerButtonClose && (
          <button
            className="button button--close-modal"
            onClick={disableClickClose ? handleClose : undefined}
            type="button"
          >
            <i className="icon icon--close" />
          </button>
        )}
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__body" ref={this._modal}>
              {!disableClose && innerButtonClose && (
                <button
                  className="button button--close-modal button--close-modal-inner"
                  onClick={handleClose}
                  type="button"
                >
                  <i className="icon icon--close" />
                </button>
              )}
              {header && <div className="modal__header">{header}</div>}
              <div className="modal__children-wrap">{childrenWithProps}</div>
              {footer && <div className="modal__footer">{footer}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  children: node.isRequired,
  header: node,
  footer: node,
  className: string,
  opened: bool,
  disableClickClose: bool,
  disableClose: bool,
  innerButtonClose: bool,
  handleClose: func,
};
